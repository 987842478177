import React, { useEffect, useState } from "react";
import { Payment, Apartment } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
import Paper from "@material-ui/core/Paper";
import { Table, Modal, Alert, Button, ToggleButton } from "react-bootstrap";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import MySelect from "./../../CustomChallan/MySelect";
import DateSheetModal from "./DateSheetModal";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Switch } from "@material-ui/core";
import ToggleExam from "./ToggleExam";
import ToggleDateSheet from "./ToggleDateSheet";
import { PremissionChecker } from "../../../../services/PremissionChecker";
import { useSelector } from "react-redux";

const ExamList = () => {
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);
  //arrays
  const [allExamsList, setAllExamsList] = useState([]);
  const [examsList, setExamsList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [weekList, setWeekList] = useState([]);
  const [allSessions, setAllSessions] = useState([]);
  const [campusId, setCampusId] = useState("");
  const [selectedCampus, setSelectedCampus] = useState("");
  const [selectedExamId, setSelectedExamId] = useState("");
  const [weekId, setWeekId] = useState("");
  const [monthDate, setMonthDate] = useState("");
  const [editExamTitle, setEditExamTitle] = useState("");
  const [editExamTypeId, setEditExamTypeId] = useState("");
  const [editFailWeightage, setEditFailWeightage] = useState("");
  const [editGradeSystem, setEditGradeSystem] = useState("");
  const [editWeekId, setEditWeekId] = useState("");
  const [editMonthDate, setEditMonthDate] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [failWeightage, setFailWeightage] = useState("");
  const [status, setStatus] = useState(false);
  const [dateSheet, setDateSheet] = useState(false);
  const [allClasses, setAllClasses] = useState([]);
  const [optionSelected, setOptionSelected] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  //objects
  const [selectedObject, setSelectedObject] = useState({});
  const [updateExamModel, setUpdateExamModel] = useState(false);
  const [examTitle, setExamTitle] = useState("");
  const [updateDateSheetModel, setUpdateDateSheetModel] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deletingExam, setDeletingExam] = useState(false);
  //booleans
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [declareExamModal, setDeclareExamModal] = useState(false);
  const [isAllFields, setIsAllFields] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dateSheetModal, setDateSheetModal] = useState(false);
  //strings
  const [currentSession, setCurrentSession] = useState("");
  const [selectedClassForDatesheet, setSelectedClassForDatesheet] = useState(
    ""
  );
  const [selectedExamForDatesheet, setSelectedExamForDatesheet] = useState("");
  const [campuses, setAllCampuses] = useState(user.campuses || []);
  const [selectedExam, setSelectedExam] = useState("");
  const [toggleId, setToggleId] = useState("");
  const [dateSheetId, setDateSheetId] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [removeConfirmModal, setRemoveConfirmModal] = useState(false);

  const [educationType, setEducationType] = useState(1);

  //functions

  useEffect(() => {
    getAllSessions();
    getExamTypesList();
    getWeekList();
    getGradeSystem();
  }, []);

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAllSessions([...data.payload]);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setCurrentSession(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getExamTypesList = () => {
    fetch(`${baseUrl}/exam-types`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLoader(false);
        setExamsList([...data.payload]);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const getWeekList = () => {
    fetch(`${baseUrl}/weeks`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setWeekList([...data.payload]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGradeSystem = () => {
    fetch(`${baseUrl}/all-grade-systems`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLoader(false);
        setGradeList([...data.payload]);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const updateExam = () => {
    if (editExamTitle.length == 0) {
      alert.error("Please add exam title");
      return;
    } else {
      setUpdateLoading(true);

      const data = {
        exam_id: selectedExamId,
        exam_type_id: editExamTypeId,
        exam_name: editExamTitle,
        week_id: editWeekId != "" ? editWeekId : null,
        month: editMonthDate != "" ? `${editMonthDate}-01` : null,
        fail_weightage: editFailWeightage,
        grade_system: editGradeSystem,
      };

      fetch(`${baseUrl}/exam/${selectedExamId}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          setUpdateLoading(false);

          if (data.metadata) {
            if (data.metadata.success) {
              alert.success(data.metadata.message);
              setConfirmModal(false);
              getAllExamsList();
            }
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };

  const deleteExam = () => {
    setDeletingExam(true);

    fetch(`${baseUrl}/delete-exam/${selectedExamId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.success(data.metadata.message);
            setRemoveConfirmModal(false);
            setDeletingExam(false);
            getAllExamsList();
          }
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };

  const updateExamStatus = (item) => {
    setToggleId(item.id);
    setExamTitle(item?.exam_name);
    setDateSheet(item?.date_sheet_status);
    setUpdateExamModel(true);
  };

  const updateDateSheetStatus = (item) => {
    setDateSheetId(item.id);
    setExamTitle(item?.exam_name);
    setStatus(item?.status);
    setUpdateDateSheetModel(true);
  };

  const getCampusClasses = (id) => {
    setSelectedCampus(id);
    fetch(`${baseUrl}/campusclass/${id}`, {
      // const getAllClasses = () => {
      //   fetch(`${baseUrl}/studentclass`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAllClasses([...data.payload]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllExamsList = () => {
    const data = {
      year_id: currentSession,
      campus_id: campusId,
    };

    setLoader(true);
    fetch(`${baseUrl}/exam_for_session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoader(false);
        setAllExamsList([...data.payload]);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const openModal = (item) => {
    setShowDetailModal(true);
    setSelectedObject(item);
  };

  const declareExamHandler = () => {
    if (
      currentSession == "" ||
      selectedExam == "" ||
      selectedGrade == "" ||
      failWeightage == "" ||
      educationType == "" ||
      selectedClasses.length == 0
    ) {
      setIsAllFields(true);
      return;
    }
    let data = {
      year_id: currentSession,
      exam_type_id: selectedExam,
      week_id: weekId != "" ? weekId : null,
      month: monthDate != "" ? `${monthDate}-01` : null,
      education_type: educationType,
      student_class_ids: selectedClasses,
      campus_id: selectedCampus,
      fail_weightage: failWeightage,
      grade_system: selectedGrade,
    };
    setLoading(true);

    fetch(`${baseUrl}/exam`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setLoading(false);
          setOptionSelected([]);
          setSelectedExam("");
          setWeekId("");
          setMonthDate("");
          setSelectedClasses("");
          setSelectedGrade("");
          setFailWeightage("");
          setDeclareExamModal(false);
          getAllExamsList();
          alert.success("Successfully Declared");
        } else if (!data.metadata.success) {
          alert.error(data.metadata.message);
          setLoading(false);
          setOptionSelected([]);
          setSelectedExam("");
          setWeekId("");
          setMonthDate("");
          setSelectedClasses("");
          setSelectedGrade("");
          setFailWeightage("");
          setDeclareExamModal(false);
          getAllExamsList();
        }
      })
      .catch((err) => {
        setLoading(false);
        setOptionSelected([]);
        setSelectedExam("");
        setWeekId("");
        setMonthDate("");
        setSelectedClasses("");
        setSelectedGrade("");
        setFailWeightage("");
        setDeclareExamModal(false);
        alert.error(data.metadata.message);
        console.log(err);
      });
  };

  //multiselect configration
  const Options = [];
  allClasses.map((item, index) => {
    Options[index] = { value: item.id, label: item.name };
  });

  const animatedComponents = makeAnimated();
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  const handleChangeClasses = (selected) => {
    if (optionSelected.length == 30 && selected.length == 30) {
      setOptionSelected([]);
      return;
    }
    setOptionSelected([...selected]);
    let valuearray = [];

    selected.map((item, index) => {
      valuearray[index] = item.value;
    });
    setSelectedClasses([...valuearray]);
  };

  return (
    <div className="container">
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-4">
            <label>
              <b>
                Select Session <span style={{ color: "red" }}>*</span>
              </b>
            </label>
            <select
              onChange={(e) => setCurrentSession(e.target.value)}
              class="form-select"
              style={{ height: 40 }}
              value={currentSession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allSessions.map((session, index) => (
                <option key={index} value={session.id}>
                  {session.year}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-4">
            <label>
              <b>
                Select Campus <span style={{ color: "red" }}>*</span>
              </b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              value={campusId}
              onChange={(e) => setCampusId(e.target.value)}
              aria-label="Default select example"
            >
              <option selected value="">
                {" "}
                Select Campus
              </option>
              {campuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4 mt-7 d-flex">
            <button
              style={{
                width: "150px",
                color: "#ffffff",
                backgroundColor: "#121291",
                borderColor: "#121291",
              }}
              onClick={() => {
                setDeclareExamModal(true);
              }}
              className="btn btn-success"
            >
              Declare Exam
            </button>

            <button
              style={{
                color: "#ffffff",
                backgroundColor: "#121291",
                borderColor: "#121291",
              }}
              onClick={getAllExamsList}
              className="btn btn-success mx-2"
            >
              Show
            </button>
          </div>
        </div>
        {loader ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-center",
              padding: "10px",
            }}
          >
            {" "}
            <span className="spinner-border"></span>{" "}
          </div>
        ) : (
          <div className="row">
            {allExamsList?.map((item, index) => {
              return (
                <>
                  <div className="col-md-4 mt-3">
                    <div className="d-flex justify-content-around">
                      <div>
                        <b>
                          {item.status == 1 ? "Announced" : "Announce Result"}
                        </b>
                        <Switch
                          disabled={
                            !PremissionChecker(user, "exam.announce_result")
                          }
                          size="small"
                          color="primary"
                          checked={item.status == 1 ? true : false}
                          onClick={() => {
                            updateExamStatus(item);
                          }}
                          onChange={(e) => setStatus(e.target.checked)}
                        />
                      </div>
                      <div>
                        <b>Date Sheet</b>
                        <Switch
                          size="small"
                          color="primary"
                          checked={item.date_sheet_status == 1 ? true : false}
                          onClick={() => updateDateSheetStatus(item)}
                          onChange={(e) => setDateSheet(e.target.checked)}
                        />
                      </div>
                    </div>
                    {/* <div style={{position:"relative",zIndex:"1",left:item.status == 1 ?"53%":"43%",top:"20%"}}>
                                        <b>{item.status == 1 ? "Announced":"Announce Result"}</b>
                                       <Switch
                                        size="small"
                                        color="primary"
                                        checked={item.status == 1 ? true : false}
                                        onClick={() => toggleAction(item.id)}
                                        onChange={(e)=>setStatus(e.target.checked)}
                                      />
                                       </div> */}

                    <div className="col-md-12">
                      <div className="card card-1 m-2">
                        <span
                          onClick={() => openModal(item)}
                          key={item.id}
                          style={{ cursor: "pointer", textAlign: "center" }}
                        >
                          <Payment
                            style={{
                              height: 40,
                              width: 40,
                              color: "#121291",
                            }}
                            //  color="primary"
                            fontSize="large"
                          />
                          <h4>
                            {
                              /* {item.exam_type == undefined
                              ? item.name
                              : item.exam_type.name} */
                              item?.exam_name
                            }
                          </h4>
                        </span>

                        <div style={{ paddingLeft: "200px" }}>
                          <span
                            onClick={() => {
                              setConfirmModal(true);
                              setSelectedExamId(item.id);
                              setEditExamTitle(item?.exam_name);
                              setEditExamTypeId(item?.exam_type_id);
                              setEditWeekId(item?.week_id);
                              setEditMonthDate(
                                item?.month != null
                                  ? item?.month.replace("-01", "")
                                  : ""
                              );
                              setEditFailWeightage(item?.fail_weightage);
                              setEditGradeSystem(item?.grade_system);
                            }}
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            className="svg-icon svg-icon-md svg-icon-primary"
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Write.svg"
                              )}
                            />
                          </span>

                          {PremissionChecker(user, "exam.delete_exam") ? (
                            <span
                              onClick={() => {
                                if (item.status !== 1) {
                                  setRemoveConfirmModal(true);
                                  setSelectedExamId(item.id);
                                  setEditExamTitle(
                                    item.exam_type == undefined
                                      ? item.name
                                      : item.exam_type.name
                                  );
                                }
                              }}
                              style={{ cursor: "pointer", marginLeft: "10px" }}
                              className={"svg-icon svg-icon-md svg-icon-danger"}
                              disabled={item.status === 0 ? false : true}
                            >
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              />
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        )}

        <Modal size="lg" show={declareExamModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Declare Exams
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="form-group col-sm-12 flex-column d-flex">
              <div className="row">
                <div className="col-md-6  flex-column d-flex">
                  <label>
                    <b>
                      Select Session <span style={{ color: "red" }}>*</span>
                    </b>
                  </label>
                  <select
                    onChange={(e) => setCurrentSession(e.target.value)}
                    className="form-select"
                    style={{ height: 40 }}
                    value={currentSession}
                    aria-label="Default select example"
                  >
                    <option value="">Select Session</option>
                    {allSessions.map((session, index) => (
                      <option key={index} value={session.id}>
                        {session.year}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 flex-column d-flex">
                  <label>
                    <b>
                      Select Campus <span style={{ color: "red" }}>*</span>
                    </b>
                  </label>
                  <select
                    style={{ height: 40 }}
                    className="form-select"
                    name="campus_id"
                    value={selectedCampus}
                    onChange={(e) => getCampusClasses(e.target.value)}
                    aria-label="Default select example"
                  >
                    <option value=""> Select Campus</option>
                    {campuses.map((campus, index) => (
                      <option key={index} value={campus.id}>
                        {campus.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <br />

              <div className="row mb-6">
                <div className=" col-md-6 flex-column d-flex ">
                  <label>
                    <b>
                      Select Exam <span style={{ color: "red" }}>*</span>
                    </b>
                  </label>
                  <select
                    class="form-select"
                    value={selectedExam}
                    onChange={(e) => {
                      setSelectedExam(e.target.value);
                    }}
                    style={{ backgroundColor: "white", height: 40 }}
                    aria-label="Default select example"
                  >
                    <option value="">Select Exam Type</option>
                    {examsList.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-6 flex-column d-flex">
                  <label>
                    <b>Select Grade System</b>{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="grade-system"
                    value={selectedGrade}
                    style={{ backgroundColor: "white", height: 40 }}
                    aria-label="Default select example"
                    onChange={(e) => {
                      setSelectedGrade(e.target.value);
                    }}
                  >
                    <option value="">Select Grade System</option>
                    {gradeList.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row mb-6">
                <div className=" col-md-6 flex-column d-flex ">
                  <label>
                    <b>
                      Select Week <span style={{ color: "red" }}>*</span>
                    </b>
                  </label>
                  <select
                    disabled={!(selectedExam == 8)}
                    class="form-select"
                    name="week"
                    value={weekId}
                    onChange={(e) => {
                      setWeekId(e.target.value);
                    }}
                    style={{ backgroundColor: "white", height: 40 }}
                    aria-label="Default select example"
                  >
                    <option value="">Select Week</option>
                    {weekList.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-6 flex-column d-flex">
                  <label>
                    <b>Select Month</b> <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={monthDate}
                    onChange={(e) => {
                      setMonthDate(e.target.value);
                    }}
                    className="form-control"
                    type="month"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 flex-column d-flex">
                  <label>
                    <b>Select Fail Weightage</b>{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="fail_subject"
                    value={failWeightage}
                    style={{ backgroundColor: "white", height: 40 }}
                    aria-label="Default select example"
                    onChange={(e) => {
                      setFailWeightage(e.target.value);
                    }}
                  >
                    <option value="">Select Fail Weightage</option>
                    <option value={0.5}>0.5</option>
                    <option value={1}>1</option>
                    <option value={1.5}>1.5</option>
                    <option value={2}>2</option>
                    <option value={2.5}>2.5</option>
                    <option value={3}>3</option>
                    <option value={3.5}>3.5</option>
                    <option value={4}>4</option>
                    <option value={4.5}>4.5</option>
                    <option value={5}>5</option>
                  </select>
                </div>

                <div className="col-md-6">
                  <label>
                    <b>Select Education Type</b>{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-select"
                    name="campus_id"
                    value={educationType}
                    style={{ backgroundColor: "white", height: 40 }}
                    aria-label="Default select example"
                    onChange={(e) => {
                      setEducationType(e.target.value);
                    }}
                  >
                    <option value={1}>Education</option>
                    <option value={2}>Hifz</option>
                  </select>
                </div>

                <div className=" col-md-12  flex-column d-flex ">
                  <br />
                  <label>
                    <b>
                      Select Classes <span style={{ color: "red" }}>*</span>
                    </b>
                  </label>
                  <MySelect
                    options={Options}
                    isMulti
                    name="selectedOptions"
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    components={{ Option, MultiValue, animatedComponents }}
                    onChange={handleChangeClasses}
                    allowSelectAll={true}
                    value={optionSelected}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          {isAllFields ? (
            <>
              <Alert
                variant="danger"
                onClose={() => setIsAllFields(false)}
                dismissible
              >
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <p>Please Fill All Fields</p>
              </Alert>
            </>
          ) : null}

          <Modal.Footer>
            {loading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "10px",
                }}
              >
                {" "}
                <span className="spinner-border"></span>{" "}
              </div>
            ) : (
              <>
                <button
                  onClick={() => {
                    declareExamHandler();
                  }}
                  className="btn btn-success button_blue"
                >
                  Declare
                </button>

                <button
                  onClick={() => {
                    setDeclareExamModal(false);
                  }}
                  className="btn btn-danger btn_width"
                >
                  Cancel
                </button>
              </>
            )}
          </Modal.Footer>
        </Modal>

        {selectedObject.student_classes != undefined ? (
          <Modal size="lg" show={showDetailModal}>
            <Modal.Header className="modal_header_style" closeButton>
              <Modal.Title
                className="Modal_Heading_font"
                id="contained-modal-title-vcenter"
              >
                Exams Detail
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                <div>
                  {
                    <div className=" col-md-12 d-flex flex-column ">
                      <div className="row">
                        <div className=" col-md-6 d-flex ">
                          <div>
                            {" "}
                            <h5 style={{ color: "grey" }}>
                              Session : &ensp;
                            </h5>{" "}
                          </div>
                          <div>
                            <h5
                              style={{
                                color: "grey",
                                textAlign: "start",
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              {selectedObject.session?.year}
                            </h5>
                          </div>
                        </div>

                        <div className=" col-md-6 d-flex ">
                          <div>
                            {" "}
                            <h5 style={{ color: "grey" }}>
                              Status : &ensp;
                            </h5>{" "}
                          </div>
                          <div>
                            <h5
                              style={{
                                color: "grey",
                                textAlign: "start",
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              {selectedObject.session?.status == "0"
                                ? "Proccedding"
                                : "Announced"}
                            </h5>
                          </div>
                        </div>
                      </div>

                      <Table responsive>
                        <thead>
                          <tr>
                            <th className="text-center">Sr. No</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Define Date Sheet</th>
                          </tr>
                        </thead>

                        <tbody>
                          {selectedObject.student_classes
                            .sort((a, b) => a.id - b.id)
                            .map((examclass, examclassindex) => (
                              <tr key={examclassindex}>
                                <td className="text-center">
                                  {examclassindex + 1}
                                </td>
                                <td className="text-center">
                                  {" "}
                                  {examclass.name}
                                </td>
                                <td className="text-center">
                                  {PremissionChecker(
                                    user,
                                    "exam.date_sheet"
                                  ) ? (
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setSelectedClassForDatesheet(
                                          examclass.id
                                        );
                                        setSelectedExamForDatesheet(
                                          selectedObject.id
                                        );
                                        setDateSheetModal(true);
                                      }}
                                      className="svg-icon svg-icon-md svg-icon-primary"
                                    >
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Communication/Clipboard-list.svg"
                                        )}
                                      />
                                    </span>
                                  ) : null}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  }
                </div>
              }
            </Modal.Body>

            <Modal.Footer>
              <button
                onClick={() => {
                  setShowDetailModal(false);
                }}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
        ) : null}

        {dateSheetModal ? (
          <DateSheetModal
            campusId={selectedObject.campus.id}
            show={dateSheetModal}
            onHide={setDateSheetModal}
            selectedclassfordatesheet={selectedClassForDatesheet}
            examid={selectedExamForDatesheet}
            showdetailmodal={setShowDetailModal}
          />
        ) : null}
        {/* datasheetmodal */}

        {/* Exam Edit Model */}
        <Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Update Exam
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-2">
              <div className="row mb-4">
                <label>
                  <b>
                    Select Exam <span style={{ color: "red" }}>*</span>
                  </b>
                </label>
                <select
                  class="form-select"
                  value={editExamTypeId}
                  onChange={(e) => {
                    setEditExamTypeId(e.target.value);
                  }}
                  style={{ backgroundColor: "white", height: 40 }}
                  aria-label="Default select example"
                >
                  <option value="">Select Exam Type</option>
                  {examsList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="row mb-4">
                <label className="form-control-label">
                  <b>Exam Title</b>
                </label>
                <input
                  onChange={(e) => {
                    setEditExamTitle(e.target.value);
                  }}
                  defaultValue={editExamTitle}
                  maxLength={100}
                  type="text"
                  className="form-control"
                  placeholder="Enter Title"
                />
              </div>

              <div className="row mb-4">
                <label className="form-control-label">
                  <b>Select Week</b>
                </label>
                <select
                  disabled={!(editExamTypeId == 8)}
                  class="form-select"
                  name="edit-week"
                  value={editWeekId}
                  onChange={(e) => {
                    setEditWeekId(e.target.value);
                  }}
                  style={{ backgroundColor: "white", height: 40 }}
                  aria-label="Default select example"
                >
                  <option value="">Select Week</option>
                  {weekList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="row mb-4">
                <label className="form-control-label">
                  <b>Select Month</b>
                </label>
                <input
                  value={editMonthDate}
                  onChange={(e) => {
                    setEditMonthDate(e.target.value);
                  }}
                  className="form-control"
                  type="month"
                />
              </div>

              <div className="row mb-4">
                <label className="form-control-label">
                  <b>Select Grade System</b>
                </label>
                <select
                  className="form-select form-control"
                  name="grade-system"
                  value={editGradeSystem}
                  style={{ backgroundColor: "white", height: 40 }}
                  aria-label="Default select example"
                  onChange={(e) => {
                    setEditGradeSystem(e.target.value);
                  }}
                >
                  <option value="">Select Grade System</option>
                  {gradeList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="row">
                <label className="form-control-label">
                  <b>Select Fail Weightage</b>
                </label>
                <select
                  className="form-select form-control"
                  name="fail_subject"
                  value={editFailWeightage}
                  style={{ backgroundColor: "white", height: 40 }}
                  aria-label="Default select example"
                  onChange={(e) => {
                    setEditFailWeightage(e.target.value);
                  }}
                >
                  <option value="">Select Fail Weightage</option>
                  <option value={0.5}>0.5</option>
                  <option value={1}>1</option>
                  <option value={1.5}>1.5</option>
                  <option value={2}>2</option>
                  <option value={2.5}>2.5</option>
                  <option value={3}>3</option>
                  <option value={3.5}>3.5</option>
                  <option value={4}>4</option>
                  <option value={4.5}>4.5</option>
                  <option value={5}>5</option>
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={updateExam}
              disabled={updateLoading}
            >
              Update
            </Button>
            <Button
              className="btn btn-danger"
              onClick={() => setConfirmModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={removeConfirmModal}
          onHide={() => setRemoveConfirmModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Exam</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            This will delete the exam and all related student results
            permanently.
            <br /> Are you sure you want to delete?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setRemoveConfirmModal(false)}
            >
              Close
            </Button>
            <Button
              variant="primary"
              className="btn btn-danger"
              //   onClick={deleteClassExam}
              disabled={deletingExam}
              onClick={deleteExam}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Paper>
      {/* {loginAccount == "Campus" ? <PasswordModal /> : ""} */}

      <ToggleExam
        title={"Update Exam Status"}
        url={`exam_status_update`}
        examTitle={examTitle}
        close={() => setUpdateExamModel(false)}
        show={updateExamModel}
        reload={getAllExamsList}
        status={status == true ? 1 : 0}
        dateSheetToggle={dateSheet == true ? 1 : 0}
        id={toggleId}
      />

      <ToggleDateSheet
        title={"Update Date Sheet Status"}
        url={`exam_status_update`}
        examTitle={examTitle}
        close={() => setUpdateDateSheetModel(false)}
        show={updateDateSheetModel}
        reload={getAllExamsList}
        status={status == true ? 1 : 0}
        dateSheetToggle={dateSheet == true ? 1 : 0}
        id={dateSheetId}
      />
    </div>
  );
};

export default ExamList;
