import React, { useEffect, useRef, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { baseUrl } from "../../../../services/config";
import "./AccountLedger.css";
import Select from "react-select";
import { useAlert } from "react-alert";
import { Table, Spinner } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { DatePicker } from "antd";
import AccountLedgerExport from "./AccountLedgerExport";
import { upperCase } from "lodash";
const { RangePicker } = DatePicker;

const AccountLedger = () => {
  const [subAccount, setSubAccount] = useState({
    label: "Select Account",
    value: "",
  });
  const [sessionData, setSessionData] = useState([]);
  const [yearId, setYearId] = useState("");
  const [from, setFrom] = useState("00");
  const [toDate, setToDate] = useState("00");
  const [loading, setLoading] = useState(false);
  const [sub_AccountData, setSub_AccountData] = useState({
    label: "Select Account",
    value: "",
  });
  const [subtoatal, setSubTotal] = useState(false);
  // for response data//////////////
  const [balance, setBalance] = useState({});
  const [account, setAccount] = useState({});
  const [transactions, settransactions] = useState([]);
  const [select, setSelect] = useState([]);
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const [newOpeningBln, setNewOpeningBln] = useState();
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [customToRise, setCustomT0Rise] = useState();

  let authtoken = localStorage.getItem("userToken");

  const [directorSign, setDirectorSign] = useState(0);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const alert = useAlert();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    sessions();
    accounts();
    getSettingData();
  }, []);
  ////getSessions//////////////////////////

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.payload[0].director_sign);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
        setDirectorSign(data.payload.settings.director_sign);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const accounts = () => {
    fetch(`${baseUrl}/subaccount`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let arr = [];
        console.log(data);
        data.payload.forEach((element) => {
          arr.push({
            label: element.title + `(${element.acode})`,
            value: element.id,
            account_chart_id: element.account_chart_id,
          });
        });
        setSelect(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = () => {
    const data = {
      from: from,
      to: toDate,
      sub_account_id: sub_AccountData.value,
      year_id: yearId,
    };

    if (subAccount == "") {
      alert.error("Account Code Must be required");
      return;
    }
    setLoading(true);
    setIsVisible(false);

    fetch(`${baseUrl}/account_ledger_report`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setIsVisible(true);
        console.log(data);
        if (data.metadata) {
          if (data.metadata.success) {
            setNewOpeningBln(data.payload);
            setBalance(
              data.payload.openning_balance_credit != 0
                ? data.payload.openning_balance_credit
                : data.payload.openning_balance_debit
            );
            setAccount(data.payload.account_head);
            // settransactions(data.payload.transactions);
            balanceCalculator(
              data.payload.transactions,
              data.payload.openning_balance_credit != 0
                ? data.payload.openning_balance_credit
                : data.payload.openning_balance_debit,
              data.payload.openning_balance_credit != 0 ? 0 : 1
            );
            setCustomT0Rise(data.payload.openning_balance_credit != 0 ? 0 : 1);
            setSubTotal(true);
          } else {
            setLoading(false);
            console.log(toString.call(data.metadata.message));
            if (toString.call(data.metadata.message) == "[object Object]") {
              Object.values(data.metadata.message).map((msg) => {
                alert.error(msg);
              });
            } else {
              alert.error(data.metadata.message);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
        alert.error("Error");
        setLoading(false);
        setSubTotal(false);
      });
  };

  const balanceCalculator = async (dataList, openingBlnc, riseDebit) => {
    let array = [...dataList];

    let newArray = [];
    let blnc = openingBlnc;

    if (array.length > 0) {
      array.forEach((item, index) => {
        blnc = riseDebit == 0 ? blnc + item.credit : blnc + item?.debit;
        blnc = riseDebit == 0 ? blnc - item.debit : blnc - item.credit;
        newArray.push({
          type:
            riseDebit == 1 ? (blnc < 0 ? "CR" : "DR") : blnc < 0 ? "DR" : "CR",
          blnc,
          ...item,
        });
      });
    }
    // let result=newArray.sort((a, b) => new Date(a.voucher?.date) - new Date(b.voucher?.date));
    settransactions(newArray);
    console.log("new Array===>", newArray);
  };

  const accountFunc = (e) => {
    setSub_AccountData(e);
    console.log(e);
  };

  const gettotaldebit = () => {
    let sum = 0;
    transactions &&
      transactions.forEach((element) => {
        sum += parseInt(element.debit);
      });
    return sum;
  };

  const gettotalCredit = () => {
    let sum = 0;
    transactions &&
      transactions.forEach((element) => {
        sum += parseInt(element.credit);
      });
    return sum;
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const datePicker = (value, dateString) => {
    console.log("Formatted Selected Time: ", dateString);
    if (dateString[0] != "") setFrom(dateString[0]);
    else setFrom("00");

    if (dateString[1] != "") setToDate(dateString[1]);
    else setToDate("00");
  };
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="d-flex mt-3 datepickerContaine">
          <div className="col-md-3">
            <label className="form-control-label">
              <b>Select Date</b>{" "}
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <RangePicker style={{ height: 40 }} onChange={datePicker} />
          </div>

          <div className="col-md-5">
            <label className="form-control-label">
              <b>
                Select Account
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <Select
              value={sub_AccountData}
              onChange={(e) => accountFunc(e)}
              className="disselect"
              placeholder="Select Account"
              options={select}
            />
          </div>
          <div className="col-md-4">
            <label className="form-control-label">
              <b>
                Select Session
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="year_id"
              value={yearId}
              aria-label="Default select example"
              onChange={(e) => setYearId(e.target.value)}
            >
              <option value="">Select Session</option>
              {sessionData &&
                sessionData.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.year}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="col-md-3 mt-7">
          <button className="btn btn-primary" onClick={handleSubmit}>
            Show
          </button>

          {transactions?.length > 0 ? (
            <>
              <ReactToPrint
                documentTitle={`Account Ledger`}
                pageStyle="auto"
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    disabled={printloading ? true : false}
                    className="btn btn-primary w-105px mx-2"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />

              <AccountLedgerExport
                transactions={transactions}
                newOpeningBln={newOpeningBln}
                customToRise={customToRise}
                gettotalCredit={gettotalCredit}
                gettotaldebit={gettotaldebit}
                sub_AccountData={sub_AccountData}
                toDate={toDate}
                from={from}
                subtoatal={subtoatal}
                balance={balance}
              />
            </>
          ) : null}
        </div>
        <br />
        <br />
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : isVisible ? (
          <div id="tableboot">
            <div className="row">
              <div className="col-md-1">
                <img
                  style={{ height: 110, width: 100 }}
                  src={icon}
                  alt="logo"
                />
              </div>
              <div
                className="col-md-10"
                style={{ paddingTop: "10px", marginLeft: "25px" }}
              >
                <h1>{unitName}</h1>
                <h3>Account Ledger Report</h3>
                <div className="d-flex justify-content-between">
                  <h4>
                    Ledger Dates :- {from} To {toDate}
                  </h4>
                  <h4>Account Name:- {sub_AccountData.label}</h4>
                </div>
              </div>
            </div>
            <Table responsive>
              <thead>
                <tr id="tbl" className="text-center">
                  <th>Date</th>
                  <th>VNoVType</th>
                  <th>Description</th>
                  <th>Debit</th>
                  <th>Credit</th>
                  <th className="text-center">Balance</th>
                </tr>
                <tr id="tbl" className="text-center">
                  <th></th>
                  <th></th>
                  <th>Opening Balance</th>
                  <th>{newOpeningBln?.openning_balance_debit}</th>
                  <th>{newOpeningBln?.openning_balance_credit}</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {transactions &&
                  transactions.map((item) => (
                    <tr id="tbl" className="text-center">
                      <td style={{ color: "black" }}>{item?.voucher?.date}</td>
                      <td style={{ color: "black" }}>
                        {item.voucher?.voucher_no}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {item?.remarks}{" "}
                        {item?.remarks &&
                        typeof item.remarks === "string" &&
                        !item.remarks.includes("Campus") &&
                        item?.campus
                          ? "( " + item?.campus?.name + " )"
                          : null}
                      </td>

                      <td style={{ color: "black" }}>{item.debit}</td>
                      <td style={{ color: "black" }}>{item.credit}</td>
                      <td style={{ color: "black", width: "150px" }}>
                        {/* {Number(item?.blnc)< 0 ? item?.blnc.toString().replace(/-/g, '') + " " +"DR":item?.blnc + " " +"CR"} */}
                        {Math.abs(item.blnc)}{" "}
                        {Math.abs(item.blnc) != 0 ? item.type : ""}
                        {/* {account.torise_debit == 1
                          ? balance -  item.credit +   item.debit 
                          : balance + item.credit  - item.debit } */}
                        {/* {sayapa(item.credit, item.debit)} */}
                      </td>
                    </tr>
                  ))}
                {subtoatal ? (
                  <>
                    <tr
                      id="tbl"
                      className="text-center"
                      style={{ borderTop: "1px solid black" }}
                    >
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      ></td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      ></td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      ></td>

                      <td style={{ color: "black", fontWeight: "bolder" }}>
                        {gettotaldebit()}
                      </td>
                      <td style={{ color: "black", fontWeight: "bolder" }}>
                        {gettotalCredit()}
                      </td>

                      <td style={{ color: "black" }}></td>
                    </tr>
                  </>
                ) : null}
                {/* for grand total */}

                {subtoatal ? (
                  <>
                    <tr
                      id="tbl"
                      className="text-center"
                      style={{ borderTop: "1px solid black" }}
                    >
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      ></td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      ></td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      ></td>
                      <td
                        colSpan={2}
                        style={{
                          color: "black",
                          fontWeight: "bolder",
                          fontSize: "14px",
                        }}
                        className="text_align_start"
                      >
                        Grand Total :-
                      </td>

                      <td style={{ color: "black", fontSize: "14px" }}>
                        {/* {account.torise_debit == 1
                          ? balance - gettotalCredit() + gettotaldebit()
                          : balance + gettotalCredit() - gettotaldebit()} */}
                        {customToRise == 1
                          ? Math.abs(
                              balance - gettotalCredit() + gettotaldebit()
                            )
                          : Math.abs(
                              balance + gettotalCredit() - gettotaldebit()
                            )}{" "}
                        {transactions[transactions?.length - 1]?.blnc != 0
                          ? transactions[transactions?.length - 1]?.type
                          : ""}
                      </td>
                    </tr>
                  </>
                ) : null}
              </tbody>
            </Table>
          </div>
        ) : null}
      </Paper>

      {/* for print/// */}

      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
        className="tablePrint"
      >
        <div className="p-3" style={{ display: "flex" }}>
          <img
            // className='col-md-2'
            style={{ height: 110, width: 120 }}
            src={icon}
          />

          <div
            className="col-md-8"
            style={{ paddingTop: "10px", marginLeft: "25px" }}
          >
            <h1>{unitName}</h1>
            <h3>Account Ledger Report</h3>
            <div className="d-flex justify-content-between">
              <h4>
                Ledger Dates :- {from} To {toDate}
              </h4>
              <h4>Account Name:- {sub_AccountData.label}</h4>
            </div>
          </div>
        </div>

        <div id="tableboot">
          <Table>
            <thead>
              <tr id="tbl" className="text-center">
                <th className="fontchagerledger">Date</th>
                <th className="fontchagerledger">VNoVType</th>
                <th className="fontchagerledger">Description</th>
                <th className="fontchagerledger">Debit</th>
                <th className="fontchagerledger">Credit</th>
                <th className="fontchagerledger">Balance</th>
              </tr>
              <tr id="tbl" className="text-center">
                <th></th>
                <th></th>
                <th className="fontchagerledger">Opening Balance</th>
                <th className="fontchagerledger">
                  {newOpeningBln?.openning_balance_debit}
                </th>
                <th className="fontchagerledger">
                  {newOpeningBln?.openning_balance_credit}
                </th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {transactions &&
                transactions.map((item) => (
                  <tr id="tbl" className="text-center">
                    <td
                      style={{
                        color: "black",
                        width: "130px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                      className="fontchagerledger"
                    >
                      {item?.voucher?.date}
                    </td>
                    <td
                      style={{
                        color: "black",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                      className="fontchagerledger"
                    >
                      {item.voucher?.voucher_no}
                    </td>
                    <td
                      style={{
                        color: "black",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                      className="text_align_start fontchagerledger"
                    >
                      {item.remarks}{" "}
                      {item?.remarks &&
                      typeof item.remarks === "string" &&
                      !item.remarks.includes("Campus") &&
                      item?.campus
                        ? "( " + item?.campus?.name + " )"
                        : null}
                    </td>

                    <td
                      style={{
                        color: "black",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                      className="fontchagerledger"
                    >
                      {item.debit}
                    </td>
                    <td
                      style={{
                        color: "black",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                      className="fontchagerledger"
                    >
                      {item.credit}
                    </td>
                    <td
                      className="fontchagerledger"
                      style={{
                        color: "black",
                        width: "150px",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                    >
                      {Math.abs(item.blnc)}{" "}
                      {Math.abs(item.blnc) != 0 ? item.type : ""}
                      {/* {account.torise_debit == 1
                          ? balance -  item.credit +   item.debit 
                          : balance + item.credit  - item.debit } */}
                      {/* {sayapa(item.credit, item.debit)} */}
                    </td>
                  </tr>
                ))}
              {subtoatal ? (
                <>
                  <tr
                    id="tbl"
                    className="text-center"
                    style={{ borderTop: "1px solid black" }}
                  >
                    <td
                      style={{ color: "black" }}
                      className="text_align_start fontchagerledger"
                    ></td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_start fontchagerledger"
                    ></td>
                    <td
                      colSpan={2}
                      style={{
                        color: "black",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                      className="text-end fontchagerledger"
                    >
                      {gettotaldebit()} DR
                    </td>
                    <td
                      colSpan={2}
                      style={{
                        color: "black",
                        whiteSpace: "normal",
                        padding: "1px",
                      }}
                      className="text-center fontchagerledger"
                    >
                      {gettotalCredit()} CR
                    </td>
                  </tr>
                </>
              ) : null}
              {/* for grand total */}

              {subtoatal ? (
                <>
                  <tr
                    id="tbl"
                    className="text-center"
                    style={{ borderTop: "1px solid black" }}
                  >
                    <td
                      style={{ color: "black" }}
                      className="text_align_start"
                    ></td>
                    <td
                      style={{ color: "black" }}
                      className="text_align_start"
                    ></td>
                    <td
                      colSpan={2}
                      style={{
                        color: "black",
                        fontWeight: "bolder",
                        whiteSpace: "normal",
                        padding: "1px",
                        fontSize: "20px",
                      }}
                      className="text-end fontchagerledger"
                    >
                      Grand Total :-
                    </td>

                    <td
                      colSpan={2}
                      className="text-center fontchagerledger"
                      style={{
                        color: "black",
                        whiteSpace: "normal",
                        padding: "1px",
                        fontWeight: "bolder",
                        fontSize: "20px",
                      }}
                    >
                      {customToRise == 1
                        ? Math.abs(balance - gettotalCredit() + gettotaldebit())
                        : Math.abs(
                            balance + gettotalCredit() - gettotaldebit()
                          )}{" "}
                      {transactions[transactions?.length - 1]?.blnc != 0
                        ? transactions[transactions?.length - 1]?.type
                        : ""}
                    </td>
                  </tr>
                </>
              ) : null}
            </tbody>
          </Table>
        </div>

        <div className="lower-content">
          <div>
            <div>
              <b className="fontchagerledger"></b>
            </div>
          </div>
          <div className="lower1">
            <div className="lower2">
              <b className="fontchagerledger">Accountant</b>
            </div>
          </div>
          <div className="lower1" id="lowerLast">
            <div className="lower2">
              {directorSign == 0 ? (
                <b className="fontchagerledger">Chief Executive</b>
              ) : (
                <b className="fontchagerledger">Director</b>
              )}
            </div>
          </div>
        </div>
        <hr className="page1-line" />
      </div>
    </>
  );
};

export default AccountLedger;
