import React, { useRef } from "react";
import { Table } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";

const AccountLedgerExport = ({
  transactions,
  newOpeningBln,
  customToRise,
  gettotalCredit,
  gettotaldebit,
  from,
  toDate,
  sub_AccountData,
  balance,
  subtoatal,
}) => {
  const tableRef = useRef(null);

  return (
    <>
      <DownloadTableExcel
        filename="Account Ledger"
        sheet="Account Ledger"
        currentTableRef={tableRef.current}
      >
        <button className="btn btn-primary mx-2"> Export </button>
      </DownloadTableExcel>

      <Table ref={tableRef} style={{ display: "none" }}>
        <tr>
          <td colSpan={6}>
            <h4 className="ffonmt">
              Ledger Dates : {from} To {toDate}
            </h4>
          </td>
        </tr>
        <tr style={{ display: "flex" }}>
          <td colSpan={6}>
            <h4 className="ffonmt">Account Name: {sub_AccountData.label}</h4>
          </td>
        </tr>
        <thead>
          <tr id="tbl">
            <th className="fontchagerledger">Date</th>
            <th className="fontchagerledger">VNoVType</th>
            <th className="fontchagerledger">Description</th>
            <th className="fontchagerledger">Debit</th>
            <th className="fontchagerledger">Credit</th>
            <th className="fontchagerledger" style={{ textAlign: "center" }}>
              Balance
            </th>
          </tr>
          <tr id="tbl">
            <th></th>
            <th></th>
            <th className="fontchagerledger">Opening Balance</th>
            <th className="fontchagerledger">
              {newOpeningBln?.openning_balance_debit}
            </th>
            <th className="fontchagerledger">
              {newOpeningBln?.openning_balance_credit}
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {transactions &&
            transactions.map((item) => (
              <tr id="tbl">
                <td
                  style={{
                    color: "black",
                    width: "130px",
                    textAlign: "center",
                  }}
                  className="fontchagerledger"
                >
                  {item?.voucher?.date}
                </td>
                <td
                  style={{ color: "black" }}
                  className="text_align_start  fontchagerledger"
                >
                  {item.voucher?.voucher_no}
                </td>
                <td
                  style={{ color: "black" }}
                  className="text_align_start fontchagerledger"
                >
                  {item.remarks}{" "}
                  {item?.remarks &&
                  typeof item.remarks === "string" &&
                  !item.remarks.includes("Campus") &&
                  item?.campus
                    ? "( " + item?.campus?.name + " )"
                    : null}
                </td>

                <td
                  style={{ color: "black", textAlign: "center" }}
                  className="fontchagerledger"
                >
                  {item.debit}
                </td>
                <td
                  style={{ color: "black", textAlign: "center" }}
                  className="fontchagerledger"
                >
                  {item.credit}
                </td>
                <td
                  className="fontchagerledger"
                  style={{ color: "black", width: "150px" }}
                >
                  {Math.abs(item.blnc)}{" "}
                  {Math.abs(item.blnc) != 0 ? item.type : ""}
                  {/* {account.torise_debit == 1
                          ? balance -  item.credit +   item.debit 
                          : balance + item.credit  - item.debit } */}
                  {/* {sayapa(item.credit, item.debit)} */}
                </td>
              </tr>
            ))}
          {subtoatal ? (
            <>
              <tr id="tbl" style={{ borderTop: "1px solid black" }}>
                <td
                  style={{ color: "black" }}
                  className="text_align_start fontchagerledger"
                ></td>
                <td
                  style={{ color: "black" }}
                  className="text_align_start fontchagerledger"
                ></td>
                <td
                  style={{ color: "black" }}
                  className="text_align_start fontchagerledger"
                ></td>

                <td
                  style={{
                    color: "black",
                    fontWeight: "bolder",
                    textAlign: "center",
                  }}
                  className="fontchagerledger"
                >
                  {gettotaldebit()}
                </td>
                <td
                  style={{
                    color: "black",
                    fontWeight: "bolder",
                    textAlign: "center",
                  }}
                  className="fontchagerledger"
                >
                  {gettotalCredit()}
                </td>

                <td style={{ color: "black" }}></td>
              </tr>
            </>
          ) : null}
          {/* for grand total */}

          {subtoatal ? (
            <>
              <tr id="tbl" style={{ borderTop: "1px solid black" }}>
                <td
                  style={{ color: "black" }}
                  className="text_align_start"
                ></td>
                <td
                  style={{ color: "black" }}
                  className="text_align_start"
                ></td>
                <td
                  style={{ color: "black" }}
                  className="text_align_start"
                ></td>

                <td
                  style={{ color: "black" }}
                  className="text_align_start"
                ></td>
                <td
                  style={{ color: "black", fontWeight: "bolder" }}
                  className="text_align_start fontchagerledger"
                >
                  Grand Total
                </td>

                <td
                  className="fontchagerledger"
                  style={{ color: "black", fontWeight: "bolder" }}
                >
                  {customToRise == 1
                    ? Math.abs(balance - gettotalCredit() + gettotaldebit())
                    : Math.abs(
                        balance + gettotalCredit() - gettotaldebit()
                      )}{" "}
                  {transactions[transactions?.length - 1]?.blnc != 0
                    ? transactions[transactions?.length - 1]?.type
                    : ""}
                </td>
              </tr>
            </>
          ) : null}
        </tbody>
      </Table>
    </>
  );
};

export default AccountLedgerExport;
