import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Table, Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { baseUrl } from "../../../services/config";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useAlert } from "react-alert";
import { Modal as AntModal } from "antd";
// import EditCampus from './EditCampus/EditCampus';
// import AddCampus from "./Form/AddCampus";
import CustomSelect from "../Student/Form/CustomSelect";

import { useFormik, Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Input,
  DatePickerField,
  TextArea,
} from "../../../_metronic/_partials/controls";
import Select from "react-select";
import { set } from "date-fns";

import SearchVoucher from "./VoucherSearch";
import { Toaster, toast } from "react-hot-toast";
import AccountVoucherPrint from "./AccountVoucherPrint";
import { PremissionChecker } from "../../../services/PremissionChecker";

export default function Accountvocher({ className }) {
  let red_recevingdate = useSelector(
    (state) => state.campuses.setchallanrecevingdate
  );

  const user = useSelector((state) => state.user.user);
  const history = useHistory();
  const alert = useAlert();
  // const [state, setstate] = useState(initialState)
  const [isvouchertype, setisvouchertype] = useState(false);

  const [contra_account_selected, setcontra_account_selected] = useState("");
  const [target_account_selected, settarget_account_selected] = useState("");

  const [deleteModal, setdeleteModal] = useState(false);
  const [currentCampusId, setcurrentCampusId] = useState("");
  const [modalvisible, setmodalvisible] = useState(false);
  const [selectedCampus, setselectedCampus] = useState({});
  const [loading, setloading] = useState(false);
  const [addloading, setaddloading] = useState(false);

  const [allsessions, setallsessions] = useState([]);
  const [allcontraAccount, setallcontraAccount] = useState([]);
  const [contraAccount, setcontraAccount] = useState([]);
  const [alltargetAccount, setalltargetAccount] = useState([]);
  const [alltargetAccountForSelect, setalltargetAccountForSelect] = useState(
    []
  );
  const [allcoucherid, setallcoucherid] = useState([]);

  const [currentindex, setcurrentindex] = useState(0);

  const [allcampuses, setallcampuses] = useState(user.campuses || []);
  const [targetaccountlist, settargetaccountlist] = useState([]);

  const [alltargetcampuslist, setalltargetcampuslist] = useState([]);
  const [alltargetdebitlist, setalltargetdebitlist] = useState([]);
  const [alltargetcreditlist, setalltargetcreditlist] = useState([]);
  const [Vochers, setVochers] = useState([]);
  const [printvouchermodal, setprintvouchermodal] = useState(false);

  const [targetVochers, settargetVochers] = useState([]);
  const [contraVoucher, setcontraVoucher] = useState([]);

  let [contra_account, setcontra_account] = useState([]);
  const [currentSession, setcurrentSession] = useState("");

  const [alltarget_acodes, setalltarget_acodes] = useState([]);

  const [voucherdate, setvoucherdate] = useState("");
  const [vouchercontraaccount, setvouchercontraaccount] = useState("");
  const [vouchercontraaccount_des, setvouchercontraaccount_des] = useState("");
  const [selectedvoucher, setselectedvoucher] = useState({});

  const [totaldebit, settotaldebit] = useState(0);
  const [totalcredit, settotalcredit] = useState(0);

  const [jvtotalamount, setjvtotalamount] = useState(false);

  //jv vouchers
  const [isjvvoucher, setisjvvoucher] = useState(false);

  const [jvcampus_id, setjvcampus_id] = useState("");

  const [jvchaqueno, setjvchaqueno] = useState("");

  //jv vouchers
  const [campusId, setCampusId] = useState("");
  const [vouchertype, setvouchertype] = useState("");

  //vouchersenddata
  const [targetaccoutids, settargetaccoutids] = useState([]);
  const [targetcampusids, settargetcampusids] = useState([]);
  const [targetremarks, settargetremarks] = useState([]);

  const [totalcontradebit, settotalcontradebit] = useState(0);
  const [totalcontracredet, settotalcontracredet] = useState(0);

  const [savevoucherloader, setsavevoucherloader] = useState(false);
  let authtoken = localStorage.getItem("userToken");
  const [editModal, setEditModel] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [editCredit, setEditCredit] = useState("");
  const [yearId, setYearId] = useState("");
  const [yearError, setYearError] = useState(false);
  const [editDrop, setEditDrop] = useState({});
  const [allCampusIDzz, setAllCampusIdzz] = useState([]);
  const [vouchers, setVouchers] = useState([]);
  const [openingBlncCredit, setOpeningBlncCredit] = useState(0);
  const [openingBlncDebit, setOpeningBlncDebit] = useState(0);
  useEffect(() => {
    setvoucherdate(red_recevingdate);
  }, [red_recevingdate]);

  useEffect(() => {
    // GetAllAccountsVocher()
    getSessions();
    getAllCountraAccounts();
    getAllTargetAccounts();
    // getAllCampuses();
    getAllVoucherTypes();
  }, []);

  const getSubAccountBalance = (id) => {
    if (id == "") {
      setOpeningBlncCredit(0);
      setOpeningBlncDebit(0);
      return;
    }
    const data = {
      year_id: yearId,
      sub_account_id: id,
    };
    fetch(`${baseUrl}/sub-account-balance`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setOpeningBlncCredit(data.payload?.opening_balance_credit);
          setOpeningBlncDebit(data.payload?.opening_balance_debit);
        }
      })
      .catch((err) => {
        console.log("err is re", err);
      });
  };

  const getAllVoucherTypes = async () => {
    await fetch(`${baseUrl}/vouchertype`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setVouchers(data.payload);
            const reser = data.payload.filter((item) => item.name != "DN");
            console.log(data, "datadata");
            setallcoucherid(reser);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCampuses = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setallcampuses([...data.payload]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllTargetAccounts = async () => {
    let arr = [];
    await fetch(`${baseUrl}/subaccount`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setalltargetAccount([...data.payload]);
            data.payload.forEach((element) => {
              arr.push({
                label: element.title + " (" + element.acode + ")",
                value: element.id,
              });
            });
            console.log(data.payload, "select data");
            setalltargetAccountForSelect(arr);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCountraAccounts = async () => {
    await fetch(`${baseUrl}/subaccount_contra`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            console.log(data.payload, "this is data 3");
            setallcontraAccount([...data.payload]);
            setcontraAccount([...data.payload]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSessions = async () => {
    await fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setallsessions([...data.payload]);
            data.payload.filter((item) => {
              if (item.active_financial_year == "1") {
                setYearId(item.id);
              }
            });
            let currentyear = new Date().getFullYear();

            let getcurrentsession;
            getcurrentsession = data.payload.filter((item) => {
              return currentyear == item.year.slice(0, 4);
            });
            setcurrentSession(getcurrentsession[0]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dispatch = useDispatch();

  const JVVoucherData = () => {
    // let targetdata = {
    //     target_sub_account_id: currenttargetaccount,
    //     target_debit: debetlist,
    //     target_credit: creditlist,
    //     acode: alltarget_acodes
    // }
  };

  const AddNewAccountVocher = (values) => {
    // if (isjvvoucher) {
    //     JVVoucherData()
    //     return;
    // }
    setCampusId(values.campus_id);

    if (editModal) {
      targetremarks[editIndex] = values.target_remarks;
      targetaccountlist.splice(
        editIndex,
        1,
        values.target_sub_account_id.value
      );
      settargetaccountlist(targetaccountlist);
      alltargetcampuslist.splice(editIndex, 1, values.campus_id);
      setalltargetcampuslist(alltargetcampuslist);
      if (!isjvvoucher) {
        console.log(contraVoucher, "currentvoucher");
        var beforecredit =
          totalcontracredet -
          parseInt(targetVochers[0].target_debit[editIndex]);
        var beforedebit =
          totalcontradebit -
          parseInt(targetVochers[0].target_credit[editIndex]);
        var condebit =
          contraVoucher[0].contra_debit -
          parseInt(targetVochers[0].target_credit[editIndex]);
        var concredit =
          contraVoucher[0].contra_credit -
          parseInt(targetVochers[0].target_debit[editIndex]);

        if (targetVochers[0].target_debit[editIndex] == 0) {
          var targetdebit =
            totaldebit - parseInt(targetVochers[0].target_credit[editIndex]);
          var targetcredit =
            totalcredit - parseInt(targetVochers[0].target_credit[editIndex]);
        } else {
          var targetdebit =
            totaldebit - parseInt(targetVochers[0].target_debit[editIndex]);
          var targetcredit =
            totalcredit - parseInt(targetVochers[0].target_debit[editIndex]);
        }
      } else {
        let jvsumofdebit = 0;

        targetVochers[0].target_debit.map((item) => {
          jvsumofdebit = parseInt(jvsumofdebit) + parseInt(item);
        });

        let jvsumofcredit = 0;

        targetVochers[0].target_credit.map((item) => {
          jvsumofcredit = parseInt(jvsumofcredit) + parseInt(item);
        });

        settotalcredit(jvsumofcredit);
        settotaldebit(jvsumofdebit);
      }

      var filteredsubaccount = alltargetAccount.find((item) => {
        return item.id == values.target_sub_account_id.value;
      });
      targetVochers[0].target_credit.splice(
        editIndex,
        1,
        parseInt(values.target_credit)
      );
      targetVochers[0].target_debit.splice(
        editIndex,
        1,
        parseInt(values.target_debit)
      );
      targetVochers[0].target_sub_account_id.splice(
        editIndex,
        1,
        filteredsubaccount
      );
      targetVochers[0].acode.splice(editIndex, 1, filteredsubaccount.acode);

      const newArr = targetaccoutids.map((item) =>
        item == targetaccoutids[editIndex] ? filteredsubaccount.id : item
      );
      settargetaccoutids(newArr);
      // const newArrforCampus = allCampusIDzz.map(item => (item ==allCampusIDzz[editIndex] ? values.campus_id : item));
      allCampusIDzz.splice(editIndex, 1, values.campus_id);
      setAllCampusIdzz(allCampusIDzz);

      var currenttargetcampus = allcampuses.find((item) => {
        return item.id == values.campus_id;
      });
      targetVochers[0].target_campus.splice(editIndex, 1, currenttargetcampus);

      if (!isjvvoucher) {
        console.log(contraVoucher, "currentvoucher");

        settotalcontracredet(
          beforecredit + parseInt(targetVochers[0].target_debit[editIndex])
        );
        settotalcontradebit(
          beforedebit + parseInt(targetVochers[0].target_credit[editIndex])
        );

        if (targetVochers[0].target_debit[editIndex] == 0) {
          settotalcredit(
            targetcredit + parseInt(targetVochers[0].target_credit[editIndex])
          );
          settotaldebit(
            targetdebit + parseInt(targetVochers[0].target_credit[editIndex])
          );
        } else {
          settotalcredit(
            targetcredit + parseInt(targetVochers[0].target_debit[editIndex])
          );
          settotaldebit(
            targetdebit + parseInt(targetVochers[0].target_debit[editIndex])
          );
        }

        let updateVoucher = {
          acode: contraVoucher[0].acode,
          contra_credit:
            concredit + parseInt(targetVochers[0].target_debit[editIndex]),
          contra_debit:
            condebit + parseInt(targetVochers[0].target_credit[editIndex]),
          contra_sub_account_id: contraVoucher[0].contra_sub_account_id,
        };
        const yello = [];
        yello.push(updateVoucher);
        console.log(yello);
        setcontraVoucher([...yello]);
      } else {
        let jvsumofdebit = 0;

        targetVochers[0].target_debit.map((item) => {
          jvsumofdebit = parseInt(jvsumofdebit) + parseInt(item);
        });

        let jvsumofcredit = 0;

        targetVochers[0].target_credit.map((item) => {
          jvsumofcredit = parseInt(jvsumofcredit) + parseInt(item);
        });

        settotalcredit(jvsumofcredit);
        settotaldebit(jvsumofdebit);
      }

      settarget_account_selected("");
      values.target_sub_account_id = { label: "", value: "" };
      values.target_debit = "";
      values.target_credit = "";
      values.target_remarks = "";
      values.campus_id = "";
      setEditModel(false);
    } else {
      console.log(targetVochers, "update3");

      setcurrentindex(parseInt(currentindex) + 1);

      let creditlist = alltargetcreditlist;
      if (values.target_credit == "") {
        creditlist[currentindex] = parseInt(0);
      } else {
        creditlist[currentindex] = parseInt(values.target_credit);
        settotalcredit(parseInt(totalcredit) + parseInt(values.target_credit));
      }
      setalltargetcreditlist(creditlist);

      let currenttargetaccount = [];

      let accountlist = targetaccountlist;
      accountlist[currentindex] = values.target_sub_account_id.value;
      settargetaccountlist(accountlist);

      accountlist.map((item, index) => {
        alltargetAccount.map((itm) => {
          if (itm.id == item) {
            currenttargetaccount.push(itm);
          }
        });
      });

      settargetaccoutids(accountlist);
      let targetaccount_remarks = targetremarks;
      targetaccount_remarks[currentindex] = values.target_remarks;
      settargetremarks([...targetaccount_remarks]);
      console.log(alltargetdebitlist, "jar");
      let debetlist = alltargetdebitlist;
      if (values.target_debit == "") {
        debetlist[currentindex] = parseInt(0);
      } else {
        debetlist[currentindex] = parseInt(values.target_debit);
      }

      setalltargetdebitlist(debetlist);
      ////here///
      let sumofdebit = 0;

      creditlist.map((item) => {
        sumofdebit = parseInt(sumofdebit) + parseInt(item);
      });

      let sumofcredit = 0;
      let totalsumofbedet = 0;

      debetlist.map((item) => {
        sumofcredit = parseInt(sumofcredit) + parseInt(item);
      });

      let currentcredetaccount = allcontraAccount.filter((item) => {
        return values.contra_sub_account_id == item.id;
      });

      setjvchaqueno(values.cheque_no);

      let alltargetacodes = alltarget_acodes;

      alltargetacodes.push(target_account_selected);
      setalltarget_acodes(alltargetacodes);
      let campusIdzz = allCampusIDzz;

      campusIdzz.push(values.campus_id);
      setAllCampusIdzz(campusIdzz);

      let currenttargetcampus = [];

      allCampusIDzz.map((item, index) => {
        allcampuses.map((itm) => {
          if (itm.id == item) {
            currenttargetcampus.push(itm);
          }
        });
      });

      let targetdata = {
        target_sub_account_id: currenttargetaccount,
        target_campus: currenttargetcampus,
        target_debit: debetlist,
        target_credit: creditlist,
        acode: alltarget_acodes,
      };
      console.log(currenttargetaccount, "hehajhweua22222222");
      console.log(targetaccountlist, "hehajhweua2222222233333333");

      setjvcampus_id(values.campus_id);

      settotalcontradebit(sumofdebit);
      settotalcontracredet(sumofcredit);

      if (!isjvvoucher) {
        let contradata = {
          contra_debit: sumofdebit,
          contra_credit: sumofcredit,
          contra_sub_account_id: currentcredetaccount[0].title,

          acode: contra_account_selected,
        };
        let contradataarray = [];
        contradataarray.push(contradata);
        console.log(contradataarray, "update");
        setcontraVoucher([...contradataarray]);

        setjvtotalamount(false);
        settotalcredit(sumofdebit + sumofcredit);
        settotaldebit(sumofdebit + sumofcredit);
      } else {
        let jvsumofdebit = 0;

        debetlist.map((item) => {
          jvsumofdebit = parseInt(jvsumofdebit) + parseInt(item);
        });

        let jvsumofcredit = 0;

        creditlist.map((item) => {
          jvsumofcredit = parseInt(jvsumofcredit) + parseInt(item);
        });

        settotalcredit(jvsumofcredit);
        settotaldebit(jvsumofdebit);
        console.log(jvsumofcredit, "???????????????");
        console.log(jvsumofdebit, "???????????????????");
        if (Math.abs(jvsumofcredit - jvsumofdebit) == 0) {
          setjvtotalamount(false);
        } else {
          setjvtotalamount(true);
        }
      }

      let targetdataarray = [];

      targetdataarray.push(targetdata);

      setvoucherdate(values.date);
      setvouchercontraaccount(values.contra_sub_account_id);
      setvouchercontraaccount_des(values.remarks);
      setvouchertype(values.voucher_type_id);

      settarget_account_selected("");

      settargetVochers([...targetdataarray]);
      console.log([...targetdataarray], "update2");

      targetdataarray[0].target_credit.map((vocher, index) => {
        console.log(targetdataarray[0]);
        console.log(targetdataarray[0].target_sub_account_id);
        console.log(targetdataarray[0].target_sub_account_id[index].title);
      });
      settarget_account_selected("");
      values.target_sub_account_id = { label: "", value: "" };
      values.target_debit = "";
      values.target_credit = "";
      values.target_remarks = "";
      values.campus_id = "";
    }
  };
  console.log(allCampusIDzz, "this is campus idzzz");
  const resetData = (values, resetForm) => {
    setcontra_account_selected("");
    settarget_account_selected("");

    setvoucherdate("");
    setvouchercontraaccount("");
    setvouchercontraaccount_des("");

    settarget_account_selected("");
    setisvouchertype(false);
    setisjvvoucher(false);
    setcontraVoucher([]);
    settargetVochers([]);

    setalltargetcreditlist([]);
    setcurrentindex(0);
    settargetaccountlist([]);
    setalltargetcampuslist([]);
    setalltargetdebitlist([]);
    setalltarget_acodes([]);

    settotaldebit(0);
    settotalcredit(0);
    setjvtotalamount(false);

    setvouchertype("");
    setjvchaqueno("");

    // if (resetForm != undefined) {
    //   resetForm();
    // }
  };

  const cancelModal = (e, values, resetForm) => {
    e.preventDefault();
    setmodalvisible(false);
    resetData(values, resetForm);
  };

  const initialValues = {
    // session_id: "",
    date: voucherdate,
    voucher_type_id: vouchertype,
    contra_sub_account_id: vouchercontraaccount,

    voucher_no: "",
    balance: "",
    remarks: vouchercontraaccount_des,

    target_sub_account_id: "",
    account_code: "",

    cheque_no: jvchaqueno,
    campus_id: "1",

    target_remarks: "",
    target_debit: "",
    target_credit: "",
  };
  const editValues = {
    // session_id: "",
    date: voucherdate,
    voucher_type_id: vouchertype,
    contra_sub_account_id: vouchercontraaccount,

    voucher_no: "",
    balance: "",
    remarks: vouchercontraaccount_des,

    target_sub_account_id: { label: editDrop.title, value: editDrop.id },
    account_code: "",

    cheque_no: jvchaqueno,
    campus_id: allCampusIDzz[editIndex],

    target_remarks: targetremarks[editIndex],
    target_debit:
      targetVochers.length > 0 ? targetVochers[0].target_debit[editIndex] : "",
    target_credit:
      targetVochers.length > 0 ? targetVochers[0].target_credit[editIndex] : "",
  };

  const AccountVoucher_Schema = Yup.object().shape(
    {
      // session_id: Yup.string().required("Financial Year is required"),

      cheque_no: Yup.string().when("voucher_type_id", (voucher_type_id) => {
        if (voucher_type_id == "3" || voucher_type_id == "4")
          return Yup.string()
            .notRequired("cheque no is not required!")
            .nullable();
      }),

      date: Yup.string().required(" Date is required field"),

      voucher_type_id: Yup.string().required("Financial Year is required"),

      // contra_sub_account_id: Yup.string()

      contra_sub_account_id: Yup.string().when(
        "voucher_type_id",
        (voucher_type_id) => {
          if (voucher_type_id != "5")
            return Yup.string().required(" Credit is required field");
        }
      ),

      // .required(' Contra Account  is required '),

      remarks: Yup.string().notRequired("Remarks Year is not required"),

      target_sub_account_id: Yup.string().required(
        "Target Sub Account is required field"
      ),

      campus_id: Yup.string().required("Campus  is required"),

      target_remarks: Yup.string().notRequired(
        " Remarks is not required field"
      ),

      // target_debit: Yup
      //     .string()
      //     .when("voucher_type_id", (voucher_type_id) => {
      //         if (voucher_type_id == "2" || voucher_type_id == "4" || voucher_type_id == "5")
      //             return Yup.string().required('Debit is required')
      //     }),

      target_debit: Yup.string().when(["voucher_type_id", "target_credit"], {
        is: (voucher_type_id) =>
          voucher_type_id == "2" ||
          voucher_type_id == "4" ||
          voucher_type_id == "5",
        then: Yup.string().when(["voucher_type_id", "target_credit"], {
          is: (voucher_type_id) => voucher_type_id == "5",
          then: Yup.string().when("target_credit", {
            is: (target_credit) => target_credit == undefined,
            then: Yup.string()
              .required("Credit is required field")
              .matches(/^[0-9]*$/, "Credit Musrt Be a Number"),
            otherwise: Yup.string().notRequired(),
          }),
          otherwise: Yup.string()
            .required("Credit is required field")
            .matches(/^[0-9]*$/, "Credit Musrt Be a Number"),
        }),
        otherwise: Yup.string().notRequired(),
      }),

      target_credit: Yup.string().when(["voucher_type_id", "target_debit"], {
        is: (voucher_type_id) =>
          voucher_type_id == "1" ||
          voucher_type_id == "3" ||
          voucher_type_id == "5",
        then: Yup.string().when(["voucher_type_id", "target_debit"], {
          is: (voucher_type_id) => voucher_type_id == "5",
          then: Yup.string().when("target_debit", {
            is: (target_debit) => target_debit == undefined,
            then: Yup.string()
              .required("Credit is required field")
              .matches(/^[0-9]*$/, "Credit Musrt Be a Number"),
            otherwise: Yup.string().notRequired(),
          }),
          otherwise: Yup.string()
            .required("Credit is required field")
            .matches(/^[0-9]*$/, "Credit Musrt Be a Number"),
        }),
        otherwise: Yup.string().notRequired(),
      }),
    },
    ["target_credit", "target_debit"]
  );

  const onChangeHandler = (e, values, current) => {
    console.log(e);
    let id = e;
    if (current == "contra_account") {
      let dataofaccount = allcontraAccount.filter((item) => {
        return id == item.id;
      });

      setcontra_account_selected(dataofaccount[0]?.acode);
    } else if (current == "target_account") {
      let dataofaccount = alltargetAccount.filter((item) => {
        return id == item.id;
      });
      settarget_account_selected(dataofaccount[0]?.acode);
    }
  };
  const onVoucherTypeChange = (e) => {
    console.log(e.target.value);
    console.log(contraAccount);
    const updatedoption = contraAccount.filter((item) => {
      if (e.target.value == "1" || e.target.value == "2") {
        return item.acode == 14020001;
      } else {
        return item.acode != 14020001;
      }
    });
    setallcontraAccount(updatedoption);

    setisjvvoucher(false);
    if (e.target.value == "") {
      setisvouchertype(false);
    } else {
      setisvouchertype(true);
    }
    if (e.target.value == "5") {
      setisjvvoucher(true);
    }
  };

  const isSubmittingBlock = (e, setSubmitting, isSubmitting) => {
    setSubmitting(false);
  };

  const SaveVoucher = (values, resetForm) => {
    if (yearId == "") {
      setYearError(true);
      return;
    }
    let data = {};
    if (!isjvvoucher) {
      if (values.voucher_type_id == "3" || values.voucher_type_id == "4") {
        data = {
          year_id: yearId,
          date: values.date,
          voucher_type_id: values.voucher_type_id,
          campus_id: allCampusIDzz,

          check_no: jvchaqueno,

          contra_sub_account_id: values.contra_sub_account_id,
          remarks: values.remarks,
          contra_debit: totalcontradebit,
          contra_credit: totalcontracredet,

          target_sub_account_id: targetaccoutids,
          target_debit: alltargetdebitlist,
          target_credit: alltargetcreditlist,
          target_remarks: targetremarks,
        };
      } else {
        data = {
          year_id: yearId,
          date: values.date,
          voucher_type_id: values.voucher_type_id,
          campus_id: allCampusIDzz,

          contra_sub_account_id: values.contra_sub_account_id,
          remarks: values.remarks,
          contra_debit: totalcontradebit,
          contra_credit: totalcontracredet,

          target_sub_account_id: targetaccoutids,
          target_debit: alltargetdebitlist,
          target_credit: alltargetcreditlist,
          target_remarks: targetremarks,
        };
      }
    } else {
      data = {
        year_id: yearId,
        date: values.date,
        voucher_type_id: values.voucher_type_id,
        campus_id: allCampusIDzz,

        target_sub_account_id: targetaccoutids,
        target_debit: alltargetdebitlist,
        target_credit: alltargetcreditlist,
        target_remarks: targetremarks,
      };
    }

    console.log(data);
    setsavevoucherloader(true);

    fetch(`${baseUrl}/voucher`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          // setmodalvisible(false);
          setsavevoucherloader(false);
          resetData(values);
          alert.show("Voucher Addd Successfully");
          setselectedvoucher(data.payload);
          setprintvouchermodal(true);
        } else {
          setsavevoucherloader(false);
          toast.error(data.metadata.message);
        }
      })
      .catch((err) => {
        setsavevoucherloader(false);
        console.log("err is re", err);
      });

    // .then((res) => {
    //   if (res.ok) {
    //     setmodalvisible(false);
    //     setsavevoucherloader(false);
    //     resetData(values);
    //     alert.show("Voucher Addd Successfully");
    //   } else {
    //     setsavevoucherloader(false);
    //   }
    //   res.json();
    // })
    // .then((data) => {
    //   console.log
    //    alert.show(data.metadata.message);
    // })
    // .catch((err) => {
    //   setsavevoucherloader(false);

    //   console.log(err);
    // });
  };
  const onDelete = (index) => {
    let deleteRemoveIdz = allCampusIDzz;
    deleteRemoveIdz.splice(editIndex, 1);
    setAllCampusIdzz(deleteRemoveIdz);
    console.log(deleteRemoveIdz, "onDelete");

    const newIds = targetaccoutids.filter(
      (item) => item != targetaccoutids[index]
    );
    const newRemarks = targetremarks.filter(
      (item) => item != targetremarks[index]
    );

    console.log(newIds, newRemarks, "ya tha edit ma error");
    settargetaccoutids(newIds);
    settargetremarks(newRemarks);

    if (!isjvvoucher) {
      console.log(contraVoucher, "currentvoucher");

      settotalcontracredet(
        totalcontracredet - parseInt(targetVochers[0].target_debit[index])
      );
      settotalcontradebit(
        totalcontradebit - parseInt(targetVochers[0].target_credit[index])
      );

      if (targetVochers[0].target_debit[index] == 0) {
        settotalcredit(
          totalcredit - parseInt(targetVochers[0].target_credit[index])
        );
        settotaldebit(
          totaldebit - parseInt(targetVochers[0].target_credit[index])
        );
      } else {
        settotalcredit(
          totalcredit - parseInt(targetVochers[0].target_debit[index])
        );
        settotaldebit(
          totaldebit - parseInt(targetVochers[0].target_debit[index])
        );
      }

      let updateVoucher = {
        acode: contraVoucher[0].acode,
        contra_credit:
          contraVoucher[0].contra_credit -
          parseInt(targetVochers[0].target_debit[index]),
        contra_debit:
          contraVoucher[0].contra_debit -
          parseInt(targetVochers[0].target_credit[index]),
        contra_sub_account_id: contraVoucher[0].contra_sub_account_id,
      };

      const yello = [];
      yello.push(updateVoucher);
      console.log(updateVoucher, "updated voucher");
      console.log(targetVochers, "before");

      targetVochers[0].target_sub_account_id.splice(index, 1);
      targetVochers[0].acode.splice(index, 1);
      targetVochers[0].target_credit.splice(index, 1);
      targetVochers[0].target_debit.splice(index, 1);
      console.log(targetVochers, "update2");

      if (targetVochers[0].target_sub_account_id.length == 0) {
        setcontraVoucher([]);
        settargetVochers([]);
        setcurrentindex(0);
      } else {
        // let filr = targetVochers[0].tr.filter(value => value !== 'empty');
        setcurrentindex(currentindex - 1);
        setcontraVoucher([...yello]);
        settargetVochers([...targetVochers]);
      }
    } else {
      const yello = [];
      console.log(targetVochers, "before");

      targetVochers[0].target_sub_account_id.splice(index, 1);
      targetVochers[0].acode.splice(index, 1);
      targetVochers[0].target_credit.splice(index, 1);
      targetVochers[0].target_debit.splice(index, 1);
      console.log(targetVochers, "update2");
      let jvsumofdebit = 0;

      targetVochers[0].target_debit.map((item) => {
        jvsumofdebit = parseInt(jvsumofdebit) + parseInt(item);
      });

      let jvsumofcredit = 0;

      targetVochers[0].target_credit.map((item) => {
        jvsumofcredit = parseInt(jvsumofcredit) + parseInt(item);
      });

      settotalcredit(jvsumofcredit);
      settotaldebit(jvsumofdebit);

      if (targetVochers[0].target_sub_account_id.length == 0) {
        settargetVochers([]);
        setcurrentindex(0);
      } else {
        // let filr = targetVochers[0].tr.filter(value => value !== 'empty');
        setcurrentindex(currentindex - 1);
        settargetVochers([...targetVochers]);
      }
    }
  };

  const openEditClick = (index) => {
    let data = alltargetAccount.find((item) => {
      return item.title == targetVochers[0].target_sub_account_id[index].title;
    });
    settarget_account_selected(data.acode);

    setEditDrop(data);
    console.log(data);
    console.log(index, "hassan");
    setEditIndex(index);
    setEditCredit(targetVochers[0].target_credit[index]);
    setEditModel(true);
  };
  console.log(allCampusIDzz, "retyrt");
  return (
    <Paper style={{ marginTop: "20px", marginBottom: "10px" }}>
      <Toaster />
      <div style={{ padding: 10, display: "flex", alignItems: "center" }}>
        {PremissionChecker(user, "account.can_add_voucher") ? (
          <button
            className="btn btn-success button_blue button_blue"
            style={{ margin: 5 }}
            onClick={() => setmodalvisible(true)}
          >
            Add voucher
          </button>
        ) : null}
      </div>
      <div>
        <SearchVoucher allcoucherid={vouchers} />
        {/* <Table>
                    <thead>
                        <tr className="text-center">
                            <th>Sr</th>
                            <th>Acc Code</th>
                            <th>Account Name</th>
                            <th>Description</th>
                            <th>Debit</th>
                            <th>Credit</th>

                        </tr>
                    </thead>
                    <tbody>
                        {



                            Vochers.map((vocher, index) => (

                                <tr key={index} name={index}>
                                    <td>{index}</td>
                                    <td>{vocher.acc_code}</td>
                                    <td>{vocher.acc_name}</td>
                                    <td>{vocher.description}</td>
                                    <td>{vocher.debit}</td>
                                    <td>{vocher.credit}</td>

                                </tr>


                            ))
                        }

                    </tbody>
                </Table>
                <div className="row justify-content-center">
                    {loading && <span className="spinner-border"></span>}
                </div> */}
      </div>

      <Modal
        size="lg"
        show={modalvisible}
        onHide={() => setmodalvisible(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static" // This prevents clicking outside the modal from closing it
        keyboard={false} // This prevents the modal from closing when pressing the Escape key
      >
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="example-modal-sizes-title-lg"
          >
            Voucher Type Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {savevoucherloader ? (
            <b style={{ color: "green" }}>
              voucher Adding Please Wait {` `}{" "}
              <span className="spinner-border"></span>
            </b>
          ) : (
            <>
              <Formik
                enableReinitialize={true}
                initialValues={editModal ? editValues : initialValues}
                validationSchema={AccountVoucher_Schema}
                onSubmit={(
                  values,
                  { resetForm, setSubmitting, setFieldValue, setValues }
                ) => {
                  AddNewAccountVocher(values);
                  resetForm();
                  // setFieldValue("campus_id", values.campus_id);
                  setSubmitting(false);
                }}
              >
                {(props) => {
                  const {
                    handleSubmit,
                    errors,
                    setFieldValue,
                    setValues,
                    touched,
                    values,
                    resetForm,
                    isSubmitting,
                    isValid,
                    setSubmitting,
                    handleChange,
                    handleBlur,
                  } = props;
                  return (
                    <>
                      <Form autoComplete="off">
                        <div className="row">
                          <div className="col-md-6">
                            <label>
                              Financial Year{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              class="form-select"
                              disabled={
                                !PremissionChecker(
                                  user,
                                  "account.can_change_financial_year"
                                )
                              }
                              onChange={(e) => {
                                setYearError(false);
                                setYearId(e.target.value);
                              }}
                              style={{
                                backgroundColor: "white",
                                height: "40px",
                              }}
                              aria-label="Default select example"
                              value={yearId}
                            >
                              <option value="">Select Session</option>
                              {allsessions.map((item, index) => (
                                <option
                                  key={index}
                                  selected={
                                    item.active_financial_year == "1"
                                      ? true
                                      : false
                                  }
                                  value={item.id}
                                >
                                  {item.year}
                                </option>
                              ))}
                            </select>
                            {yearError ? (
                              <div
                                style={{ color: "#f87d8a" }}
                                className="input-feedback"
                              >
                                Year is required
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-6">
                            <label>
                              voucher Date{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <Field
                              name="date"
                              disabled={editModal ? true : false}
                              onChange={(e) => {
                                props.handleChange(e);
                                isSubmittingBlock(
                                  e,
                                  setSubmitting,
                                  isSubmitting
                                );
                              }}
                              component={Input}
                              placeholder="Date"
                              type="date"
                              max="9999-12-30"
                            />
                          </div>
                          <div className="col-md-6">
                            <br />
                            <label>
                              voucher Type{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <select
                              class="form-select form-control"
                              name="voucher_type_id"
                              disabled={editModal ? true : false}
                              value={values.voucher_type_id}
                              onChange={(e) => {
                                props.handleChange(e);
                                onVoucherTypeChange(e);
                                if (
                                  e.target.value == "1" ||
                                  e.target.value == "2"
                                ) {
                                  const updatedoption = contraAccount.find(
                                    (item) => {
                                      if (
                                        e.target.value == "1" ||
                                        e.target.value == "2"
                                      ) {
                                        return item.acode == 14020001;
                                      } else {
                                        return item.acode != 14020001;
                                      }
                                    }
                                  );
                                  getSubAccountBalance(updatedoption.id);
                                  setFieldValue(
                                    "contra_sub_account_id",
                                    updatedoption.id
                                  );
                                } else {
                                  const updatedoption = contraAccount.find(
                                    (item) => {
                                      return item.acode == 14020001;
                                    }
                                  );
                                  if (
                                    values.contra_sub_account_id ==
                                    updatedoption.id
                                  ) {
                                    getSubAccountBalance("");

                                    setFieldValue("contra_sub_account_id", "");
                                  }
                                }

                                isSubmittingBlock(
                                  e,
                                  setSubmitting,
                                  isSubmitting
                                );
                              }}
                              onBlur={handleBlur}
                              style={{ backgroundColor: "white" }}
                              aria-label="Default select example"
                            >
                              <option selected value="">
                                Select Voucher Type
                              </option>
                              {allcoucherid.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {errors.voucher_type_id && touched.voucher_type_id && (
                              <div
                                style={{ color: "#f87d8a" }}
                                className="input-feedback"
                              >
                                {errors.voucher_type_id}
                              </div>
                            )}
                          </div>
                          {isvouchertype ? (
                            <>
                              {!isjvvoucher ? (
                                <>
                                  <div className="col-md-6">
                                    <br />
                                    <label>
                                      Contra Account{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>

                                    <select
                                      class="form-select form-control"
                                      name="contra_sub_account_id"
                                      disabled={editModal ? true : false}
                                      value={values.contra_sub_account_id}
                                      onChange={(e) => {
                                        getSubAccountBalance(e.target.value);
                                        props.handleChange(e);
                                        onChangeHandler(
                                          e.target.value,
                                          values,
                                          "contra_account"
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      style={{ backgroundColor: "white" }}
                                      aria-label="Default select example"
                                    >
                                      <option value="">
                                        Select Contra Account
                                      </option>
                                      {allcontraAccount.map((item, index) => (
                                        <option key={index} value={item.id}>
                                          {item.title}
                                        </option>
                                      ))}
                                    </select>
                                    {errors.contra_sub_account_id &&
                                      touched.contra_sub_account_id && (
                                        <div
                                          style={{ color: "#f87d8a" }}
                                          className="input-feedback"
                                        >
                                          {errors.contra_sub_account_id}
                                        </div>
                                      )}
                                    {openingBlncCredit > 0 && (
                                      <span className="mt-1">
                                        Opening balance credit:{" "}
                                        <b>{openingBlncCredit}</b>
                                      </span>
                                    )}
                                    {openingBlncDebit > 0 && (
                                      <span className="mt-1">
                                        Opening balance debit:{" "}
                                        <b>{openingBlncDebit}</b>
                                      </span>
                                    )}
                                  </div>

                                  {/* <div className="col-md-4">
                                                                                <br />
                                                                                <label>voucher No <span style={{ color: "red" }}>*</span></label>
                                                                                <Field
                                                                                    name="voucher_no"
                                                                                    component={Input}
                                                                                    placeholder="Voucher No"
                                                                                    disabled

                                                                                />
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <br />
                                                                                <label>Balance <span style={{ color: "red" }}>*</span></label>

                                                                                <Field
                                                                                    name="balance"
                                                                                    component={Input}
                                                                                    placeholder="Balance"
                                                                                    disabled

                                                                                />
                                                                            </div> */}

                                  <div className="col-md-6">
                                    <br />
                                    <label>Contra Account Description </label>
                                    <Field
                                      name="remarks"
                                      component={Input}
                                      placeholder="Description"
                                      maxLength={125}
                                    />
                                  </div>
                                </>
                              ) : (
                                <div className="col-md-4">
                                  <br />
                                </div>
                              )}
                              <div className="col-md-6">
                                <br />
                                <label>
                                  Voucher Posting{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                {/* <select
                                  class="form-select form-control"
                                  name="target_sub_account_id"
                                  value={values.target_sub_account_id}
                                  // onChange={handleChange}
                                  onChange={(e) => {
                                    props.handleChange(e);
                                    onChangeHandler(
                                      e,
                                      values,
                                      "target_account"
                                    );
                                    isSubmittingBlock(
                                      e,
                                      setSubmitting,
                                      isSubmitting
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  style={{ backgroundColor: "white" }}
                                  aria-label="Default select example"
                                >
                                  <option selected value="">
                                    Select Target Account
                                  </option>
                                  {alltargetAccount.map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {item.title + " (" + item.acode + ")"}
                                    </option>
                                  ))}
                                </select> */}

                                <Select
                                  value={values.target_sub_account_id}
                                  name="target_sub_account_id"
                                  onChange={(e) => {
                                    console.log(e.value);
                                    setFieldValue("target_sub_account_id", e);
                                    onChangeHandler(
                                      e.value,
                                      values,
                                      "target_account"
                                    );

                                    isSubmittingBlock(
                                      e,
                                      setSubmitting,
                                      isSubmitting
                                    );
                                  }}
                                  className=""
                                  placeholder=""
                                  options={alltargetAccountForSelect}
                                />
                                {errors.target_sub_account_id &&
                                  touched.target_sub_account_id && (
                                    <div
                                      style={{ color: "#f87d8a" }}
                                      className="input-feedback"
                                    >
                                      {errors.target_sub_account_id}
                                    </div>
                                  )}
                              </div>
                              <div className="col-md-6">
                                <br />
                                <label>
                                  {" "}
                                  Account Code{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <Field
                                  name="account_code"
                                  component={Input}
                                  value={target_account_selected}
                                  placeholder="Account Code"
                                  disabled
                                  // maxLength={125}
                                />

                                {/* <input onChange={(e) => { console.log(e) }} placeholder="Account Code" className="form-control" type="text" /> */}
                              </div>

                              <div className="col-md-6">
                                <br />
                                <label>
                                  Campus <span style={{ color: "red" }}>*</span>
                                </label>

                                <select
                                  class="form-select form-control"
                                  name="campus_id"
                                  // disabled={editModal ? true : false}

                                  value={values.campus_id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={{ backgroundColor: "white" }}
                                  aria-label="Default select example"
                                >
                                  <option selected value="">
                                    Select Campus
                                  </option>
                                  {allcampuses.map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                                {errors.campus_id && touched.campus_id && (
                                  <div
                                    style={{ color: "#f87d8a" }}
                                    className="input-feedback"
                                  >
                                    {errors.campus_id}
                                  </div>
                                )}

                                {/* <CustomSelect

                                                            className='input'
                                                            onChange={evt => {
                                                                console.log(evt.value)

                                                            }}
                                                            placeholder="Campus"

                                                            options={vochers_campuses}
                                                        /> */}
                              </div>
                              <div className="col-md-6">
                                <br />
                                <label>
                                  {" "}
                                  cheque No{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                <Field
                                  name="cheque_no"
                                  component={Input}
                                  placeholder="Cheque No"
                                  disabled={
                                    values.voucher_type_id == "3" ||
                                    values.voucher_type_id == "4"
                                      ? false
                                      : true
                                  }
                                  maxLength={125}
                                />
                                {/* <input onChange={(e) => { console.log(e) }} placeholder="cheque No" className="form-control" type="text" /> */}
                              </div>

                              <div className="col-md-12">
                                <br />
                                <label>Description </label>
                                <Field
                                  name="target_remarks"
                                  component={TextArea}
                                  placeholder="Remarks"
                                  // maxLength={125}
                                />
                                {/* <input onChange={(e) => { console.log(e) }} placeholder="Description" className="form-control" type="text" /> */}
                              </div>
                              <div className="col-md-4">
                                <br />
                                <label>
                                  Debet <span style={{ color: "red" }}>*</span>
                                </label>
                                <Field
                                  name="target_debit"
                                  component={Input}
                                  placeholder="Debit"
                                  disabled={
                                    values.voucher_type_id == 2 ||
                                    values.voucher_type_id == 4 ||
                                    values.voucher_type_id == 5
                                      ? false
                                      : true
                                  }
                                  maxLength={9}
                                />
                              </div>
                              <div className="col-md-4">
                                <br />
                                <label>
                                  Credit <span style={{ color: "red" }}>*</span>
                                </label>
                                <Field
                                  name="target_credit"
                                  component={Input}
                                  placeholder="Credit"
                                  disabled={
                                    values.voucher_type_id == 1 ||
                                    values.voucher_type_id == 3 ||
                                    values.voucher_type_id == 5
                                      ? false
                                      : true
                                  }
                                  maxLength={9}
                                />
                              </div>
                            </>
                          ) : null}
                        </div>
                      </Form>
                      {isvouchertype ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              className="btn btn-primary"
                              onClick={getAllTargetAccounts}
                              type="button"
                            >
                              Reload Posting
                            </button>
                            <button
                              className="btn btn-success button_blue mx-2"
                              onClick={handleSubmit}
                              type="submit"
                              value="Submit"
                            >
                              {addloading ? (
                                <span className="spinner-border"></span>
                              ) : (
                                <>Add</>
                              )}
                            </button>

                            {/* <button onClick={() => setmodalvisible(false)} className="btn btn-danger btn_width ml-3" >Cancel</button> */}
                          </div>
                          <div className="card">
                            <div>
                              <Table>
                                <thead>
                                  <tr className="text-center">
                                    <th>Sr</th>
                                    <th>Acc Code</th>
                                    <th>Account Name</th>
                                    <th>Campus Name</th>

                                    <th>Debit</th>
                                    <th>Credit</th>
                                    {targetVochers[0]?.target_sub_account_id
                                      .length > 0 ? (
                                      <>
                                        <th>Edit</th>
                                        <th>Remove</th>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {isjvvoucher ? null : (
                                    <>
                                      {contraVoucher.map((vocher, index) => (
                                        <tr key={index} name={index}>
                                          <td>{index}</td>
                                          <td>{vocher.acode}</td>
                                          <td>
                                            {vocher.contra_sub_account_id}
                                          </td>
                                          <td>{vocher?.campus?.name}</td>
                                          {/* <td>{vocher.remarks}</td> */}
                                          <td>{vocher.contra_debit}</td>
                                          <td>{vocher.contra_credit}</td>
                                        </tr>
                                      ))}
                                    </>
                                  )}

                                  {targetVochers[0] == undefined ? null : (
                                    <>
                                      {targetVochers[0].target_sub_account_id.map(
                                        (vocher, index) => (
                                          <tr key={index} name={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                              {targetVochers[0].acode[index]}
                                            </td>
                                            <td>
                                              {
                                                targetVochers[0]
                                                  .target_sub_account_id[index]
                                                  .title
                                              }
                                            </td>
                                            <td>
                                              {
                                                targetVochers[0].target_campus[
                                                  index
                                                ].name
                                              }
                                            </td>
                                            {/* <td>{vocher.remarks}</td> */}
                                            <td>
                                              {
                                                targetVochers[0].target_debit[
                                                  index
                                                ]
                                              }
                                            </td>
                                            <td>
                                              {
                                                targetVochers[0].target_credit[
                                                  index
                                                ]
                                              }
                                            </td>
                                            <td
                                              style={{
                                                paddingLeft: "0px",
                                                textAlign: "center",
                                              }}
                                            >
                                              <a
                                                key={index}
                                                className="btn btn-icon btn-light btn-hover-primary btn-sm "
                                                onClick={(e) => {
                                                  openEditClick(index);
                                                }}
                                              >
                                                <span
                                                  key={index}
                                                  className="svg-icon svg-icon-md svg-icon-primary"
                                                >
                                                  <SVG
                                                    key={index}
                                                    src={toAbsoluteUrl(
                                                      "/media/svg/icons/Communication/Write.svg"
                                                    )}
                                                  />
                                                </span>
                                              </a>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              <span
                                                onClick={() => onDelete(index)}
                                                style={{ cursor: "pointer" }}
                                                className={
                                                  "svg-icon svg-icon-md svg-icon-danger"
                                                }
                                              >
                                                <SVG
                                                  src={toAbsoluteUrl(
                                                    "/media/svg/icons/General/Trash.svg"
                                                  )}
                                                />
                                              </span>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </>
                                  )}
                                  {/* <br />
                                                                            <br /> */}
                                  {/* <div style={{ width:"100%",display: "flex", justifyContent: "flex-end" }}>
                                                                                <span>Difference={totaldebit-totalcredit}</span>
                                                                                <span>Total Debit={totaldebit}</span>
                                                                                <span>Total Credit={totalcredit}</span>


                                                                            </div> */}
                                </tbody>
                              </Table>
                              <div className="row justify-content-center">
                                {loading && (
                                  <span className="spinner-border"></span>
                                )}
                              </div>
                            </div>
                          </div>
                          <br />
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div>
                              <label>Difference</label>
                              <div>{Math.abs(totaldebit - totalcredit)}</div>
                            </div>
                            <div>
                              <label>Total Debit</label>
                              <div>{totaldebit}</div>
                            </div>
                            <div>
                              <label>Total Credit</label>
                              <div>{totalcredit}</div>
                            </div>
                          </div>
                        </>
                      ) : null}

                      <br />
                      <br />

                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          onClick={() => {
                            SaveVoucher(values, resetForm);
                          }}
                          disabled={
                            Math.abs(totaldebit - totalcredit) == 0
                              ? false
                              : true
                          }
                          className="btn btn-success button_blue"
                        >
                          Save
                        </button>

                        <button
                          onClick={(e) => resetData(values, resetForm)}
                          className="btn btn-danger btn_width ml-3"
                        >
                          Reset
                        </button>

                        <button
                          onClick={(e) => cancelModal(e, values, resetForm)}
                          className="btn btn-danger btn_width ml-3"
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  );
                }}
              </Formik>
            </>
          )}
          {printvouchermodal && (
            <AccountVoucherPrint
              show={printvouchermodal}
              onHide={() => {
                setmodalvisible(false);
                setprintvouchermodal(false);
              }}
              selectedVoucher={selectedvoucher}
            />
          )}
        </Modal.Body>
        {/* <Modal.Footer>
                    <button className="btn btn-success button_blue" onClick={AddNewAccountVocher} type="submit" value="Submit">
                        {
                            addloading ? <span className="spinner-border"></span> : <>Add</>
                        }

                    </button>

                    <button onClick={() => setmodalvisible(false)} className="btn btn-danger btn_width ml-3" >Cancel</button>
                </Modal.Footer> */}
      </Modal>
    </Paper>
  );
}
