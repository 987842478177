import React, { useState, useEffect } from "react";
import { Table, Modal, Spinner, Alert } from "react-bootstrap";
import { useAlert } from "react-alert";

import { baseUrl } from "../../../services/config";
import { Field, useFormik, Form, Formik } from "formik";
import {
  Input,
  DatePickerField,
  Select,
} from "../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import * as Yup from "yup";
import Paper from "@material-ui/core/Paper";
import { useSelector, useDispatch } from "react-redux";
import { PremissionChecker } from "../../../services/PremissionChecker";
const DefineCampusFee = () => {
  const authtoken = localStorage.getItem("userToken");
  const alert = useAlert();
  // Array states
  const [allsessions, setallsessions] = useState([]);
  const [updateddata, setupdateddata] = useState([]);
  const [globalclasses, setglobalclasses] = useState([]);
  const [globalfeetypes, setglobalfeetypes] = useState([]);
  const [globalfeetypes1, setglobalfeetypes1] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campuses, setcampuses] = useState(user.campuses || []);
  const [campusesclasses, setcampusesclasses] = useState([]);
  const [courses, setcourses] = useState([]);
  const [feetypes, setfeetypes] = useState([]);
  const [fees, setfees] = useState([]);
  const [addmodal, setaddmodal] = useState(false);
  const [AllSessions, setAllSessions] = useState([]);
  const [currentCampus, setcurrentCampus] = useState("");
  const [addFeeloading, setaddFeeloading] = useState(false);
  //Input States
  const [currentsession, setcurrentsession] = useState("");
  const [campus_id, setcampus_id] = useState("");
  const [fee_type_id, setfee_type_id] = useState("");
  const [student_class_id, setstudent_class_id] = useState("");
  const [course_id, setcourse_id] = useState("");
  const [amount, setamount] = useState("");
  const [shift, setshift] = useState("");
  const [session, setsession] = useState("");
  const [isallfields, setisallfields] = useState(false);
  const [isformsubmitted, setisformsubmitted] = useState(false);
  const [errorcreate, seterrorcreate] = useState("Please Fill All Fields");
  const [iscampuschange, setiscampuschange] = useState(false);
  const [checkchangecampus, setcheckchangecampus] = useState("");
  const [editmodal, seteditmodal] = useState(false);
  const [currentobject, setcurrentobject] = useState({});
  const [updateamount, setupdateamount] = useState("");
  const [isupdatefields, setisupdatefields] = useState(false);
  const [isupdateloading, setisupdateloading] = useState(false);
  const [deletedstudentid, setdeletedstudentid] = useState("");
  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteloader, setdeleteloader] = useState(false);
  const [fetchingdata, setfetchingdata] = useState(false);
  const [updateloading, setupdateloading] = useState(false);

  let checking = 0;
  const dispatch = useDispatch();
  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );

  const [currentsessionid, setcurrentsessionid] = useState("");
  const [campusId_Add, setcampusId_Add] = useState("");
  const [feeTypeId, setFeeTypeId] = useState("");
  const [select_class, setSelect_class] = useState("-1");
  const [feeAmount, setFeeAmount] = useState(0);
  const [initialselectedsession, setinitialselectedsession] = useState("");
  //for rendering data on the base of classes and globas fee types

  useEffect(() => {
    // if (previousselectedcampus != "") {

    // }
    if (
      campusidget != "" &&
      initialselectedsession != "" &&
      globalfeetypes.length != 0 &&
      globalclasses.length != 0
    ) {
      console.log("campusidget==>", campusidget);
      console.log("initialselectedsession==>", initialselectedsession);
      fetchFunction(previousselectedcampus, initialselectedsession);
    }
    // console.log("currentsession==>", currentsession)

    // console.log("globalfeetypes==>", globalfeetypes)
    // console.log("globalclasses==>", globalclasses)
  }, [initialselectedsession, globalfeetypes, globalclasses]);

  // document.onkeyup = function (e) {
  //   if (e.which == 82) {

  //   }
  // }

  const onResetForm = (resetForm) => {
    resetForm();
  };

  useEffect(() => {
    if (campusidget != "") {
      setpreviousselectedcampus(campusidget);
      setmainchangecampus(campusidget);
    }

    getAllSessions();
    // getallcampus();
    getallcourses();
    getGlobalFeeses();
    getfeetypes();
    getGlobalClasses();
  }, []);

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setallsessions(data.payload);
        data.payload.map((item, index) => {
          if (item.active_academic_year == "1") {
            setcurrentsession(item.id);
            // console.log(currentsession)
            setinitialselectedsession(item.id);
            // console.log("previousselectedcampus=>", previousselectedcampus)
            // if (previousselectedcampus != "") {
            // }
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGlobalClasses = () => {
    fetch(`${baseUrl}/studentclass`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setglobalclasses([...data.payload]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGlobalFeeses = (camopus_id) => {
    fetch(`${baseUrl}/feestypes`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("response from server", data);

        // fetchFunction(previousselectedcampus, camopus_id, data.payload);

        setglobalfeetypes([...data.payload]);

        setglobalfeetypes1([...data.payload]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validChars = () => {
    document
      .querySelector(".number_input_class")
      .addEventListener("keypress", function(evt) {
        if (
          (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
          evt.which > 57
        ) {
          evt.preventDefault();
        }
      });
  };

  //main get function

  const fetchFunction = (campusid, sessionid) => {
    setfetchingdata(true);
    fetch(`${baseUrl}/campus/campus-fees/${campusid}/${sessionid}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setfees([...data.payload]);
        mergeData(data.payload, globalfeetypes);

        // if (globalfeetypes.length == 0) {

        //   mergeData(data.payload, feetypes);
        // } else {
        // }
      })
      .catch((err) => {
        setfetchingdata(false);
        console.log(err);
      });
  };
  const changesession = (id) => {
    setcurrentsession(id);

    if (currentCampus != "") {
      fetchFunction(currentCampus, id);
    }
  };
  const setmainchangecampus = (id) => {
    setpreviousselectedcampus(id);

    dispatch({
      type: "SELECTED_CAMPUS_ID",
      payload: {
        setselectedcampusid: id,
      },
    });

    console.log(id);
    setcurrentCampus(id);
    if (currentsession != "") {
      fetchFunction(id, currentsession);
    }
  };

  const mergeData = (deta, feetype) => {
    // console.log(globalfeetypes, "globalfeetypes");
    // console.log(feetype, "feetype");
    // console.log(deta);

    let newArray = [];
    let mergedarray = [];
    feetype.forEach((feeid, index) => {
      newArray = deta.filter(function(el) {
        return el.fee_type_id == feeid.id;
      });
      mergedarray[index] = { name: feeid.name, classes: newArray };
    });

    // console.log(mergedarray);

    globalclasses.forEach((classid, classindex) => {
      mergedarray.forEach((item, index) => {
        const isFound = item.classes.some((element) => {
          if (element.class_name === classid.name) {
            return true;
          } else {
            return false;
          }
        });
        if (!isFound) {
          mergedarray[index].classes.push({
            student_class_id: classid.id,
            class_name: classid.name,
            amount: 0,
          });
        }
      });
    });

    // console.log(mergedarray);

    mergedarray.forEach((item) => {
      item.classes.sort(function(a, b) {
        return parseInt(a.student_class_id) - parseInt(b.student_class_id);
      });
    });

    // console.log(mergedarray);

    setupdateddata([...mergedarray]);
    setfetchingdata(false);
  };

  //get functions

  const getallcampus = () => {
    fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.payload);
        setcampuses(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getAllSessions = () => {
  //     fetch(`${baseUrl}/session`, {
  //         method: 'GET',
  //         headers: {
  //             'Authorization': `Bearer ${authtoken}`
  //         }
  //     }).then((res) => res.json())
  //         .then((data) => {

  //             // console.log(data.payload);
  //             setAllSessions(data.payload)

  //         })
  //         .catch((err) => {
  //             console.log(err)
  //         })
  // }

  const onchangecampusclass = (id) => {
    setcampus_id(id);
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setcampusesclasses(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getfeetypes = () => {
    fetch(`${baseUrl}/feestypes`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("fee types ==>", data.payload);
        setfeetypes(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getallcourses = () => {
    fetch(`${baseUrl}/courses`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const arr = data.payload;

        //  console.log(typeof(arr))

        // console.log("course ==>", Object.values(arr));

        setcourses(Object.values(arr));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addFee = async (values) => {
    setaddFeeloading(true);
    const data = {
      campus_id: values.select_cmapus,
      fee_type_id: values.select_feetype,
      student_class_id: values.select_class,
      amount: values.amount,
      year_id: currentsessionid,
    };

    console.log(data);

    await fetch(`${baseUrl}/feestructure`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          // console.log(data.payload);
          // setaddmodal(false);
          if (data.metadata) {
            if (data.metadata.success) {
              alert.show("Fee successfully Defined");
              setFeeTypeId("");
              setFeeAmount(0);
              setaddFeeloading(false);

              setiscampuschange(false);
              setcheckchangecampus("");
              setmainchangecampus(values.select_cmapus);
            } else {
              alert.show(data.metadata.message);
              setaddmodal(false);
              setaddFeeloading(false);
              setiscampuschange(false);
              setcheckchangecampus("");
            }
          } else {
            alert.show("Something Went Wrong");
            setaddFeeloading(false);

            setiscampuschange(false);
            setcheckchangecampus("");
          }
        })
      )
      .catch((err) => {
        setaddmodal(false);
        setiscampuschange(false);
        setcheckchangecampus("");
        console.log(err);
        setaddFeeloading(false);
      });
  };

  //here work start

  const initialValues = {
    isformsubmittedcheck: "",
    select_cmapus: campusId_Add,
    select_class: select_class,
    select_feetype: feeTypeId,
    amount: feeAmount,
  };
  const CampusFeeSchema = Yup.object().shape({
    select_cmapus: Yup.string().required("Campus is required"),
    // select_class: Yup.string().required("Class is required"),
    select_feetype: Yup.string().required("Fee Type is required"),
    amount: Yup.string()
      .required("Please Enter your Amount")
      .matches(/^[0-9]*$/, "Only Numbers Are Allowed"),

    // amount: Yup.string()
    //     .required('Amount is required'),
  });

  const createEroors = (errors, touched, values, isSubmitting) => {
    setisallfields(false);
    if (errors.select_cmapus && touched.select_cmapus) {
      seterrorcreate(errors.select_cmapus);
      setisallfields(true);
      return;
    }
    if (!errors.select_cmapus && touched.select_cmapus) {
      if (
        (!iscampuschange && values.select_cmapus != "") ||
        checkchangecampus != values.select_cmapus
      ) {
        setiscampuschange(true);
        onchangecampusclass(values.select_cmapus);
      }
      if (checkchangecampus != values.select_cmapus) {
        setcheckchangecampus(values.select_cmapus);
      }
    }
    // if (errors.select_class && touched.select_class) {
    //   seterrorcreate(errors.select_class);
    //   setisallfields(true);
    //   return;
    // }
    if (errors.select_feetype && touched.select_feetype) {
      seterrorcreate(errors.select_feetype);
      setisallfields(true);
      return;
    }
    if (errors.amount && touched.amount) {
      seterrorcreate(errors.amount);
      setisallfields(true);
      return;
    }
    if (Object.keys(errors).length === 0) {
      if (values.interview_date == "") {
      } else {
        if (isSubmitting) {
          // if (isformsubmitted) {
          //     console.log(checking)
          //     checking = 0;
          //     console.log(checking)
          // }
          if (values.isformsubmittedcheck == "") {
            values.isformsubmittedcheck = "1";
            console.log(values.isformsubmittedcheck);
            addFee(values);
          }

          // if (!isformsubmitted) {
          //     console.log(checking)
          //     if (checking % 2 == 0) {
          //         console.log(checking)
          //         ++checking;
          //         console.log(checking)
          //         setisformsubmitted(true)
          //         addFee(values)
          //     }

          // }
        }
      }
      return;
    }
  };

  const deleteFee = (item) => {
    setdeletedstudentid(item.id);
    setdeleteModal(true);
  };

  const DeleteFee = () => {
    setdeleteloader(true);
    fetch(`${baseUrl}/feestructure/${deletedstudentid}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setdeleteloader(false);
          console.log(data);
          setdeleteModal(false);
          if (data.metadata) {
            if (data.metadata.success) {
              alert.success("Fee Deleted success");
              setmainchangecampus(currentCampus);
            }
            if (!data.metadata.success) {
              alert.show("Error Occured");
            }
          } else {
            alert.show("Error Occured");
          }
        });
      })
      .catch((err) => {
        console.log(err);
        alert.show("Error Occured");
        setdeleteloader(false);
        setdeleteModal(false);
      });
  };
  const openEditmodel = (cons) => {
    console.log(cons);

    setupdateamount(cons.amount);
    setcurrentobject(cons);
    seteditmodal(true);
  };

  const updateCampusFee = () => {
    if (updateamount == "") {
      setisupdatefields(true);
      return;
    }

    setisupdatefields(false);
    let data = {
      amount: parseInt(updateamount),
    };
    setisupdateloading(true);
    fetch(`${baseUrl}/feestructure/${currentobject.id}?_method=PUT`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          seteditmodal(false);
          setisupdateloading(false);
          console.log(data);
          if (data.metadata) {
            if (data.metadata.success) {
              setupdateamount("");
              setmainchangecampus(currentCampus);
              changesession(currentsession);
              alert.show("Fee Update successfully ");
            } else {
              alert.show("Something Went Wrong");
            }
          } else {
            alert.show("Error Occured");
          }
        })
      )
      .catch((err) => {
        seteditmodal(false);
        console.log(err);
        setisupdateloading(false);
      });
  };

  const changesessiononadd = (id) => {
    setcurrentsessionid(id);
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", padding: "15px", marginBottom: "10px" }}
      >
        <div>
          {PremissionChecker(user, "campus_fee.can_define") ? (
            <button
              onClick={() => setaddmodal(true)}
              className="btn btn-success button_blue button_blue"
            >
              Define Fee
            </button>
          ) : null}
          <div
            style={{
              margin: 10,
              marginLeft: "0px",
              padding: "0px",
            }}
            class="form-group col-sm-6 flex-column d-flex"
          >
            <select
              onChange={(e) => setmainchangecampus(e.target.value)}
              class="form-select"
              style={{ height: 40 }}
              value={campuses.length == 0 ? "" : previousselectedcampus}
              aria-label="Default select example"
            >
              <option selected value="">
                Select Campus
              </option>
              {campuses.map((campus) => (
                <option value={campus.id}>{campus.name}</option>
              ))}
            </select>
          </div>
          <div
            style={{
              margin: 10,
              marginLeft: "0px",
              padding: "0px",
            }}
            class="form-group col-sm-6 flex-column d-flex"
          >
            <select
              onChange={(e) => changesession(e.target.value)}
              class="form-select"
              style={{ height: 40 }}
              value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allsessions.map((session) => (
                <option
                  // selected={session.active_academic_year == "1" ? true : false}
                  value={session.id}
                >
                  {session.year}
                </option>
              ))}
            </select>
          </div>
          <div>
            <>
              {fetchingdata ? (
                <span className="spinner-border"></span>
              ) : (
                <>
                  {updateddata == [] ? null : PremissionChecker(
                      user,
                      "campus_fee.can_view_list"
                    ) ? (
                    <Table responsive>
                      <thead>
                        <tr>
                          <th></th>
                          {globalclasses.map((item, index) => {
                            return (
                              <th
                                // style={{color:'gray'}}
                                key={index}
                                value={item.id}
                              >
                                {item.name}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {updateddata.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <th>{item.name}</th>
                                {globalclasses.map((classobj, classindex) => {
                                  return PremissionChecker(
                                    user,
                                    "campus_fee.can_update_fee"
                                  ) ? (
                                    <td
                                      key={classindex}
                                      onClick={
                                        item.classes[classindex].id == undefined
                                          ? null
                                          : (e) => {
                                              openEditmodel(
                                                item.classes[classindex]
                                              );
                                            }
                                      }
                                      style={
                                        item.classes[classindex].id == undefined
                                          ? { textAlign: "start" }
                                          : {
                                              cursor: "pointer",
                                              textAlign: "start",
                                            }
                                      }
                                      value={
                                        item.classes[classindex].id == undefined
                                          ? ""
                                          : item.classes[classindex].id
                                      }
                                    >
                                      {item.classes[classindex].amount}
                                    </td>
                                  ) : null;
                                })}
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <>
                      <div className="bg-dark-o-15 font-size-h4 font-weight-bold mb-6 mt-6 text-center">
                        Permission denied for update salary allowances
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          </div>
        </div>

        <Modal onHide={() => setaddmodal(false)} show={addmodal} size="lg">
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Define Campus Fee
            </Modal.Title>
          </Modal.Header>

          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={CampusFeeSchema}
            onSubmit={(values) => {}}
          >
            {(props) => {
              const {
                handleSubmit,
                errors,
                touched,
                values,
                isSubmitting,
                isValid,
                setSubmitting,
                handleChange,
                handleBlur,
                resetForm,
                setValues,
              } = props;
              // setErrorsLis(errors)
              createEroors(errors, touched, values, isSubmitting);
              return (
                <>
                  {addFeeloading ? (
                    <b style={{ color: "green" }}>
                      Adding Fee Please Wait{" "}
                      <span className="spinner-border"></span>{" "}
                    </b>
                  ) : (
                    <>
                      <Form
                        onSubmit={() => handleSubmit()}
                        className="form form-label-right"
                      >
                        <Modal.Body>
                          <div class="row d-flex justify-content-center">
                            <div class="col-xl-10 col-lg-10 col-md-10 col-10">
                              {/* form start from here */}
                              {/* <div className="generate text-center">Define Campus Fee</div> */}

                              <div class="form-group">
                                <label>
                                  <b>
                                    Select Session{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <select
                                  onChange={(e) =>
                                    changesessiononadd(e.target.value)
                                  }
                                  class="form-select"
                                  style={{ height: 40 }}
                                  value={currentsessionid}
                                  aria-label="Default select example"
                                >
                                  <option selected value="">
                                    Select Session
                                  </option>
                                  {allsessions.map((session) => (
                                    <option value={session.id}>
                                      {session.year}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {currentsessionid != "" ? (
                                <form class="form-card">
                                  <div class="row justify-content-between text-left">
                                    <div class="form-group col-sm-6 flex-column d-flex">
                                      <label>
                                        <b>
                                          Select Campus{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </b>
                                      </label>
                                      <select
                                        //  onChange={(e) => onchangecampusclass(e)}
                                        name="select_cmapus"
                                        value={values.select_cmapus}
                                        // setcampusId_Add
                                        onChange={(e) => {
                                          handleChange(e);
                                          setcampusId_Add(e.target.value);
                                        }}
                                        onBlur={handleBlur}
                                        class="form-select form-control"
                                        aria-label="Default select example"
                                      >
                                        <option selected value="">
                                          Select Campus
                                        </option>
                                        {campuses.map((campus) => (
                                          <>
                                            <option value={campus.id}>
                                              {campus.name}
                                            </option>
                                          </>
                                        ))}
                                        {/* {campuses.map((campus) =>
                                          user.head_office.id ==
                                          campus.head_office_id ? (
                                            <>
                                              <option value={campus.id}>
                                                {campus.name}
                                              </option>
                                            </>
                                          ) : null
                                        )} */}
                                      </select>
                                      {errors.select_cmapus &&
                                        touched.select_cmapus && (
                                          <div
                                            style={{ color: "#f87d8a" }}
                                            className="input-feedback"
                                          >
                                            {errors.select_cmapus}
                                          </div>
                                        )}
                                    </div>
                                    {/* getfeetypes(campus_id, student_class_id, currentsessionid); */}

                                    <div class="form-group col-sm-6 flex-column d-flex">
                                      <label class="form-control-label">
                                        <b>
                                          Select Fee type{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </b>
                                      </label>
                                      <select
                                        //   onChange={(e) => setfee_type_id(e.target.value)}
                                        name="select_feetype"
                                        value={values.select_feetype}
                                        onChange={(e) => {
                                          handleChange(e);
                                          setFeeTypeId(e.target.value);
                                        }}
                                        onBlur={handleBlur}
                                        class="form-select form-control"
                                        aria-label="Default select example"
                                      >
                                        <option selected value="">
                                          Select Fee Type
                                        </option>

                                        {feetypes.map((fee) => (
                                          <option value={fee.id}>
                                            {fee.name}
                                          </option>
                                        ))}
                                      </select>
                                      {errors.select_feetype &&
                                        touched.select_feetype && (
                                          <div
                                            style={{ color: "#f87d8a" }}
                                            className="input-feedback"
                                          >
                                            {errors.select_feetype}
                                          </div>
                                        )}
                                    </div>

                                    <div class="form-group col-sm-6 flex-column d-flex pt-3">
                                      <label>
                                        <b>
                                          Select Class{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>{" "}
                                        </b>
                                      </label>
                                      <select
                                        //  onChange={(e) => onchangecampusclass(e)}
                                        name="select_class"
                                        value={values.select_class}
                                        onChange={(e) => {
                                          handleChange(e);
                                          setSelect_class(e.target.value);
                                          // getfeetypes(campusId_Add, e.target.value, currentsessionid)
                                        }}
                                        onBlur={handleBlur}
                                        class="form-select form-control"
                                        aria-label="Default select example"
                                      >
                                        {/* <option selected value="">
                                          Select Class
                                        </option> */}
                                        <option value="-1">All Classes</option>
                                        {campusesclasses.map((campus) => (
                                          // user.head_office.id == campus.head_office_id ? <>
                                          <option value={campus.id}>
                                            {campus.name}
                                          </option>
                                          // </> : null
                                        ))}
                                      </select>

                                      {/* {errors.select_class &&
                                        touched.select_class && (
                                          <div
                                            style={{ color: "#f87d8a" }}
                                            className="input-feedback"
                                          >
                                            {errors.select_class}
                                          </div>
                                        )} */}
                                    </div>

                                    <div class="form-group col-sm-6 flex-column d-flex pt-3">
                                      <label class="form-control-label">
                                        <b>
                                          Amount{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </b>
                                      </label>
                                      <Field
                                        name="amount"
                                        type="text"
                                        component={Input}
                                        placeholder="Enter Your Address"
                                        maxLength={6}
                                        onChange={(e) => {
                                          handleChange(e);
                                          setFeeAmount(e.target.value);
                                        }}
                                        value={feeAmount}
                                      />
                                    </div>
                                  </div>

                                  {/* <div className="text-center">
                                    <h5>Fee Detail</h5>
                                  </div>
                                  <div class="row justify-content-between text-left">
                                    <div class="form-group col-sm-6 flex-column d-flex">
                                      <label class="form-control-label">
                                        <b>
                                          Amount{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </b>
                                      </label>
                                      <Field
                                        name="amount"
                                        type="text"
                                        component={Input}
                                        placeholder="Enter Your Address"
                                        maxLength={6}
                                        onChange={(e) => {
                                          handleChange(e);
                                          setFeeAmount(e.target.value);
                                        }}
                                        value={feeAmount}
                                      />
                                    </div>
                                  </div> */}
                                </form>
                              ) : null}
                            </div>
                          </div>
                        </Modal.Body>
                      </Form>
                      {isallfields ? (
                        <>
                          <Alert
                            variant="danger"
                            onClose={() => setisallfields(false)}
                            dismissible
                          >
                            <Alert.Heading style={{ textAlign: "start" }}>
                              Oh snap! You got an error!
                            </Alert.Heading>
                            <p style={{ textAlign: "start" }}>{errorcreate}</p>
                          </Alert>
                        </>
                      ) : null}
                      <Modal.Footer>
                        <>
                          <button
                            // onClick={addFee}
                            onClick={() =>
                              handleSubmit(setSubmitting, resetForm, setValues)
                            }
                            type="submit"
                            value="Submit"
                            className="btn btn-success button_blue button_blue"
                          >
                            Add
                          </button>
                          {/* <button
                            // onClick={addFee}
                            onClick={() => resetForm()}
                            type="reset"
                            value="Submit"
                            className="btn btn-success button_blue button_blue"
                          >
                            Reset
                          </button> */}
                        </>

                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            // checking = 0
                            setisallfields(false);
                            //   setisformsubmitted(false)
                            setaddFeeloading(false);
                            setcampusesclasses([]);
                            setiscampuschange(false);
                            setcheckchangecampus("");
                            setaddmodal(false);
                          }}
                          className="btn btn-danger btn_width"
                        >
                          Cancel
                        </button>
                      </Modal.Footer>
                    </>
                  )}
                </>
              );
            }}
          </Formik>
        </Modal>
        <Modal show={editmodal} onHide={() => seteditmodal(false)}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title className="Modal_Heading_font">Edit Fee</Modal.Title>
          </Modal.Header>
          {isupdateloading ? (
            <div style={{ padding: "15px" }}>
              <b style={{ color: "green" }}>
                Updating Fee Please Wait{" "}
                <span className="spinner-border"></span>{" "}
              </b>
            </div>
          ) : (
            <Modal.Body>
              <div>
                <div>
                  <label>
                    Amount <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    onClick={() => {
                      validChars();
                    }}
                    onChange={(e) => {
                      console.log(e.target.value);
                      if (e.target.value == "") {
                        setisupdatefields(true);
                      } else if (e.target.value == "e") {
                        setisupdatefields(true);
                      } else {
                        setisupdatefields(false);
                      }
                      setupdateamount(e.target.value);
                    }}
                    type="text"
                    maxLength="6"
                    pattern={/^[0-9]*$/}
                    required
                    defaultValue={updateamount}
                    value={updateamount}
                    placeholder="Amount"
                    className="form-control mb-2 number_input_class"
                  />
                </div>

                {isupdatefields ? (
                  <>
                    <Alert
                      variant="danger"
                      onClose={() => setisupdatefields(false)}
                      dismissible
                    >
                      <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                      <p>Please Add valid Amount</p>
                    </Alert>
                  </>
                ) : null}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="btn btn-success button_blue ml-5"
                    onClick={updateCampusFee}
                  >
                    Update
                  </button>
                  <button
                    style={{ marginLeft: "10px" }}
                    className="btn btn-danger btn_width "
                    onClick={() => {
                      setupdateamount("");
                      seteditmodal(false);
                      setisupdatefields(false);
                      setisupdateloading(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Modal.Body>
          )}
        </Modal>

        <Modal show={deleteModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to delete Fee
            </Modal.Title>
          </Modal.Header>
          {deleteloader ? (
            <div style={{ padding: "15px" }}>
              <b style={{ color: "green" }}>
                Deleting Fee Please Wait{" "}
                <span className="spinner-border"></span>{" "}
              </b>
            </div>
          ) : (
            <Modal.Footer>
              <div style={{ display: "flex", justifyContent: "center" }}></div>
              <button onClick={DeleteFee} className="btn button_blue">
                Delete
              </button>
              <button
                onClick={() => setdeleteModal(false)}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </Modal.Footer>
          )}
        </Modal>
      </Paper>
    </div>
  );
};

export default DefineCampusFee;
