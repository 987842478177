import { useEffect } from "react";
// import { generateTokken, messaging } from "../../utils/firebaseUtils";
// import { onMessage } from "firebase/messaging";
import { baseUrl } from "../../services/config";
import { useAlert } from "react-alert";

const authtoken = localStorage.getItem("userToken");

export const SendNotifaction = async (
  title,
  des,
  std_notification_id,
  notification_name,
  notification_id
) => {
  let data = {
    notification_name: notification_name,
    notification_id: notification_id,
  };
  let notifactionData = {
    body: des,
    title: title,
    std_notification_id: std_notification_id,
    data: [data],
  };

  const sendData = {
    notifications: [notifactionData],
  };

  fetch(`${baseUrl}/send-notification`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authtoken}`,
    },
    body: JSON.stringify(sendData),
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const sendTestResultNotifications = async (arrayofExam) => {
  // Define the batch size (e.g., 10 IDs per batch)
  const batchSize = 10;

  for (let i = 0; i < arrayofExam.length; i += batchSize) {
    const batchIds = arrayofExam.slice(i, i + batchSize);
    const batchPromises = batchIds
      .filter((item) => item.id != null)
      .map((item) =>
        SendNotifaction(
          item.title,
          item.description,
          item.id,
          "Daily Test",
          "3"
        )
      );
    await Promise.all(batchPromises);
  }
};

export const sendExamResultNotifications = async (arrayofExam) => {
  // Define the batch size (e.g., 10 IDs per batch)
  const batchSize = 10;

  for (let i = 0; i < arrayofExam.length; i += batchSize) {
    const batchIds = arrayofExam.slice(i, i + batchSize);
    const batchPromises = batchIds
      .filter((item) => item.id != null)
      .map((item) =>
        SendNotifaction(item.title, item.description, item.id, "Result", "10")
      );
    await Promise.all(batchPromises);
  }
};

export const sendExamNotifications = async (arrayofExam) => {
  // Define the batch size (e.g., 10 IDs per batch)
  const batchSize = 10;

  for (let i = 0; i < arrayofExam.length; i += batchSize) {
    const batchIds = arrayofExam.slice(i, i + batchSize);
    const batchPromises = batchIds
      .filter((item) => item.id != null)
      .map((item) =>
        SendNotifaction(item.title, item.description, item.id, "Exam", "5")
      );
    await Promise.all(batchPromises);
  }
};

export const sendDateSheetNotifications = async (arrayofExam) => {
  // Define the batch size (e.g., 10 IDs per batch)
  const batchSize = 10;

  for (let i = 0; i < arrayofExam.length; i += batchSize) {
    const batchIds = arrayofExam.slice(i, i + batchSize);
    const batchPromises = batchIds
      .filter((item) => item.id != null)
      .map((item) =>
        SendNotifaction(
          item.title,
          item.description,
          item.id,
          "Date Sheet",
          "4"
        )
      );
    await Promise.all(batchPromises);
  }
};

export const sendHomeWorkNotifications = async (arrayofExam) => {
  // Define the batch size (e.g., 10 IDs per batch)
  const batchSize = 10;

  for (let i = 0; i < arrayofExam.length; i += batchSize) {
    const batchIds = arrayofExam.slice(i, i + batchSize);
    const batchPromises = batchIds
      .filter((item) => item.id != null)
      .map((item) =>
        SendNotifaction(item.title, item.description, item.id, "Home Work", "6")
      );
    await Promise.all(batchPromises);
  }
};

export const sendStudentResourceNotifications = async (arrayofExam) => {
  // Define the batch size (e.g., 10 IDs per batch)
  const batchSize = 10;

  for (let i = 0; i < arrayofExam.length; i += batchSize) {
    const batchIds = arrayofExam.slice(i, i + batchSize);
    const batchPromises = batchIds
      .filter((item) => item.id != null)
      .map((item) =>
        SendNotifaction(
          item.title,
          item.description,
          item.id,
          "Resource Work",
          "9"
        )
      );
    await Promise.all(batchPromises);
  }
};

export const sendAddNotifications = async (
  title,
  des,
  ids,
  notifaction_name,
  notifaction_id
) => {
  // Define the batch size (e.g., 10 IDs per batch)
  const batchSize = 10;

  for (let i = 0; i < ids.length; i += batchSize) {
    const batchIds = ids.slice(i, i + batchSize);
    const batchPromises = batchIds
      .filter((id) => id != null)
      .map((id) =>
        SendNotifaction(title, des, id, notifaction_name, notifaction_id)
      );
    await Promise.all(batchPromises);
  }
};

export const sendDueFeeNotifications = async (arrayofExam) => {
  // Define the batch size (e.g., 10 IDs per batch)
  const batchSize = 10;

  for (let i = 0; i < arrayofExam.length; i += batchSize) {
    const batchIds = arrayofExam.slice(i, i + batchSize);
    const batchPromises = batchIds
      .filter((item) => item.id != null)
      .map((item) =>
        SendNotifaction(
          item.title,
          item.description,
          item.id,
          "Unpaid Fees",
          "11"
        )
      );
    await Promise.all(batchPromises);
  }
};

export const sendAttendMsgNotifications = async (
  title,
  des,
  ids,
  notifaction_name,
  notifaction_id
) => {
  // Define the batch size (e.g., 10 IDs per batch)
  const batchSize = 10;

  for (let i = 0; i < ids.length; i += batchSize) {
    const batchIds = ids.slice(i, i + batchSize);
    const batchPromises = batchIds
      .filter((id) => id != null)
      .map((id) =>
        SendNotifaction(title, des, id, notifaction_name, notifaction_id)
      );
    await Promise.all(batchPromises);
  }
};

export const sendAttendanceNotifications = async (
  selectAttendace,
  arrayofstudents
) => {
  // Define the batch size (e.g., 10 IDs per batch)
  console.log("ya chala?");
  const batchSize = 10;

  for (let i = 0; i < arrayofstudents.length; i += batchSize) {
    const batchIds = arrayofstudents.slice(i, i + batchSize);
    const batchPromises = batchIds
      .filter((item) => item.id != null)
      .map((item) => {
        switch (selectAttendace) {
          case "1":
            SendNotifaction(
              "Attendance/Discipline Alert",
              `Dear Parent, your child ${item.name} is absent from school today without any information. Please contact at the earliest`,
              item.id,
              "Attendance",
              "1"
            );
            break;

          case "2":
            SendNotifaction(
              "Attendance/Discipline Alert",
              `Dear Parent, application for your child ${item.name} sick leave has been received, may he/she get well soon and attend the school at earliest`,
              item.id,
              "Attendance",
              "1"
            );
            break;
          case "3":
            SendNotifaction(
              "Attendance/Discipline Alert",
              `Dear Parent, Leave Application of your child ${item.name} has been received in the school office and approved.`,
              item.id,
              "Attendance",
              "1"
            );
            break;
          case "4":
            SendNotifaction(
              "Attendance/Discipline Alert",
              `Dear Parent, your child ${item.name} has been late from school today. Kindly pay attention on his/her discipline`,
              item.id,
              "Attendance",
              "1"
            );
            break;

          case "5":
            SendNotifaction(
              "Attendance/Discipline Alert",
              `Dear Parent, your child ${item.name} home work was not complete today, Kindly pay attention on his/her studies`,
              item.id,
              "Attendance",
              "1"
            );
            break;

          case "6":
            SendNotifaction(
              "Attendance/Discipline Alert",
              `Dear Parent, your child ${item.name} was not in proper uniform today. Please send your child in proper uniform.`,
              item.id,
              "Attendance",
              "1"
            );
            break;

          case "7":
            SendNotifaction(
              "Attendance Alert",
              `Dear Parent, your child ${item.name} test was not prepared. Please make sure he/she must focus on his/her studies.`,
              item.id,
              "Attendance",
              "1"
            );
            break;
        }
      });
    await Promise.all(batchPromises);
  }
};
