// export const domainStudent = '@wijh.com'

// export const baseUrl = process.env.NODE_ENV === "production" ? "https://portal.darearqamsgd.edu.pk/api" : "https://portal.darearqamsgd.edu.pk/api"

// let baseUrl =
//   process.env.NODE_ENV === "production"
//     ? "http://demo-api.dassmis.net/api"
//     : "http://demo-api.dassmis.net/api";

let baseUrl =
  process.env.NODE_ENV === "production"
    ? "http://127.0.0.1:8000/api"
    : "http://127.0.0.1:8000/api";

let domainStudent = "@demo.com";

let appStatus = "1";

let appVersion = "1.0.0";

export const setConfigDataFromFile = async () => {
  try {
    const response = await fetch("/ConfigData.txt");
    const fileContent = await response.text();

    // Parse the file content as JSON if it contains valid JSON data
    const configData = JSON.parse(fileContent);

    // Update values if they exist in the file
    if (configData.baseUrl) {
      baseUrl = configData.baseUrl;
    }

    if (configData.appStatus) {
      appStatus = configData.appStatus;
    }

    if (configData.domainStudent) {
      domainStudent = configData.domainStudent;
    }

    appVersion = "1.1.10";
    console.log("Config Data:", {
      baseUrl,
      domainStudent,
      appVersion,
      appStatus,
    });
  } catch (error) {
    console.error("Error fetching ConfigData.txt:", error);
    // Handle the error if necessary
  }
};

export { baseUrl, domainStudent, appVersion, appStatus };
