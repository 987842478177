import { Paper } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { Modal, Spinner, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";
import { DownloadTableExcel } from "react-export-table-to-excel";

const Result = () => {
  const pageStyle = `
    @page {
      size: a4 !important;
    }
    `;

  const componentRef = useRef(null);
  const tableRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const alert = useAlert();

  const authtoken = localStorage.getItem("userToken");
  const [allsessions, setallsessions] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [selectedcampus, setselectedcampus] = useState("");
  const [currentsession, setcurrentsession] = useState("");
  const [selectedsection, setselectedsection] = useState("");
  const [allsubjects, setallsubjects] = useState([]);
  const [selectedsubject, setselectedsubject] = useState("");
  const [educationType, setEducationType] = useState(1);

  const [exampsTypeList, setexampsTypeList] = useState([]);
  const [selectedExamType, setselectedExamType] = useState("");
  const [printmodal, setprintmodal] = useState(false);
  const [allexamslist, setallexamslist] = useState([]);

  const [stu_classesList, setstu_classesList] = useState([]);

  const [selectedclass, setselectedclass] = useState("");

  const [allclasses, setallclasses] = useState([]);
  const [allCampusSections, setallCampusSections] = useState([]);

  const [updateloading, setupdateloading] = useState(false);
  const [allResultList, setallResultList] = useState([]);

  const [loading, setloading] = useState(false);
  const [isPracticalMode, setisPracticalMode] = useState(false);

  const [examDetails, setexamDetails] = useState({});
  const [classDetails, setclassDetails] = useState({});
  const [sectionDetails, setsectionDetails] = useState({});
  const [subjectDetails, setsubjectDetails] = useState({});
  const [printEmpty, setPrintEmpty] = useState(true);

  //printing states

  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    // getAllCampus();
    getAllSubjects();
    getAllExampTypes();
    getAllSessions();
    getSettingData();
    // getAllClasses()
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions([...data.payload]);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setcurrentsession(session.id);
            // getAllExamsList(session.id)
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllExamsList = (id) => {
    const data = {
      year_id: currentsession,
      campus_id: id,
    };

    fetch(`${baseUrl}/exam_for_session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("get exam", data);

        setallexamslist([...data.payload]);

        // setallclasses(data.payload[0]?.student_classes)

        // getassignedExamsList(data.payload)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllExampTypes = async () => {
    await fetch(`${baseUrl}/exam_type`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setexampsTypeList(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getAllSubjects = async () => {
    await fetch(`${baseUrl}/subject`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallsubjects(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getAllCampus = async () => {
    console.log("campus geeting");
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const onCampusChange = async (id) => {
    setselectedcampus(id);
    getAllExamsList(id);
  };

  const onChangeSession = (id) => {
    setcurrentsession(id);
  };

  const getAllClasses = () => {
    fetch(`${baseUrl}/studentclass`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallclasses([...data.payload]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClassChange = (id) => {
    setselectedclass(id);

    fetch(`${baseUrl}/class_section/${selectedcampus}/${educationType}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallCampusSections([...data.payload]);
      })
      .catch((err) => {
        console.log("section error", err);
      });
  };

  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${selectedcampus}/${id}${
        selectedclass ? "/" + selectedclass : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          console.log(data, "section data");
          setallCampusSections(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStudentResult = () => {
    // console.log(selectedExamType + " " + selectedcampus + " " + selectedclass);
    if (
      selectedExamType === "" ||
      selectedcampus === "" ||
      selectedsubject === ""
    ) {
      return alert.error("Please select all fileds");
    }

    if (selectedsection === "" && selectedclass === "") {
      return alert.error("Please select class/section");
    }
    if (educationType !== "2" && selectedclass === "") {
      return alert.error("Please select class");
    }

    setloading(true);
    let data = {
      year_id: currentsession,
      exam_id: selectedExamType,
      campus_id: selectedcampus,
      education_type: educationType,
      student_class_id: selectedclass,
      global_section_id: selectedsection,
      subject_id: selectedsubject,
    };
    fetch(`${baseUrl}/result-get`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata.success) {
          console.log(data.payload.results);

          const res = data.payload.results.sort(
            (a, b) => a.student.admission_id - b.student.admission_id
          );
          setallResultList(res);
          setexamDetails(data.payload.exam);
          setclassDetails(data.payload.class);
          setsectionDetails(data.payload.section);
          setsubjectDetails(data.payload.subject);
          setloading(false);
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            toast.error(msg);
            setloading(false);
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  const OnchangefullMark = (e, index) => {
    let arr = [...allResultList];
    // arr[index].full_marks = e.target.value;
    arr.map((item) => {
      item.full_marks = e.target.value;
    });
    setallResultList(arr);
  };
  const onChangeGainMarks = (e, index) => {
    let arr = [...allResultList];
    let newVal = e.target.value;

    // console.log("event value==>",Number(newVal) ? newVal : 'NaN')

    if (newVal <= parseFloat(arr[index].full_marks)) {
      arr[index].gain_marks = newVal;

      let obtainMarks = newVal / arr[index].full_marks;
      let percentage = obtainMarks * 100;
      arr[index].percentage = percentage.toFixed(2);

      setallResultList(arr);

      if (percentage <= 100 && percentage >= 90) {
        arr[index].grade = "A+";
        setallResultList(arr);
      } else if (percentage < 90 && percentage >= 80) {
        arr[index].grade = "A";
        setallResultList(arr);
      } else if (percentage < 80 && percentage >= 70) {
        arr[index].grade = "B";
        setallResultList(arr);
      } else if (percentage < 70 && percentage >= 60) {
        arr[index].grade = "C";
        setallResultList(arr);
      } else if (percentage < 60 && percentage >= 50) {
        arr[index].grade = "D";
        setallResultList(arr);
      } else if (percentage < 50 && percentage >= 40) {
        arr[index].grade = "E";
        setallResultList(arr);
      } else if (percentage < 40 && percentage >= 0) {
        arr[index].grade = "F";
        setallResultList(arr);
      }

      // calculateGrade(percentage.toFixed(2), index)
    }
  };

  const onChangePracticalMarks = (e, index, gain, full) => {
    if (Number(e.target.value) + Number(gain) > Number(full)) {
      return alert.error(
        "Gain marks + presentation marks cannot be greater then total marks"
      );
    }
    let arr = [...allResultList];
    let newVal = e.target.value;

    arr[index].practical_marks = newVal;

    setallResultList(arr);

    let addtion = parseFloat(newVal) + parseFloat(arr[index].gain_marks);

    let obtainMarks = addtion / arr[index].full_marks;
    let percentage = obtainMarks * 100;
    arr[index].percentage = percentage.toFixed(2);

    setallResultList(arr);

    if (percentage <= 100 && percentage >= 90) {
      arr[index].grade = "A+";
      setallResultList(arr);
    } else if (percentage < 90 && percentage >= 80) {
      arr[index].grade = "A";
      setallResultList(arr);
    } else if (percentage < 80 && percentage >= 70) {
      arr[index].grade = "B";
      setallResultList(arr);
    } else if (percentage < 70 && percentage >= 60) {
      arr[index].grade = "C";
      setallResultList(arr);
    } else if (percentage < 60 && percentage >= 50) {
      arr[index].grade = "D";
      setallResultList(arr);
    } else if (percentage < 50 && percentage >= 40) {
      arr[index].grade = "E";
      setallResultList(arr);
    } else if (percentage < 40 && percentage >= 0) {
      arr[index].grade = "F";
      setallResultList(arr);
    }

    // calculateGrade(percentage.toFixed(2), index)
  };

  const updateResult = () => {
    setupdateloading(true);

    let result_id = [];
    let full_marks = [];
    let gain_marks = [];
    let percentage = [];
    let grade = [];
    let practical_marks = [];
    let status = [];

    allResultList.forEach((ele) => {
      result_id.push(ele.id);
      full_marks.push(ele.full_marks);
      gain_marks.push(ele.gain_marks ? ele.gain_marks : 0);
      percentage.push(ele.percentage);
      grade.push(ele.grade);
      practical_marks.push(ele.practical_marks);
      status.push(ele.status);
    });

    console.log(status);

    let formdata = new FormData();

    // formdata.append('result_id[]', result_id)
    // formdata.append('full_marks[]', full_marks)
    // formdata.append('gain_marks[]', gain_marks)
    // formdata.append('percentage[]', percentage)
    // formdata.append('grade[]', grade)
    // formdata.append('practical_marks[]', practical_marks)

    for (let i = 0; i < result_id.length; i++) {
      formdata.append(`result_id[${i}]`, result_id[i]);
    }
    for (let i = 0; i < full_marks.length; i++) {
      formdata.append(`full_marks[${i}]`, full_marks[i]);
    }
    for (let i = 0; i < gain_marks.length; i++) {
      formdata.append(`gain_marks[${i}]`, gain_marks[i]);
    }
    for (let i = 0; i < percentage.length; i++) {
      formdata.append(`percentage[${i}]`, percentage[i]);
    }
    for (let i = 0; i < grade.length; i++) {
      formdata.append(`grade[${i}]`, grade[i]);
    }
    for (let i = 0; i < practical_marks.length; i++) {
      formdata.append(`practical_marks[${i}]`, practical_marks[i]);
    }
    for (let i = 0; i < status.length; i++) {
      formdata.append(`status[${i}]`, status[i]);
    }
    formdata.append("exam_id", selectedExamType);

    for (var pair of formdata.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    fetch(`${baseUrl}/result-update`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
      body: formdata,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          alert.success("Result update successfully");
          setupdateloading(false);
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            alert.error(msg);
            setupdateloading(false);
          });
        }

        console.log(data);
      })
      .catch((err) => {
        setupdateloading(false);

        console.log(err);
      });
  };

  //printing function

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const onStatusChange = (e, index) => {
    let arr = [...allResultList];
    let newVal = e.target.value;

    arr[index].status = newVal;
    if (newVal != "Present") {
      arr[index].gain_marks = 0;
      arr[index].percentage = 0;
      arr[index].practical_marks = 0;
      arr[index].grade = "F";
    }
    setallResultList(arr);
  };

  const printdoc = () => {
    function printElement(elem) {
      var $printSection1 = document.getElementById("printSection");
      if (!$printSection1) {
        var $printSection1 = document.createElement("div");
        $printSection1.id = "printSection";
        document.body.appendChild($printSection1);
      }

      $printSection1.innerHTML = elem;
      // $printSection1.appendChild(domClone);
      window.print();
    }

    printElement(document.getElementById("result-p").innerHTML);
  };

  const inputRefs = useRef([]);
  const inputRefs1 = useRef([]);

  const handleKeyDown = (e, index) => {
    if (e.key === "Tab" && !e.shiftKey) {
      e.preventDefault();

      const nextIndex = (index + 1) % inputRefs.current.length;
      const nextInput = inputRefs.current[nextIndex];

      if (nextInput) nextInput.focus();
      nextInput.select();
    }
  };

  const handleKeyDown1 = (e, index) => {
    if (e.key === "Tab" && !e.shiftKey) {
      e.preventDefault();

      const nextIndex1 = (index + 1) % inputRefs1.current.length;
      const nextInput1 = inputRefs1.current[nextIndex1];

      if (nextInput1) {
        nextInput1.focus();
        nextInput1.select();
      }
    }
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "10px" }}
      >
        <div className="row">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <select
              onChange={(e) => onChangeSession(e.target.value)}
              class="form-select"
              style={{ height: 40 }}
              value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allsessions.map((session) => (
                <option key={session.id} value={session.id}>
                  {session.year}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              value={selectedcampus}
              onChange={(e) => onCampusChange(e.target.value)}
              aria-label="Default select example"
            >
              <option value=""> Select Campus</option>
              {campuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Exam</b>
            </label>
            <select
              onChange={(e) => {
                setselectedExamType(e.target.value);
                let finded = allexamslist.find(
                  (item) => item.id == e.target.value
                );
                setallclasses(finded.student_classes);
              }}
              class="form-select"
              style={{ height: 40 }}
              // value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Exam</option>
              {allexamslist.map((exam) => (
                <option key={exam.id} value={exam.id}>
                  {exam?.exam_name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Class</b>
            </label>

            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              onChange={(e) => onClassChange(e.target.value)}
              aria-label="Default select example"
            >
              <option value=""> Select Class</option>
              {allclasses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-3">
            <label>
              <b>Select Education Type</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
                getSectionsListOnTypeChange(e.target.value);
              }}
            >
              <option value={1}>Education</option>
              <option value={2}>Hifz</option>
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Section</b>
            </label>

            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              // value={selectedSection}
              onChange={(e) => setselectedsection(e.target.value)}
              aria-label="Default select example"
            >
              <option value=""> Select Section</option>
              {allCampusSections.map((sec, index) => (
                <option key={index} value={sec.id}>
                  {sec.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Subject</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              // value={selectedSection}
              // onChange={(e) => getStudentResult(e.target.value)}
              onChange={(e) => setselectedsubject(e.target.value)}
              aria-label="Default select example"
            >
              <option value=""> Select Subject</option>
              {allsubjects.map((sec, index) => (
                <option key={index} value={sec.id}>
                  {sec.name}
                </option>
              ))}
            </select>
          </div>
          <div style={{ height: 40 }} className="col-md-3 mt-12">
            {/* <label>
              <input
                type="checkbox"
                onChange={(e) => setisPracticalMode(e.target.checked)}
              />
              <span>&nbsp;&nbsp;&nbsp; </span>Include Presentation
            </label>
            */}
            <input type="checkbox" style={{ display: "none" }} />
            <label className="checkbox checkbox-single">
              <input
                type="checkbox"
                onChange={(e) => setisPracticalMode(e.target.checked)}
              />
              <span />
              &nbsp;&nbsp;&nbsp;Include Presentation
            </label>
          </div>
          <div className="col-md-12 mt-5">
            {/* <label>
              <input
              checked={printEmpty}
                type="checkbox"
                onChange={(e) => setPrintEmpty(e.target.checked)}
              />
              <span>&nbsp;&nbsp;&nbsp; </span>Print Empty
            </label> */}
            <input type="checkbox" style={{ display: "none" }} />
            <label className="checkbox checkbox-single">
              <input
                checked={printEmpty}
                type="checkbox"
                onChange={(e) => setPrintEmpty(e.target.checked)}
              />
              <span />
              &nbsp;&nbsp;&nbsp;Print Empty
            </label>
          </div>

          <div
            className="col d-flex justify-content-between"
            style={{ marginTop: 20, height: 40 }}
          >
            <div>
              <button
                disabled={loading ? true : false}
                className="btn btn-primary mx-2"
                onClick={getStudentResult}
              >
                {loading ? "Loading..." : "Get Result"}
              </button>

              {allResultList.length > 0 ? (
                <ReactToPrint
                  documentTitle="Enter Result"
                  pageStyle={pageStyle}
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint={true}
                  trigger={() => (
                    <button
                      disabled={printloading ? true : false}
                      className="btn btn-primary w-100px mx-2"
                    >
                      {printloading ? "Printing..." : "Print"}
                    </button>
                  )}
                  content={() => componentRef.current}
                />
              ) : null}

              <DownloadTableExcel
                filename="Enter Result Export"
                sheet="Enter Result Export"
                currentTableRef={tableRef.current}
              >
                {allResultList.length > 0 && (
                  <button className="btn btn-primary mx-2">Export</button>
                )}
              </DownloadTableExcel>
            </div>
            <div>
              <button
                disabled={updateloading ? true : false}
                className="btn btn-primary mx-2"
                onClick={updateResult}
              >
                {updateloading ? "Updating..." : "Update"}
              </button>
            </div>
          </div>
          {/* <div style={{ marginTop: 30, height: 40 }} className="col-md-2">
        
          </div> */}
        </div>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <Table responsive style={{ overflow: "scroll" }}>
            <thead>
              <tr>
                <td className="text-center">Sr.No</td>
                <td className="text-center">Admission No</td>
                <td className="text-center">Student Name</td>
                <td className="text-center">Subject</td>
                <td className="text-center">Total Marks</td>
                <td className="text-center">Gain Marks</td>
                <td className="text-center">Status</td>
                {isPracticalMode ? (
                  <td className="text-center">Presentation Marks</td>
                ) : null}
                <td className="text-center">Persentage</td>
                <td className="text-center">Grade</td>
              </tr>
            </thead>
            <tbody>
              {allResultList.length > 0
                ? allResultList.map((item, index) => (
                    <tr key={item.id}>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">
                        {item.student?.admission_id}
                      </td>
                      <td className="text-center">{item.student?.name}</td>
                      <td className="text-center">{item?.subject?.name}</td>
                      <td className="text-center">
                        <input
                          onChange={(e) => OnchangefullMark(e, index)}
                          value={item.full_marks}
                          placeholder={"Total Marks"}
                          ref={(ref) => (inputRefs.current[index] = ref)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                        />
                      </td>
                      <td className="text-center">
                        <input
                          disabled={item.status != "Present" ? true : false}
                          onChange={(e) => onChangeGainMarks(e, index)}
                          value={item.gain_marks}
                          placeholder={"Gain Marks"}
                          ref={(ref) => (inputRefs.current[index] = ref)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                        />
                      </td>
                      <td className="text-center">
                        <select
                          style={{ height: 40, width: "120px" }}
                          className="form-select"
                          name="status"
                          value={item.status}
                          onChange={(e) => onStatusChange(e, index)}
                          aria-label="Default select example"
                        >
                          <option selected value="Present">
                            Present
                          </option>
                          <option value="Absent">Absent</option>
                          <option value="Leave">Leave</option>
                        </select>
                      </td>
                      {isPracticalMode ? (
                        <td className="text-center">
                          <input
                            onChange={(e) =>
                              onChangePracticalMarks(
                                e,
                                index,
                                item.gain_marks,
                                item.full_marks
                              )
                            }
                            value={item.practical_marks}
                            placeholder={"Presentation Marks"}
                            ref={(ref) => (inputRefs1.current[index] = ref)}
                            onKeyDown={(e) => handleKeyDown1(e, index)}
                          />
                        </td>
                      ) : null}
                      <td className="text-center">{item.percentage}%</td>
                      <td className="text-center">{item.grade}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        )}
      </Paper>

      {/* Print Enter Result Export */}
      <div style={{ visibility: "hidden" }}>
        <div ref={componentRef} id="printSection">
          <div className="p-3" style={{ display: "flex" }}>
            <img style={{ height: 100, width: 110 }} src={icon} />

            <div className="col-md-8" style={{ paddingTop: "25px" }}>
              <h1>{unitName}</h1>
              <div className="d-flex justify-content-between">
                <div>
                  <h3>
                    <b>Campus: {examDetails.campus?.name}</b>
                  </h3>
                  <h3>
                    <b>Subject: {subjectDetails.name}</b>
                  </h3>
                </div>
                <div>
                  <h3>
                    <b>Class: {classDetails?.name}</b>
                  </h3>
                  <h3>
                    <b>Section: {sectionDetails?.name}</b>
                  </h3>
                </div>
                <div>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "black",
                      margin: "0",
                      textAlign: "center",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {printEmpty ? "" : allResultList[0]?.full_marks}
                  </p>
                  <div>
                    <p style={{ fontSize: "16px", color: "black" }}>
                      Total Marks
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <h1>{examDetails.exam_type?.name}</h1>
          </div>

          <Table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th
                  style={{ fontSize: "16px", border: "1px solid black" }}
                  className="text-center"
                >
                  <b>Sr.No</b>
                </th>
                <th
                  style={{ fontSize: "16px", border: "1px solid black" }}
                  className="text-center"
                >
                  <b>Admission No</b>
                </th>
                <th
                  style={{ fontSize: "16px", border: "1px solid black" }}
                  className="text-center"
                >
                  <b>Student Name</b>
                </th>
                <th
                  style={{ fontSize: "16px", border: "1px solid black" }}
                  className="text-center"
                >
                  {" "}
                  <b>Gain Marks</b>
                </th>
                {isPracticalMode ? (
                  <th
                    style={{ fontSize: "16px", border: "1px solid black" }}
                    className="text-center"
                  >
                    <b>Presentation Marks</b>
                  </th>
                ) : null}

                <th
                  style={{ fontSize: "16px", border: "1px solid black" }}
                  className="text-center"
                >
                  {" "}
                  <b>Total Marks</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {allResultList.map((item, index) => (
                <tr key={item.id}>
                  <td
                    className="text-center"
                    style={{
                      fontSize: "16px",
                      color: "black",
                      border: "1px solid black",
                      padding: "1px",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    className="text-center"
                    style={{
                      fontSize: "16px",
                      color: "black",
                      border: "1px solid black",
                      padding: "1px",
                    }}
                  >
                    {item.student?.admission_id}
                  </td>
                  <td
                    className="text-lef"
                    style={{
                      fontSize: "16px",
                      color: "black",
                      border: "1px solid black",
                      padding: "1px",
                    }}
                  >
                    {item.student?.name}
                  </td>

                  <td
                    className="text-cente"
                    style={{
                      fontSize: "16px",
                      color: "black",
                      border: "1px solid black",
                      padding: "1px",
                    }}
                  >
                    {!printEmpty ? item.gain_marks : ""}
                  </td>

                  {isPracticalMode ? (
                    <td
                      className="text-cente"
                      style={{
                        fontSize: "16px",
                        color: "black",
                        border: "1px solid black",
                        padding: "1px",
                      }}
                    >
                      {!printEmpty ? item.practical_marks : ""}
                    </td>
                  ) : null}
                  <td
                    className="text-cente"
                    style={{
                      fontSize: "16px",
                      color: "black",
                      border: "1px solid black",
                      padding: "1px",
                    }}
                  >
                    {isPracticalMode && !printEmpty
                      ? Number(item.gain_marks) + Number(item.practical_marks)
                      : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      {/* Export Enter Result Export */}
      <div style={{ visibility: "hidden" }}>
        <div ref={tableRef} id="printSection">
          <div className="p-3" style={{ display: "flex" }}>
            <img style={{ height: 100, width: 110 }} src={icon} />

            <div className="col-md-8" style={{ paddingTop: "25px" }}>
              <h1>{unitName}</h1>
              <div className="d-flex justify-content-between">
                <div>
                  <h3>
                    <b>Campus: {examDetails.campus?.name}</b>
                  </h3>
                  <h3>
                    <b>Subject: {subjectDetails.name}</b>
                  </h3>
                </div>
                <div>
                  <h3>
                    <b>Class: {classDetails?.name}</b>
                  </h3>
                  <h3>
                    <b>Section: {sectionDetails?.name}</b>
                  </h3>
                </div>
                <div>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "black",
                      margin: "0",
                      textAlign: "center",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {printEmpty ? "" : allResultList[0]?.full_marks}
                  </p>
                  <div>
                    <p style={{ fontSize: "16px", color: "black" }}>
                      Total Marks
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <h1>{examDetails.exam_type?.name}</h1>
          </div>

          <Table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th
                  style={{ fontSize: "16px", border: "1px solid black" }}
                  className="text-center"
                >
                  <b>Sr.No</b>
                </th>
                <th
                  style={{ fontSize: "16px", border: "1px solid black" }}
                  className="text-center"
                >
                  <b>Admission No</b>
                </th>
                <th
                  style={{ fontSize: "16px", border: "1px solid black" }}
                  className="text-center"
                >
                  <b>Student Name</b>
                </th>
                <th
                  style={{ fontSize: "16px", border: "1px solid black" }}
                  className="text-center"
                >
                  {" "}
                  <b>Gain Marks</b>
                </th>
                {isPracticalMode ? (
                  <th
                    style={{ fontSize: "16px", border: "1px solid black" }}
                    className="text-center"
                  >
                    <b>Presentation Marks</b>
                  </th>
                ) : null}

                <th
                  style={{ fontSize: "16px", border: "1px solid black" }}
                  className="text-center"
                >
                  {" "}
                  <b>Total Marks</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {allResultList.map((item, index) => (
                <tr key={item.id}>
                  <td
                    className="text-center"
                    style={{
                      fontSize: "16px",
                      color: "black",
                      border: "1px solid black",
                      padding: "1px",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    className="text-center"
                    style={{
                      fontSize: "16px",
                      color: "black",
                      border: "1px solid black",
                      padding: "1px",
                    }}
                  >
                    {item.student?.admission_id}
                  </td>
                  <td
                    className="text-lef"
                    style={{
                      fontSize: "16px",
                      color: "black",
                      border: "1px solid black",
                      padding: "1px",
                    }}
                  >
                    {item.student?.name}
                  </td>

                  <td
                    className="text-cente"
                    style={{
                      fontSize: "16px",
                      color: "black",
                      border: "1px solid black",
                      padding: "1px",
                    }}
                  >
                    {!printEmpty ? item.gain_marks : ""}
                  </td>

                  {isPracticalMode ? (
                    <td
                      className="text-cente"
                      style={{
                        fontSize: "16px",
                        color: "black",
                        border: "1px solid black",
                        padding: "1px",
                      }}
                    >
                      {!printEmpty ? item.practical_marks : ""}
                    </td>
                  ) : null}
                  <td
                    className="text-cente"
                    style={{
                      fontSize: "16px",
                      color: "black",
                      border: "1px solid black",
                      padding: "1px",
                    }}
                  >
                    {isPracticalMode && !printEmpty
                      ? Number(item.gain_marks) + Number(item.practical_marks)
                      : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Result;
