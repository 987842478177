import React, { useState, useEffect } from "react";
import { Modal, Alert } from "react-bootstrap";
import GetSingleStudentFee from "./GetSingleStudentFee";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../services/config";
import Paper from "@material-ui/core/Paper";
import { DatePicker } from "antd";
import { round } from "lodash";
import { MdDelete } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
const { RangePicker } = DatePicker;

function GenerateDiscountFee() {
  //store data
  let red_recevingdate = useSelector(
    (state) => state.campuses.setchallanrecevingdate
  );
  const alert = useAlert();
  const [allsessions, setallsessions] = useState([]);
  const [currentsession, setcurrentsession] = useState("");
  const [isFineEnable, setIsFineEnable] = useState(false);

  const [feeloading, setfeeloading] = useState(false);
  const [feemodal, setfeemodal] = useState(false);
  const user = useSelector((state) => state.user.user);
  const authtoken = localStorage.getItem("userToken");
  const [feetypes, setfeetypes] = useState([]);
  const [due_date, setdue_date] = useState("");
  const [issue_date, setissue_date] = useState("");
  const [studentsForFeeGenerate, setstudentsForFeeGenerate] = useState([]);

  const [isallfields, setisallfields] = useState(false);
  let [monthlyFeeInputsvalue, setmonthlyFeeInputsvalue] = useState([]);
  let [isMonthlyFee, setisMonthlyFee] = useState(false);

  let [additiionalfeeinputs, setadditiionalfeeinputs] = useState([1]);
  let [isAdditionalFee, setisAdditionalFee] = useState(false);

  let [errorcreate, seterrorcreate] = useState("Please Fill All The Fields");
  const [selectedStudents, setSelectedStudents] = useState([]);

  const [fees_type_id, setfees_type_id] = useState([]);
  const [amount, setAmount] = useState([]);
  const [monthDate, setMonthDate] = useState([]);

  const [isDicountEnable, setIsDicountEnable] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [beforeDiscountAmount, setBeforeDiscountAmount] = useState(0);
  const [afterDiscountAmount, setAfterDiscountAmount] = useState(0);

  const addAdditionalInputfield = (e) => {
    e.preventDefault();

    let array = additiionalfeeinputs;
    array.push(1);
    setadditiionalfeeinputs([...array]);
  };

  useEffect(() => {
    setissue_date(red_recevingdate);
    setdue_date(red_recevingdate);
  }, [red_recevingdate]);

  useEffect(() => {
    getAllSessions();
  }, []);

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions(data.payload);
        data.payload.map((item, index) => {
          if (item.active_academic_year == "1") {
            setcurrentsession(item.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getfeetypes = (campusid, classid, sessionid) => {
    console.log(campusid, classid);
    let url = "";
    if (classid != "") {
      url = `${baseUrl}/classfeetypes/${campusid}/${classid}/${sessionid}`;
    } else {
      url = `${baseUrl}/campus/campus-fees/${campusid}/${sessionid}`;
    }

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            let filtered_feetypes = [];
            let array = [];

            if (classid != "") {
              data.payload.map((item) => {
                if (!filtered_feetypes.includes(item.name)) {
                  if (item.name == "MONTHLY FEE" || item.name == "HOSTEL FEE") {
                  } else {
                    console.log(item.name);
                    filtered_feetypes.push(item.name);
                    array.push({ name: item.name, id: item.id });
                  }
                }
              });
            } else {
              data.payload.map((item) => {
                if (!filtered_feetypes.includes(item.fee_name)) {
                  if (
                    item.fee_name == "MONTHLY FEE" ||
                    item.name == "HOSTEL FEE"
                  ) {
                  } else {
                    console.log(item.fee_name);
                    filtered_feetypes.push(item.fee_name);
                    array.push({ name: item.fee_name, id: item.fee_type_id });
                  }
                }
              });
            }
            console.log("fee types ==>", array);
            setfeetypes([...array]);
          }
        }
      })
      .catch((err) => {
        // setmonthlyFeeInputs([1])
        // setmonthlyFeeInputsvalue([])
        // setisMonthlyFee(false)
        console.log(err);
      });
  };

  const cencelModal = () => {
    // setisAdditionalFee(false);
    setisMonthlyFee(false);
    setmonthlyFeeInputsvalue([]);
    setIsDicountEnable(false);
    setDiscountAmount(0);
    setBeforeDiscountAmount(0);
    setAfterDiscountAmount(0);
    setisallfields(false);
    // setmonthlyFeeInputs([1]);
    // setfees_type_id([]);
    // setamount([]);
    // setadditiionalfeeinputs([]);
    setfeeloading(false);
    seterrorcreate("Please Fill All The Fields");
  };

  //fee genrate

  const generateSectionFee = async () => {
    setfeeloading(true);

    let monthly_fee_status = isMonthlyFee ? 1 : 0;
    let additional_fee_status = isAdditionalFee ? 1 : 0;
    let discount_status = isDicountEnable ? 1 : 0;

    if (!isMonthlyFee) {
      if (!isAdditionalFee) {
        setisallfields(true);
        seterrorcreate("Please Add Monthly Fee or Additional Fee");
        setfeeloading(false);
        return;
      }
    }

    if (monthlyFeeInputsvalue.length == 0) {
      if (isMonthlyFee) {
        setisallfields(true);
        seterrorcreate("Please Add Monthly Fee");
        setfeeloading(false);
        return;
      }
    }

    if (isAdditionalFee) {
      if (
        fees_type_id.length != additiionalfeeinputs.length ||
        additiionalfeeinputs.length != amount.length
      ) {
        setisallfields(true);
        seterrorcreate("Please Add Some Additional Fee");
        setfeeloading(false);
        return;
      }
    }

    if (due_date.length == 0 || issue_date.length == 0) {
      setisallfields(true);
      seterrorcreate("Please Give Due And issue Dates");
      setfeeloading(false);
      return;
    }

    if (due_date.slice(0, 4) < "2020" || issue_date.slice(0, 4) < "2020") {
      setisallfields(true);
      seterrorcreate("Due Or issue Dates must be after 2020");
      setfeeloading(false);
      return;
    }

    if (isDicountEnable && discountAmount == 0) {
      setisallfields(true);
      seterrorcreate("Please enter a discount amount greater than zero");
      setfeeloading(false);
      return;
    }

    setisallfields(false);
    const data = {
      monthly_fee_status,
      additional_fee_status,
      discount_status,
      student_id: studentsForFeeGenerate,
      fee_month: monthlyFeeInputsvalue,
      additional_fees: constructAdditionalFeesArray(),
      discount_amount: discountAmount,
      due_date,
      issue_date,
      add_fine: isFineEnable ? 1 : 0,
    };

    console.log(data);

    await fetch(`${baseUrl}/discount_fee_generate_with_std_list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          setfeeloading(false);
          if (data?.metadata?.success) {
            setfeemodal(false);
            setfeeloading(false);
            setisallfields(false);
            setisMonthlyFee(false);
            setIsDicountEnable(false);
            alert.success("Fee Generated Successfully");
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setfeemodal(false);
            setfeeloading(false);
          } else {
            setfeemodal(false);
            setfeeloading(false);
            alert.error(
              data?.metadata?.message
                ? data?.metadata?.message
                : "Something went wrong"
            );
          }
          cencelModal();
        })
      )
      .catch((err) => {
        setfeemodal(false);
        alert.show(err);
      });
  };

  const getStudentsForFeeGenerate = (
    students,
    campusid,
    classid,
    sectionid
  ) => {
    if (students.length != 0) {
      console.log(campusid, "yaha issue");
      getfeetypes(campusid, classid, currentsession);
    }
    setstudentsForFeeGenerate(students);
  };

  const getStudentArray = (value) => {
    setSelectedStudents(value);
  };

  const handleChanger = (day, date) => {
    let startDate = date[0] + "-01";
    let endDate = date[1] + "-01";
    var start = startDate.split("-");
    var end = endDate.split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? "0" + month : month;
        dates.push([i, displayMonth, "01"].join("-"));
      }
    }
    setmonthlyFeeInputsvalue(dates);
  };

  const additionalfeeChange = (e, index) => {
    let newAmount = [...amount];
    newAmount[index] = e.target.value;
    setAmount(newAmount);
  };

  const constructAdditionalFeesArray = () => {
    return fees_type_id.map((id, index) => ({
      fees_type_id: id,
      amount: amount[index],
      fee_month: monthDate[index] + "-01",
    }));
  };

  const getCurrentMonthYear = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11, so add 1
    return `${year}-${month}`;
  };

  const discountFeeChange = (value) => {
    setDiscountAmount(value);

    let feeAmount = selectedStudents[0]?.liable_fees[0]?.amount;
    let applyDiscount = feeAmount * (value / 100);
    let orignalDiscountAmount = feeAmount - applyDiscount;

    setBeforeDiscountAmount(round(feeAmount * monthlyFeeInputsvalue.length));
    setAfterDiscountAmount(
      round(orignalDiscountAmount * monthlyFeeInputsvalue.length)
    );
  };
  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {studentsForFeeGenerate.length == 0 ? (
          <button
            onClick={() => alert.show("Please Select Some Students")}
            className="btn btn-success button_blue"
          >
            Add Fee
          </button>
        ) : (
          <button
            onClick={() => setfeemodal(true)}
            className="btn btn-success button_blue"
          >
            Add Fee
          </button>
        )}

        <GetSingleStudentFee
          getStudentsForFeeGenerate={getStudentsForFeeGenerate}
          getStudentArray={getStudentArray}
        />

        <Modal size="lg" show={feemodal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Generate Fee With Discount - {selectedStudents[0]?.campus?.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <br />

            <form class="form-card">
              <div class="row justify-content-between text-left">
                <div class="form-group col-sm-12 flex-column d-flex pt-3">
                  <label class="form-control-label text-center font-size-h5">
                    <b>
                      Select Fee Types <span style={{ color: "red" }}>*</span>
                    </b>
                  </label>

                  <div>
                    <div class="form-group flex-column d-flex">
                      <div class="flex-column d-flex">
                        <div class="switch ">
                          <label className="switchcheckboxlabel flex-row d-flex">
                            <input
                              onChange={() => {
                                setisMonthlyFee(!isMonthlyFee);
                                // setmonthlyFeeInputsvalue = [];
                              }}
                              defaultChecked={isMonthlyFee}
                              type="checkbox"
                            />
                            <span class="lever"></span>
                            <h3 className="switchcheckboxtext">Monthly Fee</h3>
                          </label>
                        </div>

                        {isMonthlyFee ? (
                          <div className="row mt-3 p-2">
                            <div className="col-md-8 ">
                              <RangePicker
                                getPopupContainer={(triggerNode) => {
                                  return triggerNode.parentNode;
                                }}
                                style={{ height: "40px" }}
                                picker="month"
                                onChange={(day, date) =>
                                  handleChanger(day, date)
                                }
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div>
                      <div class="switch">
                        <label className="switchcheckboxlabel flex-row d-flex">
                          <input
                            onChange={() => {
                              setisAdditionalFee(!isAdditionalFee);
                              setadditiionalfeeinputs([1]);
                              setAmount([]);
                            }}
                            type="checkbox"
                            defaultChecked={isAdditionalFee}
                          />
                          <span class="lever"></span>
                          <h3 className="switchcheckboxtext">Additional Fee</h3>
                        </label>
                      </div>

                      {isAdditionalFee ? (
                        <div>
                          <div className="form-group column">
                            {additiionalfeeinputs.map((item, index) => (
                              <div className="col-lg-12 col-sm-12 mb-4">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label class="form-control-label">
                                      <b>Add Additional Fee</b>{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <select
                                      defaultValue={fees_type_id[index]}
                                      value={fees_type_id[index]}
                                      onChange={(e) => {
                                        let updatingarray = [...fees_type_id];
                                        updatingarray[index] = e.target.value;
                                        amount[index] = "";
                                        monthDate[
                                          index
                                        ] = getCurrentMonthYear();
                                        setfees_type_id(updatingarray);
                                      }}
                                      className="form-select h-40px"
                                      aria-label="Default select example"
                                    >
                                      <option selected disabled>
                                        Select Fee Type
                                      </option>
                                      {feetypes.map((fee) => (
                                        <option key={fee.id} value={fee.id}>
                                          {fee.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>

                                  <div className="col-md-3">
                                    <label class="form-control-label">
                                      <b>(Concession ignored)</b>{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="number"
                                      onChange={(e) => {
                                        additionalfeeChange(e, index);
                                      }}
                                      defaultValue={amount[index]}
                                      value={amount[index]}
                                      placeholder="Custom Amount"
                                    />
                                  </div>

                                  <div className="col-md-4">
                                    <label class="form-control-label">
                                      <b>Fee Month</b>{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      defaultValue={getCurrentMonthYear()}
                                      value={monthDate[index]}
                                      className="form-control"
                                      onChange={(e) => {
                                        let updatingarray = [...monthDate];
                                        updatingarray[index] = e.target.value;
                                        setMonthDate(updatingarray);
                                      }}
                                      type="month"
                                    />
                                  </div>

                                  <div className="col-md-1 mt-10">
                                    <span
                                      className="svg-icon svg-icon-xl cursor-pointer"
                                      onClick={(e) => {
                                        e.preventDefault();

                                        setadditiionalfeeinputs(
                                          additiionalfeeinputs.filter(
                                            (item, i) => i !== index
                                          )
                                        );
                                        setfees_type_id(
                                          fees_type_id.filter(
                                            (item, i) => i !== index
                                          )
                                        );
                                        setAmount(
                                          amount.filter(
                                            (item, i) => i !== index
                                          )
                                        );
                                      }}
                                    >
                                      <IoMdCloseCircle
                                        color="#F64E60"
                                        height="25px"
                                        width="25px"
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}

                            <div className="col-lg-3 col-sm-12">
                              <button
                                id="add_additional_Fee_button"
                                onClick={(e) => {
                                  addAdditionalInputfield(e);
                                }}
                                className="btn btn-success button_blue"
                              >
                                Add Fields
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group flex-column d-flex mt-9">
                      <div className="switch">
                        <label className="switchcheckboxlabel flex-row d-flex">
                          <input
                            onChange={(e) => {
                              setIsDicountEnable(!isDicountEnable);
                            }}
                            type="checkbox"
                            defaultChecked={isDicountEnable}
                          />
                          <span class="lever"></span>
                          <h3 className="switchcheckboxtext">Add Discount</h3>
                        </label>
                      </div>

                      {isDicountEnable ? (
                        <div className="row p-2">
                          <div className="col-md-4 mt-3">
                            <label class="form-control-label">
                              <b>
                                Enter Discount (%)
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              max={100}
                              min={0}
                              onChange={(e) => {
                                let value = parseInt(e.target.value, 10); // Convert value to number
                                if (value > 100) value = 100; // Ensure max value
                                if (value < 0) value = 0; // Ensure min value
                                discountFeeChange(value); // Pass the valid value
                              }}
                              defaultValue={discountAmount}
                              value={discountAmount}
                              placeholder="Enter Discount (%)"
                            />
                          </div>
                          <div className="col-md-4 mt-3">
                            <label class="form-control-label">
                              <b>
                                Before Discount (%)
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              onChange={discountFeeChange}
                              defaultValue={beforeDiscountAmount}
                              value={beforeDiscountAmount}
                              placeholder="Before Discount (%)"
                              disabled
                            />
                          </div>
                          <div className="col-md-4 mt-3">
                            <label class="form-control-label">
                              <b>
                                After Discount (%)
                                <span style={{ color: "red" }}>*</span>
                              </b>
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              onChange={discountFeeChange}
                              defaultValue={afterDiscountAmount}
                              value={afterDiscountAmount}
                              placeholder="After Discount (%)"
                              disabled
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div class="form-group row mt-4">
                      <div className="form-group col-md-4 flex-column d-flex mt-5">
                        <div className="switch">
                          <label className="switchcheckboxlabel flex-row d-flex">
                            <input
                              onChange={(e) => {
                                setIsFineEnable(e.target.checked);
                              }}
                              type="checkbox"
                              defaultChecked={isFineEnable}
                            />
                            <span class="lever"></span>
                            <h3 className="switchcheckboxtext">Add Fine</h3>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4 mt-5">
                        <label class="form-control-label">
                          <b>
                            Due Date <span style={{ color: "red" }}>*</span>
                          </b>
                        </label>
                        <input
                          format="DD/MM/YYYY"
                          max="9999-12-30"
                          onChange={(e) => setdue_date(e.target.value)}
                          type="date"
                          value={due_date}
                          class="form-control  mb-2"
                        />
                      </div>

                      <div className="col-md-4 mt-5">
                        <label class="form-control-label">
                          <b>
                            Issue Date <span style={{ color: "red" }}>*</span>
                          </b>
                        </label>
                        <input
                          format="DD/MM/YYYY"
                          max="9999-12-30"
                          value={issue_date}
                          onChange={(e) => setissue_date(e.target.value)}
                          type="date"
                          class="form-control  mb-2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {isallfields ? (
              <>
                <Alert
                  variant="danger"
                  onClose={() => setisallfields(false)}
                  dismissible
                >
                  <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                  <p>{errorcreate}</p>
                </Alert>
              </>
            ) : null}
          </Modal.Body>
          {feeloading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                padding: "15px",
              }}
            >
              {" "}
              <span className="spinner-border"></span>{" "}
            </div>
          ) : (
            <Modal.Footer>
              <button
                onClick={generateSectionFee}
                className="btn btn-success button_blue"
              >
                Generate
              </button>

              <button
                onClick={() => {
                  cencelModal();
                  setfeemodal(false);
                }}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </Modal.Footer>
          )}
        </Modal>
      </Paper>
    </div>
  );
}

export default GenerateDiscountFee;
