import React, { useState, useEffect } from "react";
import { baseUrl } from "../../../services/config";
import { useSelector } from "react-redux";
import { Modal, Form, Col, Table, Alert, Spinner } from "react-bootstrap";

import { useAlert } from "react-alert";
import axios from "axios";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import Paper from "@material-ui/core/Paper";
const Sections = () => {
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);
  const [globalSectionsList, setglobalSectionsList] = useState([]);
  const [allSections, setAllSections] = useState([]);
  const [selectedsections, setselectedsections] = useState([]);
  const [addModal, setaddModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [allclasses, setallclasses] = useState([]);
  const [classSectionList, setclassSectionList] = useState([]);
  const [student_class_id, setstudent_class_id] = useState("");
  const [global_section_id, setglobal_section_id] = useState("");
  const [campusId, setCampusId] = useState("");
  const [deleteModal, setdeleteModal] = useState(false);
  const [deletesectionloader, setdeletesectionloader] = useState(false);
  const [deletedsectionid, setdeletedsectionid] = useState("");
  const [selectedclassid, setselectedclassid] = useState("");
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [allfields, setallfields] = useState(false);
  const [educationType, setEducationType] = useState(1);
  const [educationTypeForSearch, setEducationTypeForSearch] = useState(1);

  const [startloading, setstartloading] = useState(false);

  useEffect(() => {
    // getAllCampus();
    // getAllClasses()
    getAllSections();
  }, []);
  const getAllSections = async () => {
    await fetch(`${baseUrl}/global_section_list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAllSections(data.payload);
        console.log(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const deleteFee = (item) => {
    console.log(item);
    console.log("deletedSection", item.id);
    setdeletedsectionid(item.id);
    setdeleteModal(true);
  };

  const handleCampusChange = (id) => {
    setCampusId(id);
    getAllClasses(id);
  };
  const getAllClasses = (id) => {
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((classes) => {
          console.log(classes, "classes");
          if (classes.metadata) {
            if (classes.metadata.success) {
              setallclasses(classes.payload);
            } else {
              alert.show("Error Occured cannot get claases");
            }
          } else {
            alert.show("Error Occured cannot get claases");
          }
        });
      })

      .catch((err) => {
        console.log(err);
        alert.show("Error Occured cannot get claases");
      });
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authtoken}`,
  };

  const AddSectionsHandler = async () => {
    if (student_class_id == "" || global_section_id == "") {
      setallfields(true);
      return;
    }
    setstartloading(true);
    setallfields(false);
    const data = {
      campus_id: campusId,
      student_class_id,
      global_section_id,
      education_type: educationType,
    };
    console.log(data);

    // axios.post(`${baseUrl}/store_class_sections`, data, {
    //     headers: headers
    // }).then(res => {
    //     console.log(res)
    // }).catch((error) => {
    //     console.log(error)
    // })

    await fetch(`${baseUrl}/store_class_sections`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((data) => {
          setstartloading(false);

          if (data.metadata) {
            if (data.metadata.success) {
              alert.success("Section Addedd Success");
              getSectionsListOnTypeChange();
            } else {
              alert.error("Something went wrong");
            }
          }
        });
      })
      .catch((err) => {
        setstartloading(false);

        alert.error("Something went wrong", err);
        console.log(err);
      });
  };

  const getSectionsList = (id) => {
    setloading(true);
    fetch(
      `${baseUrl}/class_section/${campusId}/${educationTypeForSearch}/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        setloading(false);
        res.json().then((data) => {
          console.log(data, "section data");
          setglobalSectionsList(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSectionsListOnTypeChange = () => {
    if (campusId == "" && selectedclassid == "") {
      return alert.error("Please select campus & class");
    }
    setloading(true);
    fetch(
      `${baseUrl}/class_section/${campusId}/${educationType}${
        selectedclassid ? "/" + selectedclassid : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        setloading(false);
        res.json().then((data) => {
          console.log(data, "section data");
          setglobalSectionsList(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onchangeClass = (id) => {
    setselectedclassid(id);
    fetch(`${baseUrl}/class_section/${user.campus.id}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data);
          setclassSectionList(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteFee = () => {
    console.log(deletedsectionid, "deletedsectionid");
    console.log(`${baseUrl}/delete_class_section/${deletedsectionid}`);

    setdeletesectionloader(true);

    fetch(
      `${baseUrl}/delete_class_section/${campusId}/${selectedclassid}/${educationTypeForSearch}/${deletedsectionid}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        console.log(res, "res");
        setdeletesectionloader(false);
        res.json().then((data) => {
          console.log(data);
          setdeletesectionloader(false);
          setdeleteModal(false);
          if (data.metadata) {
            if (data.metadata.success) {
              alert.success("Section Deleted success");
              getSectionsList(selectedclassid);
            }
            if (!data.metadata.success) {
              alert.show("Error Occured");
            }
          } else {
            alert.show("Error Occured");
          }
        });
      })
      .catch((err) => {
        console.log(err);
        alert.show("Error Occured");
        setdeletesectionloader(false);
        setdeleteModal(false);
      });
  };
  const handleClassChange = (id) => {
    setselectedclassid(id);
    getSectionsList(id);
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <button
          onClick={() => setaddModal(true)}
          className="btn btn-success button_blue"
        >
          Add Sections
        </button>
        <br />
        <div className="row mt-5">
          <div className="col-md-4">
            <label>
              <b>Select Campus</b>
            </label>
            <div className="d-flex flex-column">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="campus_id"
                value={campusId}
                aria-label="Default select example"
                onChange={(e) => {
                  handleCampusChange(e.target.value);
                }}
              >
                <option value="">Select Campus</option>
                {campusData &&
                  campusData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <label>
              <b>Select Class</b>
            </label>
            <select
              style={{ height: 40, border: "1px solid #e5e4e7" }}
              className="form-select"
              aria-label="Default select example"
              value={selectedclassid}
              onChange={(e) => {
                handleClassChange(e.target.value);
              }}
            >
              <option value="">Select Class</option>
              {allclasses.map((campus) => (
                <option key={campus.id} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <label>
              <b>Select Education Type</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              value={educationTypeForSearch}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
                getSectionsListOnTypeChange();
              }}
            >
              <option selected value={1}>
                Education
              </option>
              <option value={2}>Hifz</option>
            </select>
          </div>
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <div>
            <Table>
              <thead>
                <tr>
                  <td style={{ textAlign: "start" }}>ID</td>
                  <td style={{ textAlign: "start" }}>Name</td>
                  <td style={{ textAlign: "start" }}>Delete</td>
                </tr>
              </thead>
              <tbody>
                {globalSectionsList.map((sect) => (
                  <tr>
                    <td style={{ textAlign: "start" }}>{sect.id}</td>
                    <td style={{ textAlign: "start" }}>{sect.name}</td>
                    <td style={{ textAlign: "start" }}>
                      <a
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        onClick={() => deleteFee(sect)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Delete-user.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}

        <Modal show={addModal}>
          {/* <Modal.Header>Add Sections to Class</Modal.Header> */}
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Add Sections to Class
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {startloading ? (
              <span className="spinner-border"></span>
            ) : (
              <>
                <label>
                  <b>Select Campus</b>
                </label>
                <div className="d-flex flex-column">
                  <select
                    style={{ height: 40 }}
                    className="form-select"
                    name="campus_id"
                    value={campusId}
                    aria-label="Default select example"
                    onChange={(e) => {
                      handleCampusChange(e.target.value);
                    }}
                  >
                    <option value="">Select Campus</option>
                    {campusData &&
                      campusData.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                  </select>
                </div>
                <label className="mt-4">
                  <b>Select Class</b>
                </label>
                <select
                  class="form-select form-control h-40px"
                  value={student_class_id}
                  onChange={(e) => {
                    setstudent_class_id(e.target.value);
                  }}
                  aria-label="Default select example"
                >
                  <option value="">Select Class</option>
                  {allclasses.map((campus) => (
                    <option key={campus.id} value={campus.id}>
                      {campus.name}
                    </option>
                  ))}
                </select>

                <label className="mt-4">
                  <b>Select Education Type</b>
                </label>
                <select
                  className="form-select h-40px"
                  name="campus_id"
                  value={educationType}
                  aria-label="Default select example"
                  onChange={(e) => setEducationType(e.target.value)}
                >
                  <option selected value={1}>
                    Education
                  </option>
                  <option value={2}>Hifz</option>
                </select>
                <label className="mt-4">
                  <b>Select Section</b>
                </label>
                <select
                  class="form-select form-control h-40px"
                  onChange={(e) => {
                    setglobal_section_id(e.target.value);
                  }}
                  aria-label="Default select example"
                >
                  <option value="">Select Section</option>
                  {allSections.map((campus) => (
                    <option key={campus.id} value={campus.id}>
                      {campus.name}
                    </option>
                  ))}
                </select>
              </>
            )}
          </Modal.Body>
          {allfields ? (
            <div id="err">
              <Alert
                variant="danger"
                onClose={() => setallfields(false)}
                dismissible
              >
                <Alert.Heading>Please fill All fields!</Alert.Heading>
                <p>All fields Are required</p>
              </Alert>
            </div>
          ) : null}
          <Modal.Footer>
            <button
              onClick={AddSectionsHandler}
              className="btn btn-success button_blue"
            >
              Add
            </button>
            <button
              onClick={() => setaddModal(false)}
              className="btn btn-danger btn_width"
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={deleteModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to delete section
            </Modal.Title>
          </Modal.Header>
          {deletesectionloader ? (
            <div style={{ padding: "15px", color: "green" }}>
              {" "}
              Class Deleting Please Wait{" "}
              <span className="spinner-border"></span>{" "}
            </div>
          ) : (
            <Modal.Footer>
              <div style={{ display: "flex", justifyContent: "center" }}></div>
              <button onClick={DeleteFee} className="btn button_blue">
                Delete
              </button>
              <button
                onClick={() => setdeleteModal(false)}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </Modal.Footer>
          )}
        </Modal>
      </Paper>
    </div>
  );
};

export default Sections;
