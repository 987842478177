import React from "react";
import { useState } from "react";
import "./viewResult.css";
import { baseUrl } from "../../../../../services/config";
import { useEffect } from "react";
import { upperCase } from "lodash";
import { DownloadTableExcel } from "react-export-table-to-excel";

const ViewResultExport = ({ resultDetails, sortedData }) => {
  let authtoken = localStorage.getItem("userToken");
  const tableRef = React.useRef(null);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
  });

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const printDate = () => {
    const date = new Date();

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  return (
    <>
      <DownloadTableExcel
        filename="Student Wise Result Export"
        sheet="Student Wise Result Export"
        currentTableRef={tableRef.current}
      >
        <button className="btn btn-primary mx-2">Export</button>
      </DownloadTableExcel>

      {/* Student Wise Result Export */}
      <div style={{ visibility: "hidden" }}>
        <div ref={tableRef} id="printSection">
          <div className="d-flex p-3 mt-3">
            <div className="col-md-8">
              <h1>{unitName}</h1>
              <div className="d-flex justify-content-between">
                <div>
                  <h3>
                    <b>Session: {resultDetails?.exam?.session?.year}</b>
                  </h3>

                  <h3>
                    <b>Campus: {resultDetails?.campus?.name}</b>
                  </h3>

                  <h5>
                    <b>Printing Date: {printDate()}</b>
                  </h5>
                </div>
                <div>
                  <h3>
                    <b>Class: {resultDetails?.student_class?.name}</b>
                  </h3>
                  <h3>
                    <b>Section: {resultDetails?.global_section?.name}</b>
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            {resultDetails?.students_full_result &&
            resultDetails.students_full_result.length > 0 ? (
              <div>
                {Array.isArray(
                  resultDetails.students_full_result[0]?.student_result_overall
                ) ? (
                  <h6
                    style={{
                      fontSize: "14px",
                      fontWeight: "bolder",
                      color: "#01069F",
                    }}
                  >
                    <b>{resultDetails?.exam?.exam_type?.name} TOTAL MARKS: </b>{" "}
                    {Number(
                      resultDetails?.students_full_result[0]
                        ?.student_result_overall[0]?.full_marks
                    )}
                  </h6>
                ) : (
                  <h6
                    style={{
                      fontSize: "14px",
                      fontWeight: "bolder",
                      color: "#01069F",
                    }}
                  >
                    <b>{resultDetails?.exam?.exam_type?.name} TOTAL MARKS: </b>{" "}
                    {Number(
                      resultDetails?.students_full_result[0]
                        ?.student_result_overall?.full_marks
                    )}
                  </h6>
                )}
              </div>
            ) : null}
          </div>
          <table style={{ border: "1px solid #B8860B", width: "100%" }}>
            {resultDetails?.students_full_result &&
            resultDetails.students_full_result.length > 0 &&
            Array.isArray(
              resultDetails.students_full_result[0]?.student_result_overall
            ) ? (
              <>
                <thead>
                  <tr style={{ border: "1px solid #B8860B" }}>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Sr#
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Adm#
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                        width: "120px",
                      }}
                    >
                      Student Name
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Result Detail
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      FTM
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      ATM
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                        // width: "15%",
                      }}
                    >
                      Result
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      %
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Pos
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        width: "10%",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Remarks
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData?.map((result, index) => (
                    <tr style={{ border: "1px solid #B8860B" }}>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          borderLeft: "1px solid #B8860B",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student?.admission_id}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "start",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          // width: "300px",
                          width: "220px",
                        }}
                      >
                        {result?.student?.name}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          // display: "flex",
                          textAlign: "start",
                          fontSize: "10px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result?.map((singleres) => (
                          <span>
                            {singleres?.subject?.slug}={singleres?.gain_marks}
                            {","}&nbsp;
                          </span>
                        ))}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result_overall[0]?.exam == "FIRST TERM"
                          ? Number(
                              result?.student_result_overall[0]?.gain_marks
                            ) +
                            Number(
                              result?.student_result_overall[0]?.practical_marks
                            )
                          : Number(
                              result?.student_result_overall[1]?.gain_marks
                            ) +
                            Number(
                              result?.student_result_overall[1]?.practical_marks
                            )}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result_overall[1]?.exam == "FIRST TERM"
                          ? Number(
                              result?.student_result_overall[0]?.gain_marks
                            ) +
                            Number(
                              result?.student_result_overall[0]?.practical_marks
                            )
                          : Number(
                              result?.student_result_overall[1]?.gain_marks
                            ) +
                            Number(
                              result?.student_result_overall[1]?.practical_marks
                            )}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "10px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          // width: "15%",
                        }}
                      >
                        {result?.student_final_result?.demand_result}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          // width: "50px",
                        }}
                      >
                        {result?.student_final_result?.percentage}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result_overall?.position}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          width: "10%",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            ) : (
              <>
                <thead>
                  <tr style={{ border: "1px solid #B8860B" }}>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Sr#
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Adm#
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                        width: "120px",
                      }}
                    >
                      Student Name
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Result Detail
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#0e :	father1501069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Obt. Marks
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Result
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      %
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Pos
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                        width: "10%",
                      }}
                    >
                      Remarks
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData?.map((result, index) => (
                    <tr style={{ border: "1px solid #B8860B" }}>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          borderLeft: "1px solid #B8860B",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student?.admission_id}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "start",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          width: "220px",
                        }}
                      >
                        {result?.student?.name}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          // display: "flex",
                          textAlign: "center",
                          fontSize: "10px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result?.map((singleres) => (
                          <span>
                            {singleres?.subject?.slug}={singleres?.gain_marks}
                            {","}&nbsp;
                          </span>
                        ))}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result_overall.gain_marks}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result_overall?.demand_result}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          // width: "50px",
                        }}
                      >
                        {result?.student_result_overall?.percentage}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {/* {result?.student_result_overall?.full_marks == 0
                        ? ""
                        : convertOrdinal(index + 1)} */}

                        {result?.student_result_overall?.position}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          width: "10%",
                        }}
                      >
                        {""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default ViewResultExport;
