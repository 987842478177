import React, { useEffect, useState } from "react";
import { Table, Modal, Button, Alert } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useHistory } from "react-router-dom";
import { baseUrl, domainStudent } from "../../../services/config";
import Pagination from "@material-ui/lab/Pagination";

import EditStudent from "./EditStudent/EditStudent";
import Paper from "@material-ui/core/Paper";
import { useSelector, useDispatch } from "react-redux";

import { useAlert } from "react-alert";
import usePagination from "../../../components/usePagination/usePagination";
import ViewStudent from "../Student/ViewStudent/ViewStudent";
import { PremissionChecker } from "../../../services/PremissionChecker";

function ShowAllStudents() {
  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);
  const alert = useAlert();
  const [students, setstudents] = useState([]);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [classes, setClasses] = useState([]);
  const [searchtext, setsearchtext] = useState("");
  const [currentId, setcurrentId] = useState("");
  const [loading, setloading] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [selectedcampus, setselectedcampus] = useState("");
  const [selectedStudent, setselectedStudent] = useState({});
  const [deleteLoading, setdeleteLoading] = useState(false);
  const [stuckoffmodal, setstuckoffmodal] = useState(false);
  const [loginmodal, setloginmodal] = useState(false);

  const [loginpassword, setloginpassword] = useState("");
  const [loginemail, setloginemail] = useState("");
  const [isloginemail, setisloginemail] = useState(false);
  const [allDefinedFees, setallDefinedFees] = useState([]);
  //pagination states
  const [sessionData, setSessionData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setpostPerPage] = useState(10);
  const [stuckoffloading, setstuckoffloading] = useState(false);

  const [loginemailloader, setloginemailloader] = useState(false);
  const [sectionData, setSectionData] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setselectedsection] = useState("");
  const [allconcessions, setallconcessions] = useState([]);
  const [allhostel, setallHostel] = useState([]);
  const [allVehical, setallVehical] = useState([]);
  const [defaulthol, setDefaulthol] = useState({});
  const [defaultCon, setDefaultCon] = useState({});
  const [defaultVel, setDefaultVel] = useState({});
  const [educationType, setEducationType] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [yearId, setYearId] = useState("");
  const [dateData, setDateData] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [viewSelectedId, setViewSelectedId] = useState("");
  const history = useHistory();

  let [selectedindex, setselectedindex] = useState();

  const dispatch = useDispatch();
  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );

  // useEffect(() => {
  //     fetch('/student', {
  //         method: 'GET',
  //         headers: {
  //             'Authorization': `Bearer ${authtoken}`
  //         }
  //     }).then((res) => res.json())
  //         .then((data) => {
  //             console.log(data)
  //             // setstudents(data.payload)
  //         })
  //         .catch((err) => {
  //             console.log(err)
  //         })
  // }, [])

  useEffect(() => {
    // getAllStudents()

    if (campusidget != "") {
      setpreviousselectedcampus(campusidget);
      getAllStudents("", campusidget);
    }
    // getAllCampus();
    getConcessionTypes();
    getHostelID();
    getVehicleID();
    sessions();
  }, []);
  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllStudents = async (e, value) => {
    console.log(e, "e");
    console.log(value, "value");
    let id;
    let data;
    if (e) {
      id = e.target.value;
      setselectedcampus(id);
      onChampusChange(id);
      console.log(id);
      // setloading(true);
    } else {
      id = value;
    }
    dispatch({
      type: "SELECTED_CAMPUS_ID",
      payload: {
        setselectedcampusid: id,
      },
    });
    setpreviousselectedcampus(id);
    data = {
      campus_id: id,
      status: 2,
    };
    // console.log(data)
    // await fetch(`${baseUrl}/student_filter_list`, {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${authtoken}`,
    //   },
    //   body: JSON.stringify(data),
    // })
    //   .then((res) =>
    //     res.json().then((data) => {
    //       // console.log(data);
    //       let temparr = data.payload;
    //       data.payload.map((item, index) => {
    //         item["SrNo"] = index + 1;
    //         temparr[index] = item;
    //       });

    //       setstudents(temparr);
    //       setloading(false);
    //       // dispatch({
    //       //   type: 'GET_ALL_STUDENT',
    //       //   payload: data.payload
    //       // })
    //     })
    //   )

    //   .catch((err) => {
    //     setloading(false);
    //     console.log(err);
    //   });
    if (e) {
    }
  };

  const onChampusChange = (id) => {
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.payload) {
          setClasses(data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const gotoviewpage = (student) => {
    const id = student.id;
    setViewSelectedId(id);
    setselectedStudent(student);
    setShowModal(true);
    // history.push({ pathname: `/viewstudent/${id}`, state: "showallstudents" });
  };

  const stuckOffFun = (student) => {
    setstuckoffmodal(true);
    setselectedStudent(student);
  };

  //@_pagination

  const indexOfLastPost = currentPage * postPerPage;

  const indexOfFirstPost = indexOfLastPost - postPerPage;

  const paginate = (pagenumber) => setCurrentPage(pagenumber);

  // searching
  const dataSearch = students.filter((user) => {
    let text = searchtext.toLowerCase();
    return searchtext
      ? user.name?.toLowerCase().includes(text) ||
          user?.admission_id?.toLowerCase().includes(text)
      : true;
  });

  const currentPosts = dataSearch.slice(indexOfFirstPost, indexOfLastPost);

  const openEditDialog = (student) => {
    setDefaultCon({});
    setDefaulthol({});
    setDefaultVel({});
    console.log(student);
    setselectedStudent(student);
    console.log(student.concession_id, "yabbolellellel");
    if (student.concession_id != null) {
      const defaultValue = allconcessions.find(
        (item) => item.id == student.concession_id
      );
      setDefaultCon(defaultValue);
    }
    if (student.hostel_id != null) {
      const defaultValue = allhostel.find(
        (item) => item.id == student.hostel_id
      );
      setDefaulthol(defaultValue);
    }
    if (student.vehicle_id != null) {
      const defaultValue = allVehical.find(
        (item) => item.id == student.vehicle_id
      );
      setDefaultVel(defaultValue);
    }
    seteditModal(true);
  };

  const deleteDialog = (id) => {
    setdeleteModal(true);
    setcurrentId(id);
  };

  const deleteHandler = () => {
    fetch(`${baseUrl}/student/${currentId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }).then((res) => {
      res
        .json()
        .then((data) => {
          setdeleteModal(false);

          if (data.metadata) {
            if (data.metadata.success) {
              alert.success("Student Deleted success");
            } else {
              alert.error("Error Occured");
            }
          } else {
            alert.error("Error Occured");
          }
        })
        .catch((err) => {
          setdeleteModal(false);
          alert.error("Error Occured");
          console.log("err", err);
        });
    });
  };

  const stuckOffHandler = () => {
    setstuckoffloading(true);

    console.log(selectedStudent);
    let data = {
      date: dateData,
      student_id: selectedStudent.id,
    };
    fetch(`${baseUrl}/student_struct_off`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    }).then((res) => {
      res
        .json()
        .then((data) => {
          setstuckoffloading(false);
          // previousselectedcampus
          setstuckoffmodal(false);
          if (data.metadata) {
            if (data.metadata.success) {
              getAllStudents("", previousselectedcampus);
              alert.success("Student Stuck Off Successfully");
            } else {
              alert.error("Error Occured");
            }
          } else {
            alert.error("Error Occured");
          }
        })
        .catch((err) => {
          setstuckoffloading(false);
          setstuckoffmodal(false);
          console.log("err", err);
          alert.error("Error Occured");
        });
    });
  };

  const loginHandler = () => {
    if (loginpassword == "" || loginpassword.length < 6) {
      setisloginemail(true);
      return;
    }
    setisloginemail(false);
    let data = {
      password: loginpassword,
      student_id: selectedStudent.id,
    };
    console.log(data);
    setloginemailloader(true);
    let url = "";
    if (selectedStudent.user_id == null) {
      url = "student_signup";
    } else {
      url = "student_auth_update";
    }

    fetch(`${baseUrl}/${url}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    }).then((res) => {
      res
        .json()
        .then((data) => {
          console.log(data);
          setloginemailloader(false);

          if (data.metadata) {
            if (data.metadata.success) {
              if (selectedStudent.user_id == null) {
                alert.success("Password Created Successfully");
                getAllStudents("", previousselectedcampus);
              } else {
                alert.success("Password Updated Successfully");
              }
            } else {
              alert.error("Error Occured");
            }
          } else {
            alert.error("Error Occured");
          }
          setisloginemail(false);
          setloginemail("");
          setloginpassword("");
          setloginmodal(false);
        })
        .catch((err) => {
          setloginemailloader(false);

          console.log("err", err);
          alert.error("Error Occured");
          setisloginemail(false);
          setloginemail("");
          setloginpassword("");
          setloginmodal(false);
        });
    });
  };

  const getClasswiseStudent = (e) => {
    let id = e.target.value;
    onClassChange(id);
    setSelectedClass(id);
    let data = {
      campus_id: selectedcampus,
      student_class_id: id,
      global_section_id: "",
      status: 2,
      year_id: "",
    };
    // fetch(`${baseUrl}/student_filter_list`, {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${authtoken}`,
    //   },
    //   body: JSON.stringify(data),
    // })
    //   .then((res) =>
    //     res.json().then((data) => {
    //       // console.log(data);
    //       let temparr = data.payload;
    //       data.payload.map((item, index) => {
    //         item["SrNo"] = index + 1;
    //         temparr[index] = item;
    //       });

    //       setstudents(temparr);
    //       setloading(false);
    //       // dispatch({
    //       //   type: 'GET_ALL_STUDENT',
    //       //   payload: data.payload
    //       // })
    //     })
    //   )

    //   .catch((err) => {
    //     setloading(false);
    //     console.log(err);
    //   });
  };

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(students.length / PER_PAGE);
  const _DATA = usePagination(students, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const searchbyRegId = (e) => {
    e.preventDefault();
    if (searchtext == "") {
      alert.error("Please enter Admission Id or Name");
    } else {
      const data = {
        search_keyword: searchtext.toLocaleUpperCase(),
      };

      fetch(`${baseUrl}/search_by_name_or_adm_id`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.metadata.success) {
            if (data.payload.length === 0) {
              alert.error("Match not found");
            }
            setstudents(data.payload);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg[0]);
            });
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };

  const onClassChange = (id) => {
    fetch(`${baseUrl}/class_section/${selectedcampus}/${educationType}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSectionData(data.payload);
        // getChallans()
      })
      .catch((err) => {
        console.log("", err);
      });
  };
  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${selectedcampus}/${id}${
        selectedClass ? "/" + selectedClass : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          console.log(data, "section data");
          setSectionData(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSectionChange = (id) => {
    setselectedsection(id);
  };
  const getConcessionTypes = () => {
    fetch(`${baseUrl}/concession`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setallconcessions(data.payload);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err", err);
      });
  };
  const getHostelID = async () => {
    await fetch(`${baseUrl}/hostel`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallHostel(data.payload);
        console.log(data.payload, "This is hostel");
      })
      .catch((err) => {
        console.log("hostel", err);
      });
  };
  const getVehicleID = async () => {
    await fetch(`${baseUrl}/vehicle`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallVehical(data.payload);
        console.log(data.payload, "this is vehical");
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };
  const handleShow = () => {
    if (selectedcampus == "") {
      alert.error("Campus is required");
      return;
    }
    let data = {
      campus_id: selectedcampus,
      student_class_id: selectedClass,
      global_section_id: selectedSection,
      status: 2,
      education_type: educationType,
      year_id: yearId,
    };
    setloading(true);

    fetch(`${baseUrl}/student_filter_list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          // console.log(data);
          if (data.metadata) {
            if (data.metadata.success) {
              let res = data.payload.sort(
                (a, b) => a.admission_id - b.admission_id
              );
              let temparr = res;
              res.map((item, index) => {
                item["SrNo"] = index + 1;
                temparr[index] = item;
              });

              setstudents(temparr);
            }
          }
          setloading(false);
          // dispatch({
          //   type: 'GET_ALL_STUDENT',
          //   payload: data.payload
          // })
        })
      )

      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };
  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row mt-5">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <div className="d-flex flex-column ">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="year_id"
                value={yearId}
                aria-label="Default select example"
                onChange={(e) => setYearId(e.target.value)}
              >
                <option value="">Select Session</option>
                {sessionData &&
                  sessionData.map((item) => (
                    <option value={item.id}>{item.year}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              // value={campuses.length == 0 ? "" : previousselectedcampus}
              onChange={(e) => getAllStudents(e)}
              style={{ height: 40 }}
              aria-label="Default select example"
            >
              <option value="">Select Campus</option>
              {campuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Class</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              // value={campuses.length == 0 ? "" : previousselectedcampus}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => getClasswiseStudent(e)}
            >
              <option value="">Select Class</option>
              {classes &&
                classes.map((sclass, index) => (
                  <>
                    <option key={index} value={sclass.id}>
                      {sclass.name}
                    </option>
                  </>
                ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Education Type</b>
            </label>
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
                getSectionsListOnTypeChange(e.target.value);
              }}
            >
              <option value={1}>Education</option>
              <option value={2}>Hifz</option>
            </select>
          </div>

          <div className="col-md-3 mt-3">
            <label>
              <b>Select Section</b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              aria-label="Default select example"
              onChange={(e) => onSectionChange(e.target.value)}
            >
              <option value="">Select Section</option>

              {sectionData &&
                sectionData?.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
            </select>
          </div>
          <div className="col-md-3 mt-12">
            <button
              disabled={!PremissionChecker(user, "student.show")}
              className="btn btn-primary"
              onClick={handleShow}
            >
              Show
            </button>
          </div>
        </div>
        <form onSubmit={searchbyRegId}>
          <div className="row mt-5">
            <div className="col-md-4">
              <input
                onChange={(e) => setsearchtext(e.target.value)}
                className="form-control"
                placeholder="Enter Name or Admission ID"
              />
            </div>
            <div className="col-md-4">
              <button
                disabled={
                  !PremissionChecker(user, "student.get_student_by_adm_id")
                }
                type="submit"
                className="btn btn-primary"
              >
                Search
              </button>
            </div>
          </div>
        </form>

        {/* student table  */}
        <Table responsive>
          <thead>
            <tr id="tbl">
              <th>Sr. no</th>
              {/* <th>ID</th> */}
              <th>Student Name</th>
              <th>Father Name</th>
              <th>Class Section</th>
              <th>Admission ID</th>
              {/* <th>CNIC/ B FORM</th> */}
              {PremissionChecker(user, "student.update") ? <th>Edit</th> : null}
              {/* <th>Delete</th> */}
              <th>SignUp/Reset</th>
              <th>View</th>
              {PremissionChecker(user, "student.struck_off") ? (
                <th>Stuck Off</th>
              ) : null}
            </tr>
          </thead>

          <tbody>
            {_DATA.currentData().map((student, index) => {
              return (
                // key={index}
                <tr key={index}>
                  <td>{student.SrNo}</td>
                  {/* <td className="text_align_start ">{student.id}</td> */}

                  <td
                    title={student.name.length > 15 ? student.name : null}
                    style={{ textAlign: "start", cursor: "pointer" }}
                  >
                    {student.name}
                    {/* {student.name.length > 15
                      ? student.name.substring(0, 12) + "..."
                      : student.name} */}
                  </td>
                  <td
                    title={
                      student.father_name.length > 15
                        ? student.father_name
                        : null
                    }
                    style={{ textAlign: "start", cursor: "pointer" }}
                  >
                    {student.father_name.length > 15
                      ? student.father_name.substring(0, 12) + "..."
                      : student.father_name}
                  </td>
                  <td style={{ textAlign: "start" }}>
                    {student?.student_class?.name}&nbsp;
                    {student?.global_section?.name}&nbsp; (
                    {student?.campus?.name})
                  </td>

                  <td className="text-center ">{student.admission_id}</td>

                  {PremissionChecker(user, "student.update") ? (
                    <td style={{ paddingLeft: "0px" }} className="text-center">
                      <a
                        key={index}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm "
                        onClick={(e) => {
                          openEditDialog(student);
                        }}
                      >
                        <span
                          key={index}
                          className="svg-icon svg-icon-md svg-icon-primary"
                        >
                          <SVG
                            key={index}
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                  ) : null}

                  <td className="text-center">
                    <a
                      className="btn btn-icon btn-light btn-hover-primary btn-sm "
                      // disabled={student.user_id == null ? false : true}
                      onClick={
                        // student.user_id == null ?
                        () => {
                          setselectedStudent(student);
                          setloginemail(student.admission_id + domainStudent);
                          setloginmodal(true);
                        }
                        //   : null
                      }
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          style={{ margin: "10px" }}
                          className="logouticone"
                          src={
                            student.user_id == null
                              ? toAbsoluteUrl(
                                  "/media/svg/icons/Navigation/Sign-in.svg"
                                )
                              : toAbsoluteUrl(
                                  "/media/svg/icons/Navigation/Exchange.svg"
                                )
                          }
                        />
                      </span>
                    </a>
                  </td>

                  <td className="text-center">
                    <a
                      style={{}}
                      className="btn btn-icon btn-light btn-hover-primary btn-sm "
                      onClick={() => gotoviewpage(student)}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Devices/Display2.svg"
                          )}
                        />
                      </span>
                    </a>
                  </td>

                  {PremissionChecker(user, "student.struck_off") ? (
                    <td className="text-center">
                      <a
                        className="btn btn-icon btn-light btn-hover-primary btn-sm "
                        onClick={() => stuckOffFun(student)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Code/Error-circle.svg"
                            )}
                          />
                        </span>
                      </a>
                    </td>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="row justify-content-center">
          {loading && <span className="spinner-border"></span>}
        </div>

        <Pagination
          color="primary"
          count={count}
          size="large"
          page={page}
          variant="text"
          shape="round"
          onChange={handleChange}
        />
        {/* 
        <Pagination
          postsPerPage={postPerPage}
          paginate={paginate}
          totalPosts={dataSearch.length}
        /> */}

        <Modal onHide={() => seteditModal(false)} size="xl" show={editModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              {selectedStudent.name} {selectedStudent.admission_id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditStudent
              defConce={defaultCon}
              defaulthol={defaulthol}
              defaultVel={defaultVel}
              reloader={() => onSectionChange()}
              show={() => seteditModal()}
              reload={() => getAllStudents()}
              onUpdateStudent={getAllStudents}
              studentDetail={selectedStudent}
            />
          </Modal.Body>
        </Modal>
        <Modal onHide={() => setShowModal(false)} size="xl" show={showModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              {selectedStudent.name} {selectedStudent.admission_id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ViewStudent id={viewSelectedId} />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger btn_width"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        {/* setstuckoffmodal(true)
        setselectedStudent(student) */}

        <Modal show={stuckoffmodal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to Stuck Off Student ?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <label className="form-control-label">
                  <b>
                    Select Date{" "}
                    <span style={{ color: "red", fontSize: 12 }}>*</span>
                  </b>
                </label>
                <input
                  value={dateData}
                  className="form-control"
                  onChange={(e) => setDateData(e.target.value)}
                  style={{}}
                  type="date"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {stuckoffloading ? (
              <b>
                <span className="spinner-border"></span>
              </b>
            ) : (
              <>
                <button
                  onClick={stuckOffHandler}
                  className="btn btn-success button_blue "
                >
                  Stuck Off
                </button>
                <button
                  className="btn btn-danger btn_width"
                  onClick={() => setstuckoffmodal(false)}
                >
                  Cancel
                </button>
              </>
            )}
          </Modal.Footer>
        </Modal>

        <Modal show={loginmodal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            ></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row justify-content-between text-left">
              <div className="form-group row">
                <div className="form-group col-sm-10 flex-column d-flex">
                  <label>
                    Password <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    onChange={(e) => {
                      // console.log(e.target.value)
                      // console.log(selectedStudent)
                      setloginpassword(e.target.value);
                      if (e.target.value == "") {
                        setisloginemail(true);
                      } else {
                        setisloginemail(false);
                      }
                    }}
                    placeholder="Password"
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="form-group col-sm-10 flex-column d-flex">
                  <label>Email</label>
                  <input
                    disabled
                    defaultValue={loginemail}
                    placeholder="Email"
                    type="text"
                    className="form-control"
                  />
                </div>
                {/* <div className="col-lg-10">
                <label>
                  Password{" "}
                  <span style={{ color: "red" }}>*</span>

                </label>
                <input type="text" placeholder="Password" />
              </div> */}
                {/* <div className="col-lg-10">
                <label>
                  Email{" "}


                </label>
                <input type="text" placeholder="Email" disabled />
              </div> */}
              </div>
            </div>
            {isloginemail ? (
              <>
                <Alert
                  variant="danger"
                  onClose={() => setisloginemail(false)}
                  dismissible
                >
                  <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                  <p>Please Enter 6 digit Password</p>
                </Alert>
              </>
            ) : null}
          </Modal.Body>

          <Modal.Footer>
            {loginemailloader ? (
              <b>
                <span className="spinner-border"></span>
              </b>
            ) : (
              <>
                <button
                  onClick={loginHandler}
                  className="btn btn-success button_blue "
                >
                  {selectedStudent.user_id == null ? "Login" : "Reset"}
                </button>
                <button
                  className="btn btn-danger btn_width"
                  onClick={() => {
                    setisloginemail(false);
                    setloginemail("");
                    setloginpassword("");
                    setloginmodal(false);
                  }}
                >
                  Cancel
                </button>
              </>
            )}
          </Modal.Footer>
        </Modal>

        <Modal show={deleteModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Are you sure want to delete Student ?
            </Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>
                    {
                        deleteLoading ? <b style={{ color: 'green' }}>Student deleting Please wait <span className="spinner-border"></span></b>
                            : <>           Are you want to delete Student?</>
                    }

                </Modal.Body> */}
          <Modal.Footer>
            <button
              onClick={deleteHandler}
              className="btn btn-success button_blue "
            >
              Delete
            </button>
            <button
              className="btn btn-danger btn_width"
              onClick={() => setdeleteModal(false)}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </Paper>
    </div>
  );
}

export default ShowAllStudents;
