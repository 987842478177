import { Paper } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import JoditEditor from "jodit-react";
import { sendHomeWorkNotifications } from "../../../../components/SendNotifications/SendNotifications";

const AddHomeWork = () => {
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  const [allsessions, setallsessions] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [selectedcampus, setselectedcampus] = useState("");
  const [currentsession, setcurrentsession] = useState("");
  const [selectedsection, setselectedsection] = useState("");
  const [allsubjects, setallsubjects] = useState([]);
  const [selectedsubject, setselectedsubject] = useState("");
  const [educationType, setEducationType] = useState(1);
  const [classSectionID, setClassSectionID] = useState("");
  const [selectedclass, setselectedclass] = useState("");
  const [allclasses, setallclasses] = useState([]);
  const [allCampusSections, setallCampusSections] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [loading, setloading] = useState(false);

  const editor = useRef(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [studentNotificationIds, setStudentNotificationIds] = useState([]);
  const [subjectName, setSubjectName] = useState("");

  useEffect(() => {
    getAllSubjects();
    getAllSessions();
  }, []);

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallsessions([...data.payload]);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setcurrentsession(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSubjects = async () => {
    await fetch(`${baseUrl}/subject`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallsubjects(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const onCampusChange = async (id) => {
    setselectedcampus(id);
    getCampusClasses(id);
    setallclasses([]);
    setselectedclass("");
    setallCampusSections([]);
    setselectedsection("");
  };

  const getCampusClasses = (id) => {
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.payload) {
          setallclasses(data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClassChange = (id) => {
    setselectedclass(id);
    setallCampusSections([]);
    setselectedsection("");

    fetch(`${baseUrl}/class_section/${selectedcampus}/${educationType}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallCampusSections([...data.payload]);
      })
      .catch((err) => {
        console.log("section error", err);
      });
  };

  const getSectionsListOnTypeChange = (id) => {
    fetch(
      `${baseUrl}/class_section/${selectedcampus}/${id}${
        selectedclass ? "/" + selectedclass : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => {
        res.json().then((data) => {
          setallCampusSections(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClassSectionId = (id) => {
    setselectedsection(id);
    setClassSectionID("");

    getStudentNotifcation(
      currentsession,
      selectedcampus,
      selectedclass,
      educationType,
      id
    );
    fetch(
      `${baseUrl}/class-section/${selectedcampus}/${selectedclass}/${id}/${educationType}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setClassSectionID(data.payload);
      })
      .catch((err) => {
        console.log("section error", err);
      });
  };

  const getStudentNotifcation = (
    currentsession,
    campusId,
    classId,
    educationType,
    sectionId
  ) => {
    let data = {
      campus_id: campusId,
      student_class_id: classId,
      global_section_id: sectionId,
      status: 2,
      education_type: educationType,
      year_id: currentsession,
    };
    fetch(`${baseUrl}/student_filter_list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata) {
            if (data.metadata.success) {
              let notifyIds = [];
              data.payload.forEach((student) => {
                notifyIds.push(student.notification_id);
              });
              setStudentNotificationIds(notifyIds);
            }
          }
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const AddHomeWork = () => {
    if (
      currentsession == "" ||
      selectedcampus == "" ||
      selectedclass == "" ||
      selectedsection == "" ||
      selectedsubject == "" ||
      title == "" ||
      description == "" ||
      currentDate == ""
    ) {
      return alert.error("Please select all fileds");
    }

    setloading(true);
    let data = {
      year_id: currentsession,
      class_section_id: classSectionID,
      subject_id: selectedsubject,
      title: title,
      description: description,
      date: currentDate,
      remarks: remarks,
    };

    fetch(`${baseUrl}/home-work`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },

      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        if (data.metadata.success) {
          alert.success("Home Work Added Successfully");
          sendNotification();
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            alert.error(msg[0]);
          });
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  const sendNotification = () => {
    if (studentNotificationIds.length > 0) {
      // console.log(selectedTests);
      let FinalNotificationArray = [];
      studentNotificationIds.forEach((id) => {
        FinalNotificationArray.push({
          title: "Home Work",
          description: `Dear Parents/Guardian, Home work for "${subjectName}" is uploaded at ${currentDate}. Please check it in daily diary section.`,
          id: id,
        });
      });
      // console.log(FinalNotificationArray, "finnaly");
      sendHomeWorkNotifications(FinalNotificationArray);
    }
  };

  const config = {
    readonly: false,
    placeholder: "Start typing home work description...",
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "20px" }}
      >
        <div className="row">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <select
              onChange={(e) => setcurrentsession(e.target.value)}
              className="form-select"
              style={{ height: 40 }}
              value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allsessions.map((session) => (
                <option key={session.id} value={session.id}>
                  {session.year}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Campus</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="campus_id"
              value={selectedcampus}
              onChange={(e) => onCampusChange(e.target.value)}
              aria-label="Default select example"
            >
              <option value="">Select Campus</option>
              {campuses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Class</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>

            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              value={selectedclass}
              onChange={(e) => onClassChange(e.target.value)}
              aria-label="Default select example"
            >
              <option value="">Select Class</option>
              {allclasses.map((campus, index) => (
                <option key={index} value={campus.id}>
                  {campus.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Education Type</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <select
              className="form-select"
              name="campus_id"
              value={educationType}
              style={{ height: 40 }}
              aria-label="Default select example"
              onChange={(e) => {
                setEducationType(e.target.value);
                getSectionsListOnTypeChange(e.target.value);
              }}
            >
              <option value={1}>Education</option>
              <option value={2}>Hifz</option>
            </select>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-3">
            <label>
              <b>Select Section</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>

            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              value={selectedsection}
              onChange={(e) => getClassSectionId(e.target.value)}
              aria-label="Default select example"
            >
              <option value="">Select Section</option>
              {allCampusSections.map((sec, index) => (
                <option key={index} value={sec.id}>
                  {sec.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-3">
            <label>
              <b>Select Subject</b>
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="class_id"
              value={selectedsubject}
              onChange={(e) => {
                setselectedsubject(e.target.value);
                const subjectName = allsubjects.find((item) => {
                  return item?.id == e.target.value;
                });
                setSubjectName(subjectName?.name);
              }}
              aria-label="Default select example"
            >
              <option value="">Select Subject</option>
              {allsubjects.map((sec, index) => (
                <option key={index} value={sec.id}>
                  {sec.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-6">
            <label>
              <b>Enter Remarks</b> (Optional)
            </label>
            <input
              type="text"
              className="form-control"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              name="remarks"
              placeholder="Enter Remarks"
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-3">
            <label className="form-control-label">
              <b>
                Select Date{" "}
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <input
              value={currentDate}
              className="form-control"
              onChange={(e) => setCurrentDate(e.target.value)}
              type="date"
            />
          </div>
          <div className="col-md-3 d-flex">
            <button
              disabled={loading ? true : false}
              className="btn btn-primary mx-2 mt-7 w-150px"
              onClick={AddHomeWork}
            >
              {loading ? "Loading..." : "Add Home Work"}
            </button>
          </div>
        </div>

        <div className="row mt-9">
          <div className="col-md-6">
            <label className="form-control-label">
              <b>
                Enter Home Work Title{" "}
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <input
              value={title}
              className="form-control"
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              name="homework_title"
              placeholder="Enter Home Work Title"
            />
          </div>

          <div className="col-md-12 mt-5">
            <label className="form-control-label">
              <b>
                Enter Home Work Description
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <JoditEditor
              ref={editor}
              value={description}
              config={config}
              tabIndex={1}
              onBlur={(newContent) => setDescription(newContent)} // preferred to use only this option to update the content for performance reasons
              //   onChange={(newContent) => setContent(newContent)}
            />
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default AddHomeWork;
