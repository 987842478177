import React, { useState, useEffect } from "react";
import { Table, Modal, Spinner, Alert, Form } from "react-bootstrap";
import GetAllStudentFee from "./GetAllStudentFee";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../services/config";
import Paper from "@material-ui/core/Paper";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;
const GenerateMiscFee = () => {
  let red_recevingdate = useSelector(
    (state) => state.campuses.setchallanrecevingdate
  );
  const getCurrentMonthYear = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11, so add 1
    return `${year}-${month}`;
  };

  const alert = useAlert();
  // const [classess, setClassess] = useState([])
  const [allsessions, setallsessions] = useState([]);

  const [currentsession, setcurrentsession] = useState("");
  const [sessionId, setSessionId] = useState("");

  const [isFineEnable, setisFineEnable] = useState(false);

  const [feeloading, setfeeloading] = useState(false);
  const [allsections, setallsections] = useState([]);
  const [feemodal, setfeemodal] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [classess, setClassess] = useState([]);
  const authtoken = localStorage.getItem("userToken");
  const [feetypes, setfeetypes] = useState([]);
  const [campus_id, setcampus_id] = useState("");
  const [fee_type_id, setfee_type_id] = useState("");
  const [student_class_id, setstudent_class_id] = useState("");
  const [due_date, setdue_date] = useState("");
  const [issue_date, setissue_date] = useState("");
  const [selectedcclassampus, setselectedcclassampus] = useState({});

  const [sectionid, setsectionid] = useState("");
  const [studentsForFeeGenerate, setstudentsForFeeGenerate] = useState([]);

  const currentyear = new Date().getFullYear();
  const [isallfields, setisallfields] = useState(false);
  const [datesformat, setdatesformat] = useState([]);
  const [section_id, setsection_id] = useState("");
  const [fine, setfine] = useState("");
  // let [amount, setamount] = useState([]);
  // let [fees_type_id, setfees_type_id] = useState([]);

  let [monthlyFeeInputs, setmonthlyFeeInputs] = useState([1]);
  let [monthlyFeeInputsvalue, setmonthlyFeeInputsvalue] = useState([]);
  let [isMonthlyFee, setisMonthlyFee] = useState(false);

  let [addFeeInputs, setaddFeeInputs] = useState([1]);
  let [isAddFee, setisAddFee] = useState(false);

  let [additiionalfeeinputs, setadditiionalfeeinputs] = useState([1]);
  let [addituionalFeeInputsvalue, setaddituionalFeeInputsvalue] = useState([]);
  let [isAdditionalFee, setisAdditionalFee] = useState(false);

  let [errorcreate, seterrorcreate] = useState("Please Fill All The Fields");
  const [showCampusName, setShowCampusName] = useState({});
  let [selectedcampus, setselectedcampus] = useState(0);
  let [selectedcampusclass, setselectedcampusclass] = useState({});
  const [selectedStudents, setSelectedStudents] = useState([]);

  const [feesTypeId, setfeeTypeId] = useState("");
  const [amount, setAmount] = useState("");
  const [monthDate, setMonthDate] = useState(getCurrentMonthYear());

  const [campusId, setCampusId] = useState("");
  const [classes, setclasses] = useState([]);
  const [classId, setClassId] = useState("");
  const [educationType, setEducationType] = useState(0);

  useEffect(() => {
    setissue_date(red_recevingdate);
    setdue_date(red_recevingdate);
  }, [red_recevingdate]);

  useEffect(() => {
    // getAllCampus();
    getAllSessions();
    //  getfeetypes()
  }, []);

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions(data.payload);
        data.payload.map((item, index) => {
          if (item.active_academic_year == "1") {
            setcurrentsession(item.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onchangeCampus = (campusid, classid, sectionid) => {
    const id = campusid;
    // setcampus_id(id)

    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setClassess(data.payload);
        console.log("class", data);
        data.payload.map((item) => {
          if (item.id == classid) {
            setselectedcampusclass(item);
          }
        });
      })
      .catch((err) => {
        console.log(err);
        console.log("vehicle", err);
      });

    //   getfeetypes(id);
  };

  const getfeetypes = (campusid, classid, sessionid) => {
    console.log(campusid, classid);
    let url = "";
    if (classid != "") {
      url = `${baseUrl}/classfeetypes/${campusid}/${classid}/${sessionid}`;
    } else {
      url = `${baseUrl}/campus/campus-fees/${campusid}/${sessionid}`;
    }

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            let filtered_feetypes = [];
            let array = [];

            if (classid != "") {
              data.payload.map((item) => {
                if (!filtered_feetypes.includes(item.name)) {
                  if (item.name == "MONTHLY FEE" || item.name == "HOSTEL FEE") {
                  } else {
                    console.log(item.name);
                    filtered_feetypes.push(item.name);
                    array.push({ name: item.name, id: item.id });
                  }
                }
              });
            } else {
              data.payload.map((item) => {
                if (!filtered_feetypes.includes(item.fee_name)) {
                  if (
                    item.fee_name == "MONTHLY FEE" ||
                    item.name == "HOSTEL FEE"
                  ) {
                  } else {
                    console.log(item.fee_name);
                    filtered_feetypes.push(item.fee_name);
                    array.push({ name: item.fee_name, id: item.fee_type_id });
                  }
                }
              });
            }
            console.log("fee types ==>", array);
            setfeetypes([...array]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cencelModal = () => {
    setisallfields(false);
    setfeeloading(false);
    seterrorcreate("Please Fill All The Fields");
  };

  //fee genrate

  const generateSectionFee = async () => {
    setfeeloading(true);

    if (sessionId == "" || campusId == "" || classId == "") {
      setisallfields(true);
      seterrorcreate("Please Select Session, Campus and Class");
      setfeeloading(false);
      return;
    }

    if (feesTypeId == "" || monthDate == "") {
      setisallfields(true);
      seterrorcreate("Please Add Some Additional Fee & Month Date");
      setfeeloading(false);
      return;
    }

    if (due_date.length == 0 || issue_date.length == 0) {
      setisallfields(true);
      seterrorcreate("Please Give Due And issue Dates");
      setfeeloading(false);
      return;
    }

    if (due_date.slice(0, 4) < "2020" || issue_date.slice(0, 4) < "2020") {
      setisallfields(true);
      seterrorcreate("Due Or issue Dates must be after 2020");
      setfeeloading(false);
      return;
    }

    setisallfields(false);
    const data = {
      year_id: sessionId,
      campus_id: campusId,
      student_class_id: classId,
      education_type_id: educationType,
      student_ids: studentsForFeeGenerate,
      fee_month: monthDate + "-01",
      amount: amount,
      fees_type_id: feesTypeId,
      due_date,
      issue_date,
    };

    console.log(data);

    await fetch(`${baseUrl}/miscFee`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          setfeeloading(false);
          if (data?.metadata?.success) {
            setfeemodal(false);
            setisallfields(false);
            alert.success(data.payload);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setfeemodal(false);
            setfeeloading(false);
          } else {
            setfeemodal(false);
            setfeeloading(false);
            alert.error(
              data?.metadata?.message
                ? data?.metadata?.message
                : "Something went wrong"
            );
          }
          cencelModal();
        })
      )
      .catch((err) => {
        setfeemodal(false);
        alert.show(err);
      });
  };

  const getStudentsForFeeGenerate = (
    students,
    campusid,
    classid,
    sectionid
  ) => {
    if (students.length != 0) {
      console.log(campusid, "yaha issue");
      getfeetypes(campusid, classid, currentsession);

      campuses.map((item) => {
        if (item.id == campusid) {
          setselectedcampus(item);
        }
      });

      setcampus_id(campusid);
      onchangeCampus(campusid, classid, sectionid);
      setstudent_class_id(classid);
      setsection_id(sectionid);
    }
    setstudentsForFeeGenerate(students);
  };

  const onchangecampus = (e) => {
    setCampusId(e);
    setclasses([]);

    const id = e;
    fetch(`${baseUrl}/campusclass/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setclasses([...data.payload]);

        console.log("class", data);
      })
      .catch((err) => {
        console.log(err);
        console.log("class", err);
      });
  };

  const getCampusname = (name) => {
    setShowCampusName(name);
  };

  const getStudentArray = (value) => {
    setSelectedStudents(value);
  };

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {studentsForFeeGenerate.length == 0 ? (
          <button
            onClick={() => alert.show("Please Select Some Students")}
            className="btn btn-success button_blue"
          >
            Add Fee
          </button>
        ) : (
          <button
            onClick={() => setfeemodal(true)}
            className="btn btn-success button_blue"
          >
            Add Fee
          </button>
        )}
        <br />
        <br />
        <GetAllStudentFee
          getFeeType={getfeetypes}
          getCampusName={(campusname) => getCampusname(campusname)}
          getStudentsForFeeGenerate={getStudentsForFeeGenerate}
          getcurrentsession={setcurrentsession}
          getStudentArray={getStudentArray}
        />

        <Modal size="lg" show={feemodal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Generate Misc. Fee{" "}
              {showCampusName?.name
                ? " - " + showCampusName?.name
                : " - " + selectedStudents[0]?.campus?.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form class="form-card">
              <div class="row justify-content-between text-left">
                <div class="form-group col-sm-12 flex-column d-flex pt-3">
                  <div class="form-group row">
                    <div className="col-md-6">
                      <label>
                        <b>
                          Select Session <span style={{ color: "red" }}>*</span>
                        </b>
                      </label>
                      <select
                        onChange={(e) => setSessionId(e.target.value)}
                        class="form-select"
                        style={{ height: 40 }}
                        value={sessionId}
                        aria-label="Default select example"
                      >
                        <option value="">Select Session</option>
                        {allsessions.map((session) => (
                          <option key={session.id} value={session.id}>
                            {session.year}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-6">
                      <label>
                        <b>Select Campus </b>{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        onChange={(e) => onchangecampus(e.target.value)}
                        className="form-select"
                        style={{ height: 40 }}
                        value={campusId}
                        aria-label="Default select example"
                      >
                        <option value="">Select Campus</option>

                        {campuses.map((campus) => (
                          <option key={campus.id} value={campus.id}>
                            {campus.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-6 mt-4">
                      <label>
                        <b>Select Class </b>{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        onChange={(e) => setClassId(e.target.value)}
                        value={classId}
                        className="form-select"
                        style={{ height: 40 }}
                        aria-label="Default select example"
                      >
                        <option value="">Select Class</option>

                        {classes.map((item) => (
                          <option
                            name={item.name}
                            key={item.id}
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-6 mt-4">
                      <label>
                        <b>Select Education Type</b>
                      </label>
                      <select
                        className="form-select"
                        name="campus_id"
                        value={educationType}
                        style={{ height: 40 }}
                        aria-label="Default select example"
                        onChange={(e) => {
                          setEducationType(e.target.value);
                        }}
                      >
                        <option selected value={0}>
                          All Education Type
                        </option>
                        <option value={1}>Education</option>
                        <option value={2}>Hifz</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    {additiionalfeeinputs.map((item, index) => (
                      <div className="col-lg-12 col-sm-12">
                        <div className="row">
                          <div className="col-md-4">
                            <label class="form-control-label">
                              <b>Add Additional Fee</b>{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              defaultValue={feesTypeId}
                              value={feesTypeId}
                              onChange={(e) => {
                                setfeeTypeId(e.target.value);
                              }}
                              className="form-select h-40px"
                              aria-label="Default select example"
                            >
                              <option value="">Select Fee Type</option>
                              {feetypes.map((fee) => (
                                <option key={fee.id} value={fee.id}>
                                  {fee.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-md-4">
                            <label class="form-control-label">
                              <b>(Concession will be ignored)</b>{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              onChange={(e) => {
                                setAmount(e.target.value);
                              }}
                              defaultValue={amount}
                              value={amount}
                              placeholder="Enter Custom Amount"
                            />
                          </div>

                          <div className="col-md-4">
                            <label class="form-control-label">
                              <b>Fee Month</b>{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              defaultValue={getCurrentMonthYear()}
                              value={monthDate}
                              className="form-control"
                              onChange={(e) => {
                                setMonthDate(e.target.value);
                              }}
                              type="month"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div class="form-group row">
                    <div className="col-md-4">
                      <label class="form-control-label">
                        <b>
                          Due Date <span style={{ color: "red" }}>*</span>
                        </b>
                      </label>
                      <input
                        format="DD/MM/YYYY"
                        max="9999-12-30"
                        onChange={(e) => setdue_date(e.target.value)}
                        type="date"
                        value={due_date}
                        class="form-control  mb-2"
                      />
                    </div>

                    <div className="col-md-4">
                      <label class="form-control-label">
                        <b>
                          Issue Date <span style={{ color: "red" }}>*</span>
                        </b>
                      </label>
                      <input
                        format="DD/MM/YYYY"
                        max="9999-12-30"
                        value={issue_date}
                        onChange={(e) => setissue_date(e.target.value)}
                        type="date"
                        class="form-control  mb-2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {isallfields ? (
              <>
                <Alert
                  variant="danger"
                  onClose={() => setisallfields(false)}
                  dismissible
                >
                  <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                  <p>{errorcreate}</p>
                </Alert>
              </>
            ) : null}
          </Modal.Body>
          {feeloading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                padding: "15px",
              }}
            >
              {" "}
              <span className="spinner-border"></span>{" "}
            </div>
          ) : (
            <Modal.Footer>
              <button
                onClick={generateSectionFee}
                className="btn btn-success button_blue"
              >
                Generate
              </button>

              <button
                onClick={() => {
                  cencelModal();
                  setfeemodal(false);
                }}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </Modal.Footer>
          )}
        </Modal>
      </Paper>
    </div>
  );
};

export default GenerateMiscFee;
