import React, { useState } from "react";
import { Table, Spinner } from "react-bootstrap";
import { baseUrl } from "../../../services/config";
import { useAlert } from "react-alert";

function GetSingleStudentFee({ getStudentsForFeeGenerate, getStudentArray }) {
  const authtoken = localStorage.getItem("userToken");
  const [allcampusstudent, setallcampusstudent] = useState([]);
  const [allcheckboxes, setallcheckboxes] = useState([]);
  const [checkallcheckbox, setcheckallcheckbox] = useState(false);
  const [campusid, setcampusid] = useState("");
  const [classid, setclassid] = useState("");
  const [sectionid, setsectionid] = useState("");
  const [dataloader, setdataloader] = useState(false);
  const [searchtext, setsearchtext] = useState("");
  const alert = useAlert();

  const checkBoxClick = (e, index) => {
    setcheckallcheckbox(true);
    let middlearray = [];
    let studentarray = [];
    allcampusstudent.map((item, i) => {
      if (item.id == index) {
        item.checked = !item.checked;
      }
      if (!item.checked) {
        setcheckallcheckbox(false);
      }
      if (item.checked) {
        studentarray.push(item.id);
      }
      middlearray.push(item);
    });

    console.log(studentarray);
    getStudentsForFeeGenerate(studentarray, campusid, classid, sectionid);
    setallcampusstudent([...middlearray]);
  };
  const allcheckboxChecked = () => {
    let middlearray = [];
    let studentarray = [];
    if (checkallcheckbox) {
      setcheckallcheckbox(false);
      allcampusstudent.map((item, i) => {
        item.checked = false;
        middlearray.push(item);
      });
      getStudentsForFeeGenerate(studentarray, campusid, classid, sectionid);
    } else {
      setcheckallcheckbox(true);
      allcampusstudent.map((item, i) => {
        item.checked = true;
        studentarray.push(item.id);
        middlearray.push(item);
      });
      getStudentsForFeeGenerate(studentarray, campusid, classid, sectionid);
    }

    setallcampusstudent([...middlearray]);
  };

  const searchbyRegId = (e) => {
    setdataloader(true);
    e.preventDefault();
    if (searchtext == "") {
      alert.error("Please enter Admission Id or Name");
    } else {
      const data = {
        search_keyword: searchtext.toLocaleUpperCase(),
      };

      fetch(`${baseUrl}/search_by_name_or_adm_id`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setdataloader(false);
          if (data.metadata.success) {
            data.payload.map((campus) => {
              setcampusid(campus.campus_id);
              setclassid(campus.student_class_id);
              setsectionid(campus.global_section_id);
              getStudentsForFeeGenerate(
                data,
                campus.campus_id,
                campus.student_class_id,
                campus.global_section_id
              );
            });

            let checkboxes = data.payload;
            data.payload.map((item, index) => {
              item["checked"] = false;
              checkboxes[index] = item;
            });
            let middlearray = [];
            let studentarray = [];
            setcheckallcheckbox(true);
            checkboxes.map((item, i) => {
              item.checked = true;
              studentarray.push(item.id);
              middlearray.push(item);
            });
            getStudentsForFeeGenerate(
              studentarray,
              campusid,
              classid,
              sectionid
            );
            setallcampusstudent([...middlearray]);
            setallcampusstudent([...checkboxes]);
            getStudentArray([...checkboxes]);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
          }
        })
        .catch((err) => {
          console.log("err is re", err);
        });
    }
  };

  return (
    <div>
      <div>
        <form className="row mt-9" onSubmit={searchbyRegId}>
          <div className="col-md-6">
            <input
              onChange={(e) => setsearchtext(e.target.value)}
              className="form-control"
              placeholder="Enter Name or Admission ID"
            />
          </div>
          <div className="col-md-4">
            <button type="submit" className="btn btn-primary">
              Search
            </button>
          </div>
        </form>
      </div>
      <Table responsive>
        <thead>
          <tr className="text-center">
            <th>
              <input type="checkbox" style={{ display: "none" }} />
              <label className="checkbox checkbox-single">
                <input
                  onClick={(e) => {
                    allcheckboxChecked();
                  }}
                  checked={checkallcheckbox}
                  placeholder="Checkbox"
                  type="checkbox"
                  readOnly
                />
                <span />
              </label>
            </th>
            <th>Addmission ID</th>
            <th className="text-start">Student Name</th>
            <th className="text-start">Father Name</th>
            <th>Student Class</th>
            <th>Class Section</th>
            <th>Monthly Fee</th>
          </tr>
        </thead>
        <tbody>
          {allcampusstudent.map((feechallan, index) => (
            <tr key={feechallan?.id} className="text-center">
              <td>
                <input type="checkbox" style={{ display: "none" }} />
                <label className="checkbox checkbox-single">
                  <input
                    key={feechallan?.id}
                    onClick={(e) => {
                      checkBoxClick(e, feechallan?.id);
                    }}
                    value={allcheckboxes[index]}
                    checked={feechallan?.checked}
                    type="checkbox"
                    readOnly
                  ></input>
                  <span />
                </label>
              </td>
              <td className="text-center">{feechallan?.admission_id}</td>
              <td className="text-start">{feechallan?.name}</td>
              <td className="text-start">{feechallan?.father_name}</td>
              <td className="text-center">{feechallan?.student_class?.name}</td>
              <td className="text-center">
                {feechallan?.global_section?.name}
              </td>
              {feechallan?.liable_fees.length > 0 ? (
                feechallan?.liable_fees.map((fee) =>
                  fee.fees_type_id == 4 ? (
                    <td title={fee?.amount} className="text-center">
                      {fee?.amount}
                    </td>
                  ) : null
                )
              ) : (
                <td className="text-center">0</td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="row justify-content-center">
        {dataloader && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </div>
    </div>
  );
}

export default GetSingleStudentFee;
