import React, { useState, useEffect } from "react";
import { Table, Modal, Spinner, Alert, Form } from "react-bootstrap";
import { useAlert } from "react-alert";
import { baseUrl } from "../../../../services/config";
import MySelect from "./../../CustomChallan/MySelect";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import GetAllemploy from "./GetAllemploy";
// import Pagination from "../../../../components/Pagination/Pagination";
import GenerateSallary from "../AppointedStaff/GenerateSallary/GenerateSallary";
import Paper from "@material-ui/core/Paper";

import { useSelector, useDispatch } from "react-redux";
import PreviewModal from "./PreviewModal/PreviewModal";
import { Spin } from "antd";

import Pagination from "@material-ui/lab/Pagination";
import usePagination from "../../../../components/usePagination/usePagination";

function GenerateCampusWiseFee() {
  let today = new Date();
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = String(today.getFullYear());

  const alert = useAlert();
  const [postPerPage, setpostPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [designations, setdesignations] = useState([]);
  const [optionSelectedvalue, setoptionSelectedvalue] = useState([]);
  const [feeloading, setfeeloading] = useState(false);
  const [feemodal, setfeemodal] = useState(false);
  const [searchtext, setsearchtext] = useState("");
  const [previewModal, setpreviewModal] = useState(false);
  const authtoken = localStorage.getItem("userToken");
  const [campusName, setCampusName] = useState({});
  let [optionSelected, setoptionSelected] = useState([]);
  const [salarymonth, setsalarymonth] = useState(yyyy + "-" + mm);
  const [showmploy, setshowmploy] = useState(false);
  const [selectedbankcateogry, setselectedbankcateogry] = useState("");
  const [allbanks, setallbanks] = useState([]);
  const [allcheckboxes, setallcheckboxes] = useState([]);
  const [checkallcheckbox, setcheckallcheckbox] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const [isallfields, setisallfields] = useState(false);

  const [selectedcampusstaff, setselectedcampusstaff] = useState([]);

  let [errorcreate, seterrorcreate] = useState("Please Fill All The Fields");
  const [allsubaccounts, setallsubaccounts] = useState([]);
  const [campusid, setcampusid] = useState("");
  const [loading, setLoading] = useState(false);
  const [allcampusstaff, setallcampusstaff] = useState([]);
  const user = useSelector((state) => state.user.user);
  const [campuses, setallcampuses] = useState(user.campuses || []);
  const [allsessions, setallsessions] = useState([]);
  const [selectedsession, setselectedsession] = useState("");
  const dispatch = useDispatch();
  let campusidget = useSelector((state) => state.campuses.setselectedcampusid);
  const [previousselectedcampus, setpreviousselectedcampus] = useState(
    campusidget
  );

  const [selectedSalaryList, setselectedSalaryList] = useState([]);
  const [employList, setemployList] = useState([]);
  const [selectedbank, setselectedbank] = useState("");

  useEffect(() => {
    if (campusidget != "") {
      setpreviousselectedcampus(campusidget);
      setcampusid(campusidget);
      // getAllStudents("", campusidget);
    }

    getAllBanks();
    // getAllCampus();
    getAlldesignations();
    getAllSessions();
  }, []);

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata) {
          if (data.metadata.success) {
            data.payload.map((session, index) => {
              // if (session.year.substr(0, 4) == yyyy) {
              if (session.active_financial_year == 1) {
                setselectedsession(session.id);
              }
            });
          }
        }
        setallsessions(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeampus = (id) => {
    setcampusid(id);
    const campusname = campuses.find((item) => {
      return item.id == id;
    });
    setCampusName(campusname);
    setpreviousselectedcampus(id);
    dispatch({
      type: "SELECTED_CAMPUS_ID",
      payload: {
        setselectedcampusid: id,
      },
    });
  };

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setallcampuses(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const getAlldesignations = () => {
    fetch(`${baseUrl}/designations`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          console.log(data);
          setdesignations(data.payload);
        })
      )

      .catch((err) => {
        console.log(err);
      });
  };

  const getAllBanks = () => {
    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //  setinitialloading(false)
        console.log(data, "fetch data ");
        setallbanks(data.payload);
        if (selectedbankcateogry != "") {
          let anserr = data.payload.filter(
            (item) => item.id == selectedbankcateogry
          );

          if (anserr.length != 0) {
            setallsubaccounts([...anserr[0].bank_accounts]);
          } else {
            setallsubaccounts([]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cencelModal = () => {
    // setselectedcampusstaff([])
  };
  const GenerateStafSallary = () => {
    if (salarymonth == "" || selectedsession == "") {
      setisallfields(true);
      return;
    }
    setisallfields(false);
    let data = {
      employee_id: selectedcampusstaff,
      salary_month: salarymonth + "-01",
      year_id: selectedsession,
    };
    console.log(data);
    setfeeloading(true);
    setfeemodal(false);
    fetch(`${baseUrl}/bulk_salary_generate_by_list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setfeeloading(false);
        setConfirmModal(false);
        if (data.metadata) {
          if (data.metadata.success) {
            alert.show("Salary Generated Successfully");
          } else if (!data?.metadata?.success) {
            alert.show(data?.metadata?.message, { timeout: 0 });
          } else {
            alert.error("Salary Not Generated");
          }
        } else {
          alert.error("Salary Not Generated");
        }
      })
      .catch((err) => {
        setfeemodal(false);
        setfeeloading(false);
        setConfirmModal(false);

        // setselectedsession("");
        // setsalarymonth("");
        console.log("error", err);
        alert.show(err);
      });
  };

  const PreivewSalary = () => {
    if (salarymonth == "" || selectedsession == "") {
      setisallfields(true);
      return;
    }
    setisallfields(false);
    let data = {
      employee_id: selectedcampusstaff,
      salary_month: salarymonth + "-01",
      year_id: selectedsession,
      preview: 1,
    };
    console.log(data);

    fetch(`${baseUrl}/bulk_salary_generate_by_list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata) {
          if (data.metadata.success) {
            let checkboxes = data.payload.salaries;
            data.payload.salaries.map((item, index) => {
              item["ischecked"] = false;
              checkboxes[index] = item;
            });
            let res = checkboxes.sort(
              (a, b) => a.employee.emp_code - b.employee.emp_code
            );
            setselectedSalaryList(res);
            setpreviewModal(true);
          } else if (!data?.metadata?.success) {
            alert.show(data?.metadata?.message, { timeout: 0 });
            setfeemodal(false);
            setselectedSalaryList([]);
            setpreviewModal(false);
          } else {
            alert.error("Salary Not Generated");
            setfeemodal(false);
            setselectedSalaryList([]);
            setpreviewModal(false);
          }
        } else {
          alert.error("Salary Not Generated");
        }

        // setselectedsession("");
        // setsalarymonth("");
      })
      .catch((err) => {
        // setselectedsession("");
        // setsalarymonth("");
        console.log("error", err);
        alert.show(err);
      });
  };

  const getEmployList = () => {
    if (campusid == "") {
      alert.show("Please Select Campus");
    }

    let data = {
      designation_ids: optionSelectedvalue,
      campus_id: campusid,
      bank_account_id: selectedbank,
    };
    setLoading(true);
    console.log(data);
    fetch(`${baseUrl}/get_employee_list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        console.log(data);
        // setallcampuses(data.payload)
        let checkboxes = data.payload.sort((a, b) => a.emp_code - b.emp_code);
        let temparr = checkboxes;
        checkboxes.map((item, index) => {
          item["SrNo"] = index + 1;
          temparr[index] = item;
        });
        data.payload.map((item, index) => {
          item["checked"] = false;
          checkboxes[index] = item;
          let middlearray = [];
          let staffarray = [];
          setcheckallcheckbox(true);
          checkboxes.map((item, i) => {
            item.checked = true;
            staffarray.push(item.id);
            middlearray.push(item);
          });

          setselectedcampusstaff([...staffarray]);
          setallcampusstaff([...middlearray]);
        });
        setallcampusstaff([...temparr]);
      })
      .catch((err) => {
        setLoading(false);
        console.log("vehicle", err);
      });
  };

  const generateSalary = () => {
    console.log();
  };
  const allcheckboxChecked = () => {
    let middlearray = [];
    let staffarray = [];

    if (checkallcheckbox) {
      setcheckallcheckbox(false);
      allcampusstaff.map((item, i) => {
        item.checked = false;
        middlearray.push(item);
      });
      // generateSalary()
      setselectedcampusstaff([]);
    } else {
      setcheckallcheckbox(true);
      allcampusstaff.map((item, i) => {
        item.checked = true;
        staffarray.push(item.id);
        middlearray.push(item);
      });
      setselectedcampusstaff([...staffarray]);

      // generateSalary()
    }
    console.log(selectedcampusstaff);
    setallcampusstaff([...middlearray]);
  };

  const checkBoxClick = (e, index) => {
    setcheckallcheckbox(true);
    let middlearray = [];
    let studentarray = [];
    allcampusstaff.map((item, i) => {
      if (item.id == index) {
        item.checked = !item.checked;
      }
      if (!item.checked) {
        setcheckallcheckbox(false);
      }
      if (item.checked) {
        studentarray.push(item.id);
      }
      middlearray.push(item);
    });

    console.log(studentarray);
    setallcampusstaff([...middlearray]);
    setselectedcampusstaff([...studentarray]);
    generateSalary();
  };

  const getEmployFromDesignations = () => {
    setshowmploy(true);
    getEmployList();
  };

  const animatedComponents = makeAnimated();
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const handleChange = (selected) => {
    console.log(selected);
    console.log(optionSelected);
    console.log(optionSelected.length);

    if (optionSelected.length == 30 && selected.length == 30) {
      setoptionSelected([]);
      return;
    }

    let valuearray = [];
    selected.map((item, index) => {
      valuearray[index] = item.value;
    });
    setoptionSelectedvalue([...valuearray]);
    setoptionSelected([...selected]);
  };

  const Options = [];
  designations.map((item, index) => {
    Options[index] = { value: item.id, label: item.name };
  });

  const indexOfLastPost = currentPage * postPerPage;

  const indexOfFirstPost = indexOfLastPost - postPerPage;
  // const currentPosts = allcampusstaff.slice(indexOfFirstPost, indexOfLastPost);

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(allcampusstaff.length / PER_PAGE);
  const _DATA = usePagination(allcampusstaff, PER_PAGE);

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const dataSearch = allcampusstaff.filter((user) => {
    let text = searchtext.toLowerCase();
    return searchtext
      ? user?.full_name?.toLowerCase().includes(text) ||
          user?.emp_code
            ?.toString()
            .toLowerCase()
            .includes(text)
      : true;
  });

  const currentPosts = dataSearch.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pagenumber) => setCurrentPage(pagenumber);

  return (
    <div>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        {selectedcampusstaff.length == 0 ? (
          <button
            onClick={() => alert.show("Please Select Some Staff")}
            className="btn btn-success button_blue"
          >
            Add Salary
          </button>
        ) : (
          <button
            onClick={() => setfeemodal(true)}
            className="btn btn-success button_blue"
          >
            Add Salary
          </button>
        )}
        <br />
        <br />

        <div className="container">
          <div className="row">
            <div class="form-group col-md-4">
              <select
                style={{ height: 40 }}
                className="form-select"
                name="campus_id"
                value={campuses.length == 0 ? "" : previousselectedcampus}
                onChange={(e) => {
                  onChangeampus(e.target.value);
                }}
                aria-label="Default select example"
              >
                <option selected value="">
                  {" "}
                  Select Campus
                </option>
                {campuses.map((campus, index) => (
                  <option key={index} value={campus.id}>
                    {campus.name}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ padding: "0px" }} class="form-group col-md-4">
              <input
                style={{ height: 40, width: "100%" }}
                onChange={(e) => setsearchtext(e.target.value)}
                id="searchInput"
                placeholder="Search ..."
              />
            </div>

            <div
              // style={{ padding: "0px" }}
              class="form-group col-md-4"
            >
              <MySelect
                // style={{ marginTop: 30, height: 40 }}
                options={Options}
                isMulti
                name="selectedOptions"
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                placeholder="Select All"
                components={{ Option, MultiValue, animatedComponents }}
                onChange={handleChange}
                // allowSelectAll={true}
                value={optionSelected}
              />
            </div>
          </div>
        </div>

        {/* <br />
                <br /> */}

        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <label className="form-control-label">
                <b>
                  Select Bank Category{" "}
                  <span style={{ color: "red", fontSize: 12 }}>*</span>
                </b>
              </label>
              <select
                className="form-select form-control"
                name="sub_account_id"
                value={selectedbankcateogry}
                onChange={(e) => {
                  setselectedbankcateogry(e.target.value);
                  let anserr = allbanks.filter(
                    (item) => item.id == e.target.value
                  );

                  if (anserr.length != 0) {
                    setallsubaccounts([...anserr[0].bank_accounts]);
                  } else {
                    setallsubaccounts([]);
                  }
                }}
                style={{ backgroundColor: "white" }}
                aria-label="Default select example"
              >
                <option acode="" selected value="">
                  Select...
                </option>
                {allbanks.map((item) => (
                  <option value={item.id}>{item.title}</option>
                ))}
              </select>
            </div>

            <div className="col-md-4 ">
              <label className="form-control-label">
                <b>
                  Select Bank <span style={{ color: "red" }}>*</span>
                </b>
              </label>
              <select
                className="form-select form-control"
                name="sub_account_id"
                value={selectedbank}
                onChange={(e) => {
                  setselectedbank(e.target.value);
                }}
                style={{ backgroundColor: "white" }}
                aria-label="Default select example"
              >
                <option acode="" selected value="">
                  Select...
                </option>
                {allsubaccounts.map((item) => (
                  <option value={item.id}>
                    {item.bank_name}&nbsp;&nbsp;{`(${item.account_head})`}
                  </option>
                ))}
              </select>
            </div>

            <div class="col-md-2 mt-7">
              <label className="form-control-label"></label>
              <button
                onClick={getEmployFromDesignations}
                className="btn btn-success button_blue"
              >
                Get
                {/* {feeloading && <span className="spinner-border"></span>} */}
              </button>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center">
          {/* <select

                    style={{ marginTop: 30, height: 40, backgroundColor: '#F3F6F9', border: 1, }}
                    className="form-select"
                    name="campus_id"
                    onChange={(e) => getSelectedDesignations(e)}
                    aria-label="Default select example"
                >
                    <option selected disabled>Select</option>
                    {
                        designations.map(designation => (

                            <option key={designation.id} value={designation.id}>{designation.name}</option>


                        ))
                    }
                </select> */}
        </div>

        {/* <GetAllemploy show={showmploy} designations={optionSelectedvalue} getStudentsForFeeGenerate={getStudentsForFeeGenerate} /> */}
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <div className="mt-5">
            <Table responsive>
              <thead>
                <tr>
                  <th className="text-center">SrNo.</th>
                  <th>Name</th>
                  <th className="text-center">Salary Days</th>
                  <th className="text-center">Employ Code</th>
                  <th className="text-center">CNIC</th>
                  <th className="text-center">Email</th>
                  <th className="text-center">Mobile Number</th>
                  <th className="text-center">
                    <input type="checkbox" style={{ display: "none" }} />
                    <label className="checkbox checkbox-single">
                      <input
                        onClick={(e) => {
                          allcheckboxChecked();
                        }}
                        checked={checkallcheckbox}
                        placeholder="Checkbox"
                        type="checkbox"
                        readOnly
                      />
                      <span />
                    </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                {_DATA.currentData().map((staff, index) => (
                  <tr key={staff?.id}>
                    <td className="text-center">{staff?.SrNo}</td>
                    <td
                      title={
                        staff?.full_name.length > 15 ? staff?.full_name : null
                      }
                      style={{ textAlign: "start", cursor: "pointer" }}
                    >
                      {staff?.full_name.length > 15
                        ? staff?.full_name.substring(0, 12) + "..."
                        : staff?.full_name}
                    </td>
                    <td className="text-center">{staff?.salery_days}</td>
                    <td className="text-center">{staff?.emp_code}</td>

                    <td className="text-center">{staff?.cnic_no}</td>
                    <td className="text-center">{staff?.email}</td>
                    <td className="text-center">{staff?.mobile_no}</td>

                    <td className="text-center">
                      <input type="checkbox" style={{ display: "none" }} />
                      <label className="checkbox checkbox-single">
                        <input
                          key={staff?.id}
                          onClick={(e) => {
                            checkBoxClick(e, staff?.id);
                          }}
                          value={allcheckboxes[index]}
                          checked={staff?.checked}
                          type="checkbox"
                          readOnly
                        ></input>
                        <span />
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {/* <Pagination
              paginate={paginate}
              postsPerPage={postPerPage}
              totalPosts={dataSearch.length}
              /> */}

            <Pagination
              color="primary"
              count={count}
              size="large"
              page={page}
              variant="text"
              shape="rounded"
              onChange={handleChangePage}
            />
          </div>
        )}

        <Modal size="lg" show={feemodal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Generate Salary
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {/* <div className="generate text-center">Enter  Detail and Submit</div> */}
            <br />

            <div className="form-group col-sm-6 flex-column d-flex">
              <input
                value={salarymonth}
                onChange={(e) => {
                  setsalarymonth(e.target.value);
                }}
                type="month"
              />
            </div>

            <div className="form-group col-sm-6 flex-column d-flex">
              <select
                onChange={(e) => setselectedsession(e.target.value)}
                class="form-select"
                style={{ height: 40 }}
                value={selectedsession}
                aria-label="Default select example"
              >
                <option selected value="">
                  Select Session
                </option>
                {allsessions.map((session) => (
                  <option value={session.id}>{session.year}</option>
                ))}
              </select>
            </div>

            {isallfields ? (
              <>
                <Alert
                  variant="danger"
                  onClose={() => setisallfields(false)}
                  dismissible
                >
                  <Alert.Heading>Oh snap!You got an error!</Alert.Heading>
                  <p>Please Add Salary month</p>
                </Alert>
              </>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={PreivewSalary}
              className="btn btn-success button_blue ml-2"
            >
              Preview
            </button>

            <button
              onClick={() => {
                // setfeemodal(false)
                setConfirmModal(true);
              }}
              className="btn btn-success button_blue"
            >
              Generate
            </button>
            <button
              onClick={() => {
                // cencelModal()
                setisallfields(false);
                // setselectedsession("");
                // setsalarymonth("");
                setfeemodal(false);
              }}
              className="btn btn-danger btn_width"
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <Modal centered size="md" show={confirmModal}>
          <Modal.Header className="modal_header_style" closeButton>
            <Modal.Title
              className="Modal_Heading_font"
              id="contained-modal-title-vcenter"
            >
              Generate Salary
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>Are You Sure You Want To Generate Salary?</Modal.Body>
          <Modal.Footer>
            {feeloading ? (
              <Spin style={{ marginRight: "10px" }} />
            ) : (
              <button
                disabled={feeloading}
                onClick={GenerateStafSallary}
                className="btn btn-success button_blue"
              >
                Generate
              </button>
            )}

            <button
              onClick={() => {
                // cencelModal()
                setisallfields(false);
                setConfirmModal(false);
                // setselectedsession("");
                // setsalarymonth("");
                // setfeemodal(false);
              }}
              className="btn btn-danger btn_width"
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </Paper>

      <PreviewModal
        campusName={campusName}
        show={previewModal}
        salaryList={selectedSalaryList}
        hide={() => setpreviewModal(false)}
        setsalaryList={(list) => setselectedSalaryList(list)}
      />
    </div>
  );
}

export default GenerateCampusWiseFee;
